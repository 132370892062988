import React from "react";
import "./JobDescription.css";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import WorkIcon from "@mui/icons-material/Work";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { Chip } from "@mui/material";
const JobDescription = () => {
  const job = useSelector((state) => state.job.job.data);
  const isLoading = useSelector((state) => state.loader.isLoading);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <div className="jobDescription">
      {job !== undefined && (
        <div className="jobContainer">
          <div className="card">
            <div className="header">
              <div className="jobTitle">
                <div className="title">{job.job_title}</div>
                <div className="company">{job.company_name}</div>
              </div>
              <div className="text">
                <Chip
                  size="small"
                  color="success"
                  icon={<FmdGoodIcon />}
                  label={job.location}
                  variant="outlined"
                />
                &nbsp;
                <Chip
                  size="small"
                  color="warning"
                  icon={
                    <WorkIcon
                      fontSize="small"
                      sx={{ paddingLeft: "2px", fontSize: "20px" }}
                    />
                  }
                  label={job.job_type}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="last">
              <div className="skills">Skills</div>
              <div className="tags">
                {job.skills !== undefined
                  ? job.skills.map((skill) => (
                      <Chip
                        size="small"
                        color="info"
                        label={skill}
                        variant="outlined"
                      />
                    ))
                  : null}
              </div>
            </div>
            <div className="mid">
              <div className="dates">
                <div className="postedDate">
                  <div className="label">Posted on:</div>
                  <div className="text">
                    {new Date(job.created_date).toDateString()}
                  </div>
                </div>
                <div className="lastDate">
                  <div className="label">Last date:</div>
                  <div className="text">
                    {new Date(job.expiry_date).toDateString()}
                  </div>
                </div>
                <div className="hrDetails">
                  <div className="label">Posted by:</div>
                  <div className="text">{job.created_by_name}</div>
                </div>
              </div>
              <span
                style={{
                  fontSize: "14px",
                  color: job.job_status === "Active" ? "#008e0e" : "#ba1b1b",
                  fontWeight: 500,
                }}
              >
                {job.job_status.toUpperCase()}
              </span>
            </div>
          </div>
          <div className="card">
            <div className="heading">About Company</div>
            <div className="text">{job.company_description}</div>
          </div>
          <div className="card">
            <div className="heading">About Job</div>
            <div className="editContainer">
              <div className="text">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {job.job_description}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobDescription;
