import React, { useState } from "react";
import Button from "../../../components/Button/Button";
import "./FaId.css";
import Input from "../../../components/Input/Input";
import Bank from "./Bank";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { createContactId } from "../../../actions/expense.action";
import SnackBar from "../../../components/Alerts/SnackBar";

const Personal = (props) => {
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.auth.user.id);
  const isLoadingFaid = useSelector((state) => state.loader.isLoadingFaid);
  const [check, setCheck] = useState("");
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const user = useSelector((state) => state.auth.user);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };
  const handleNext = (e) => {
    e.preventDefault();

    if (!user.name || !user.email || !user.phone) {
      setMessage("Please update your contact information on the profile page!");
      setOpenError(true);
      return;
    }

    dispatch(createContactId(user.name, user.email,user.phone, user_id))
      .then(() => {
        setCheck("true");
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };

  // const handleNameChange = (e) => {
  //   setName(e.target.value);
  // };
  // const handleEmailChange = (e) => {
  //   setEmail(e.target.value);
  // };
  // const handleMobileChange = (e) => {
  //   setMobile(e.target.value);
  // };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const [mobile, setMobile] = useState("");
  if (isLoadingFaid) {
    return <LinearProgress />;
  }
  return (
    <div className="personalbank">
      <div className="personalbankContainer">
        <div className="formContainer">
        <div className="communication">Please verify your communication details to be used to intimate about all expense reimbursements. <br />Please update your profile in case of any change.</div>
          <div className="inputFieldDisabled">
          <Input 
           type="text" 
           field="Full Name" 
           value={user.name} 
           req={true}
           name={user.name}           
            />
          </div>
          <div className="inputFieldDisabled">
            <Input
              type="email"
              field="Email"
              value={user.email}
              req={true}
              email= {user.email}
            />
          </div>
          <div className="inputFieldDisabled">
            <Input
              type="number"
              field="Mobile Number"
              value={user.phone}
              req={true}
              phone={user.phone}
            />
          </div>
          {!check && (
            <div className="button">
              <Button text="Next" handleClick={handleNext} disabled={!check && (!user.name || !user.email || !user.phone)}/>
            </div>
          )}
          {check && <Bank name={user.name} email={user.email} handleCloseBankAccount = {props.handleClose} />}
        </div>
      </div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Personal;
