import React, { useState } from "react";
import Button from "../../../components/Button/Button";
import "./FaId.css";
import Input from "../../../components/Input/Input";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { createFaId } from "../../../actions/expense.action";
import SnackBar from "../../../components/Alerts/SnackBar";

const Bank = ({ name, email,handleCloseBankAccount }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const contact_id = useSelector((state) => state.expense.contactId);
  const isLoading = useSelector((state) => state.loader.isLoading);

  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [account, setAccount] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!ifsc || !account) {
      setMessage("Please enter all the fields!");
      setOpenError(true);
      return;
    }

    dispatch(createFaId(contact_id, name, email, ifsc, account))
      .then(() => {
        // for close the model
        handleCloseBankAccount();
        //setMessage("Your FA-ID Created Successfully");
        //setOpenSuccess(true);
        //setTimeout(() => {
        //  navigate("/expense");
        //}, 3000);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };
  const handleIfscChange = (e) => {
    setIfsc(e.target.value);
  };
  const handleAccountChange = (e) => {
    setAccount(e.target.value);
  };

 //const [openError, setOpenError] = useState(false);
//const [openSuccess, setOpenSuccess] = useState(false);
 // const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

//  const [ifsc, setIfsc] = useState("");
//  const [account, setAccount] = useState("");
  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <div className="personalbank">
      <div className="formContainer">
        <div className="inputField">
          <Input
            type="text"
            field="Bank IFSC Code"
            placeholder="Enter IFSC Code"
            value={ifsc}
            onChange={handleIfscChange}
            req={true}
            isOptionEqualToValue={(option, value) => option.value === value}
          />
        </div>
        <div className="inputField">
          <Input
            type="number"
            field="Bank AccountNumber"
            placeholder="Enter Account Number"
            value={account}
            onChange={handleAccountChange}
            req={true}
          />
        </div>
        <div className="button">
          <Button text="Submit" handleClick={handleSubmit} />
        </div>
      </div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Bank;
