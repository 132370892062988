import React, { useEffect, useState } from "react";
import "./ExpenseDescription.css";
import ExpenseBill from "../../../components/ExpenseComponent/ExpenseBill";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import LinearProgress from "@mui/material/LinearProgress";
import {
  rejectExpense,
  payExpense,
  getExpenseById,
  getFaId,
} from "../../../actions/expense.action";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import SnackBar from "../../../components/Alerts/SnackBar";
import DialogueBox from "../../../components/Alerts/DialogueBox";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";

const ExpenseDescription = () => {
  const { Id } = useParams();
  const goBack = () =>{ navigate(-1);};
  const [reject, setReject] = useState("");
  const [revision, setRevision] = useState("");
  const role = useSelector((state) => state.auth.user.role);
  const handleReject = () => {
    setReject("reject");
  };
  const handleCancelReject = () => {
    setReject("");
  };
  const handleRevision = () => {
    setRevision("revision");
  };
  const handleCancelRevision = () => {
    setRevision("");
  };
  dayjs.extend(relativeTime);
  const expense = useSelector((state) => state.expense.expense);
  const [comment, setComment] = useState("");
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const isLoading = useSelector((state) => state.loader.isLoading);
  const userid = useSelector((state) => state.auth.user.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openAlert, setOpenAlert] = useState(false);
  const [dialogueMessage, setDialogueMessage] = useState(
    "Do you really want to Approve this Expense?"
  );
  const handleClickOpen = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  useEffect(() => {
    dispatch(getFaId(userid)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
    dispatch(getExpenseById(Id)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  }, []);

  const handleRevisionExpense = (e) => {
    e.preventDefault();
    if (!comment) {
      setMessage("Please add the comments!");
      setOpenError(true);
      return;
    }
    dispatch(rejectExpense(expense.id, expense.data.email, comment, "Revision", expense.data.name))
      .then(() => {
        setMessage("Expense Sent Back Successfully");
        setOpenSuccess(true);
        setTimeout(() => {
          navigate("/expenseadminrequests");
        }, 3000);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };
  const handleRejectExpense = (e) => {
    e.preventDefault();
    if (!comment) {
      setMessage("Please add the comments!");
      setOpenError(true);
      return;
    }
    dispatch(rejectExpense(expense.id, expense.data.email, comment, "Rejected", expense.data.name))
      .then(() => {
        setMessage("Expense Rejected Successfully");
        setOpenSuccess(true);
        setTimeout(() => {
          navigate("/expenseadminrequests");
        }, 3000);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };
  const handlePayExpense = (e) => {
    e.preventDefault();
    dispatch(
      payExpense(
        expense.id,
        expense.data.fa_id,
        expense.data.email,
        expense.data.total_amount,
        "Approved",
        "IMPS",
        expense.data.name
      )
    )
      .then(() => {
        setMessage("Expense Approved Successfully");
        setOpenAlert(false);
        setOpenSuccess(true);
        setTimeout(() => {
          navigate("/expenseadminrequests");
        }, 1000);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };
  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <>
      {expense.data && (
        <div className="expenseDescription">
          <div className="pageHeading">Expense Details</div>
          <div className="card">
            <div className="header">
              <div className="title">{expense.data.name}</div>

              {expense.data.status === "Revision" && expense.data.created_by===userid && (
                <button
                  className="com cancel"
                  onClick={() => navigate(`/expense/edit/${Id}`)}
                >
                  Edit
                </button>
              )}
            </div>
            <div className="mid">
              <div className="content">
                <div className="details">
                  {expense.data.status && (
                    <>
                      <span
                        style={{
                          fontSize: "16px",
                          display: "flex",
                          alignItems: "center",
                          color:
                            expense.data.status === "Reimbursed"
                              ? "#008e0e"
                              : expense.data.status === "Approved"
                              ? "blue"
                              : expense.data.status === "Pending"
                              ? "#f29339"
                              : "#ba1b1b",
                        }}
                      >
                        {expense.data.status === "Approved" ||
                        expense.data.status === "Reimbursed" ? (
                          <CheckCircleOutlineIcon fontSize="small" />
                        ) : expense.data.status === "Rejected" ? (
                          <CancelOutlinedIcon fontSize="small" />
                        ) : (
                          <HourglassTopIcon fontSize="small" />
                        )}
                        &nbsp;{expense.data.status}
                      </span>
                    </>
                  )}
                </div>
                <div className="createdate">
                  <div className="label">Request Created :</div>
                  <div className="text">
                    {dayjs().to(expense.data.created_at)}
                  </div>
                </div>
                <div className="campname">
                  <div className="label">Camp Name:</div>
                  <div className="text">{expense.data.camp_name}</div>
                </div>
              </div>
            </div>
            <div className="sec">
              <div className="headlabel">Sub Expenses</div>
              <div className="bills">
                {expense.data.bills.length &&
                  expense.data.bills.map((expense) => (
                    <ExpenseBill bills={expense} />
                  ))}
              </div>
            </div>
            <div className="sec">
              <div className="headlabel">Total Amount</div>
              <div className="amount">₹ {expense.data.total_amount}</div>
            </div>
            {role === "Admin" || role === "S_ADMIN" ? (
              <div className="sec">
                {(reject || revision) && (
                  <>
                    <div className="headlabel">Admin Comments</div>
                    <div className="commentd">
                      <TextField
                        id="outlined-basic"
                        size="normal"
                        placeholder="Add comments*"
                        variant="outlined"
                        name="comment"
                        value={comment}
                        onChange={handleCommentChange}
                        sx={{
                          minWidth: 500,
                        }}
                      />
                    </div>
                  </>
                )}
                {expense.data.comment && (
                  <>
                    <div className="headlabel">Admin Comments</div>
                    <div className="comment">{expense.data.comment}</div>
                  </>
                )}
                <div className="buttons">
                  {reject === "reject" ? (
                    <>
                      <button
                        className="com cancel"
                        onClick={handleCancelReject}
                      >
                        Cancel
                      </button>
                      <button
                        className="com reject"
                        onClick={handleRejectExpense}
                      >
                        Reject
                      </button>
                    </>
                  ) : (
                    !revision && expense.data.status === "Pending" && (
                      <button className="com reject" onClick={handleReject}>
                        Reject
                      </button>
                    )
                  )}
                  {revision === "revision" ? (
                    <>
                      <button
                        className="com cancel"
                        onClick={handleCancelRevision}
                      >
                        Cancel
                      </button>
                      <button
                        className="com send"
                        onClick={handleRevisionExpense}
                      >
                        Send Back
                      </button>
                    </>
                  ) : (
                    !reject &&
                    expense.data.status === "Pending" && (
                      <button className="com send" onClick={handleRevision}>
                        Send Back
                      </button>
                    )
                  )}
                  {!revision &&
                    !reject &&
                    expense.data.status === "Pending" && (
                      <button className="com pay" onClick={handleClickOpen}>
                        Pay Now
                      </button>
                    )}
                </div>
              </div>
            ) : (
              expense.data.comment && (
                <div className="sec">
                  <div className="headlabel">Admin Comments</div>
                  <div className="comment">{expense.data.comment}</div>
                </div>
              )
            )}
            <div className="buttons">
                  <button className="com cancel" onClick={goBack}>
                    Go Back
                  </button>
            </div>
          </div>
          <SnackBar
            message={message}
            openSuccess={openSuccess}
            openError={openError}
            handleClose={handleClose}
          />
          <DialogueBox
            dialogueMessage={dialogueMessage}
            handleFunction={handlePayExpense}
            handleCloseAlert={handleCloseAlert}
            openAlert={openAlert}
          />
        </div>
      )}
    </>
  );
};

export default ExpenseDescription;
