// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appliedJobs {
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.appliedJobs .pageHeading {
  position: fixed;
  z-index: 999;
  top: 4rem;
  width: 100%;
  background-color: var(--primary-color);
  color: var(--light-text-color);
  font-size: 20px;
  padding: 1rem 0 1rem 3.5em;
}

.appliedJobs .container {
  width: 70%;
}

.appliedJobs .jobs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.appliedJobs .container .mainBtn {
  background-color: var(--primary-color);
  color: var(--light-text-color);
  border: 1.5px solid var(--primary-color);
}
.appliedJobs .container .com {
  padding: 8px 20px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/modules/Jobs/AppliedJob/AppliedJob.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,SAAS;EACT,WAAW;EACX,sCAAsC;EACtC,8BAA8B;EAC9B,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,sCAAsC;EACtC,8BAA8B;EAC9B,wCAAwC;AAC1C;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".appliedJobs {\n  min-height: 100vh;\n  padding: 2rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 1rem;\n}\n\n.appliedJobs .pageHeading {\n  position: fixed;\n  z-index: 999;\n  top: 4rem;\n  width: 100%;\n  background-color: var(--primary-color);\n  color: var(--light-text-color);\n  font-size: 20px;\n  padding: 1rem 0 1rem 3.5em;\n}\n\n.appliedJobs .container {\n  width: 70%;\n}\n\n.appliedJobs .jobs {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.appliedJobs .container .mainBtn {\n  background-color: var(--primary-color);\n  color: var(--light-text-color);\n  border: 1.5px solid var(--primary-color);\n}\n.appliedJobs .container .com {\n  padding: 8px 20px;\n  border-radius: 5px;\n  font-weight: 500;\n  cursor: pointer;\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
