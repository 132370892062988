import React, { useState } from "react";
import "./CampCard.css";
import { getExpensesByCamp, setCamp } from "../../../actions/expense.action";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SnackBar from "../../../components/Alerts/SnackBar";

const CampCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.loader.isLoading);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const handledetails = () => {
    dispatch(getExpensesByCamp(props.camp, 1))
      .then(() => {
        navigate(`/expenseadmincampdescription/${props.camp}`);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };
  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <div className="campCard" onClick={handledetails}>
      <div className="header">
        <div className="expenseTitle">
          <div className="camp">{props.camp}</div>
        </div>

        <div className="details">
          <div className="expenseTitle">
            <div className="datetitle">Passcode :</div>
            <div className="date">{props.passcode}</div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="comments">
          <div className="title">Description :</div>
          <div className="description">{props.description}</div>
        </div>
      </div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
    </div>
  );
};

export default CampCard;
