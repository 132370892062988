import React from "react";
import { Alert, Snackbar } from "@mui/material";

const SnackBar = (props) => {
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={props.openError}
        autoHideDuration={3000}
        onClose={props.handleClose}
      >
        <Alert
          variant="filled"
          severity="warning"
          sx={{ width: "100%" }}
          onClose={props.handleClose}
        >
          {props.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={props.openSuccess}
        autoHideDuration={3000}
        onClose={props.handleClose}
      >
        <Alert
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
          onClose={props.handleClose}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackBar;
