import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, setHrRequest } from "../../../actions/profile.action";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import SnackBar from "../../../components/Alerts/SnackBar";
import DialogueBox from "../../../components/Alerts/DialogueBox";
import "./HrRequestDetails.css";

const HrRequestDetails = () => {
  const { id } = useParams();
  const [reject, setReject] = useState("");
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.loader.isLoading);
  const profile = useSelector((state) => state.profile.user);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleReject = () => {
    setReject("reject");
  };
  const handleCancelReject = () => {
    setReject("");
  };
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [dialogueMessage, setDialogueMessage] = useState(
    "Do you really want to Proceed with this Request?"
  );

  const handleClickOpenReject = () => {
    setOpenAlert(true);
    setDialogueMessage("Are you sure you want to Reject this request?");
  };

  const handleClickOpenApprove = () => {
    setOpenAlert(true);
    setDialogueMessage("Are you sure you want to Approve this request?");
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    dispatch(getProfile(id)).catch((message) => {
      setMessage(message);
      setOpenError(true);
    });
  }, [dispatch, id]);

  const handleRejectRequest = () => {
    setReject("");
    setOpenAlert(false);
    if (!comment) {
      setMessage("Please add the comments!");
      setOpenError(true);
      return;
    }
    dispatch(setHrRequest(id, "Rejected", comment))
      .then((message) => {
        setMessage(message);
        setOpenSuccess(true);
        setTimeout(() => {
          navigate("/hrrequests");
        }, 1500);
      })
      .catch((message) => {
        setMessage(message);
        setOpenError(true);
      });
  };

  const handleApprove = () => {
    dispatch(setHrRequest(id, "Approved", comment))
      .then((message) => {
        setOpenAlert(false);
        setMessage(message);
        setOpenSuccess(true);
        setTimeout(() => {
          navigate("/hrrequests");
        }, 1500);
      })
      .catch((message) => {
        setOpenAlert(false);
        setMessage(message);
        setOpenError(true);
      });
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!profile) return null;

  return (
    <>
      <div className="hrRequestDetails">
        <div className="pageHeading">HR Request Details</div>
        <div className="card">
          <div className="header">
            <div className="title">{profile.name}</div>
          </div>
          <div className="mid">
            <div className="content">
              <div>
                <div className="label">Status</div>
                <div className="text">{profile.request_hr_status}</div>
              </div>
              <div className="email">
                <div className="label">Email</div>
                <div className="text">{profile.email}</div>
              </div>
              <div className="contact">
                <div className="label">Contact Number</div>
                <div className="text">{profile.phone}</div>
              </div>
              <div className="company">
                <div className="label">Company Name</div>
                <div className="text">{profile.company_title}</div>
              </div>
            </div>
          </div>

          <div className="sec">
            <div className="headlabel">Company Description</div>
            <div className="description">{profile.company_description}</div>
          </div>
          <div className="sec">
            {reject === "reject" && (
              <>
                <div className="headlabel">Admin Comments</div>
                <div className="commentd">
                  <TextField
                    id="outlined-basic"
                    size="normal"
                    placeholder={
                      profile.comments ? profile.comments : "Add Comments*"
                    }
                    variant="outlined"
                    name="comment"
                    value={comment}
                    onChange={handleCommentChange}
                    sx={{
                      minWidth: 500,
                    }}
                  />
                </div>
              </>
            )}
            {profile.comments && profile.request_hr_status === "Rejected" && (
              <>
                <div className="headlabel">Old Comments</div>
                <div className="description">{profile.comments}</div>
              </>
            )}
            <div className="buttons">
              {reject !== "reject" && (
                <button
                  className="com back"
                  onClick={() => navigate("/hrrequests")}
                >
                  Back
                </button>
              )}
              {reject === "reject" ? (
                <>
                  <button className="com cancel" onClick={handleCancelReject}>
                    Cancel
                  </button>
                  <button
                    className="com reject"
                    onClick={handleClickOpenReject}
                  >
                    Confirm Reject
                  </button>
                </>
              ) : (
                (profile.request_hr_status === "Approved" || profile.request_hr_status === "Pending") && (
                  <button className="com reject" onClick={handleReject}>
                    Reject
                  </button>
                )
              )}
              {reject !== "reject" &&
                (profile.request_hr_status === "Rejected" || 
                profile.request_hr_status === "Pending") && (
                    <button
                      className="com approve"
                      onClick={handleClickOpenApprove}
                    >
                      Approve
                    </button>
                  )}
            </div>
          </div>
        </div>
      </div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
      <DialogueBox
        dialogueMessage={dialogueMessage}
        handleFunction={reject ? handleRejectRequest : handleApprove}
        handleCloseAlert={handleCloseAlert}
        openAlert={openAlert}
      />
    </>
  );
};

export default HrRequestDetails;
