import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./modules/Login/Login";
import Home from "./modules/Home/Home";
import Register from "./modules/Register/Register";
import VerifyEmail from "./modules/Register/VerifyEmail";
import RequestPage from "./modules/Hr/RequestHr";
import ResetPassword from "./modules/Forgot/ResetPassword";
import ResetEmail from "./modules/Forgot/ResetEmail";
import Contact from "./modules/Contact/Contact";
import Navbar from "./components/Navbar/Navbar";
import JobPortal from "./modules/Jobs/JobPortal/JobPortal";
import AppliedJob from "./modules/Jobs/AppliedJob/AppliedJob";
import CreateJob from "./modules/Jobs/CreateJob/CreateJob";
import ManageJobHR from "./modules/Jobs/ManageJobHR/ManageJobHR";
import JobDetail from "./modules/Jobs/JobDetail/JobDetail";
import JobDetailHr from "./modules/Jobs/ManageJobHR/JobDetailHr";
import AppliedCandidates from "./components/JobComponent/AppliedCandidates";
import ProbableCandidates from "./components/JobComponent/ProbableCandidates";
import ViewedCandidates from "./components/JobComponent/ViewedCandidates";
import JobPage from "./components/JobComponent/JobPage";
import MyExpense from "./modules/Expense/MyExpense/MyExpense";
import AdminRequests from "./modules/Expense/AdminRequests/AdminRequests";
import AdminCamp from "./modules/Expense/AdminCamp/AdminCamp";
import ExpenseDescription from "./modules/Expense/ExpenseDescription/ExpenseDescription";
import Profile from "./modules/Profile/Profile";
import HrRequestDetails from "./modules/Admin/HrRequestDetails/HrRequestDetails";
import HrRequests from "./modules/Admin/HrRequests/HrRequests";
import EditProfile from "./modules/Profile/EditProfile";
import CreateExpense from "./modules/Expense/CreateExpense/CreateExpense";
import JobApplication from "./modules/Jobs/JobApplication/JobApplication";
import UserManagement from "./modules/Admin/UserManagement/UserManagement";
import Footer from "./components/Footer/Footer.jsx";
import ApplicationDetail from "./modules/Jobs/AppliedJob/ApplicationDetail";
import AdminCampExpenses from "./modules/Expense/AdminCampExpenses/AdminCampExpenses";
import AllJobAdmin from "./modules/Jobs/AllJobAdmin/AllJobAdmin";

import Forbidden from "./modules/Error/Forbidden.jsx";
import NotFound from "./modules/Error/NotFound.jsx";
import EditExpense from "./modules/Expense/EditExpense/EditExpense";

// const dotenv = require("dotenv");
// dotenv.config({path: __dirname+'/../.env'});

function App() {
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/register"
          element={!isLoggedIn ? <Register /> : <Navigate to="/" />}
        />
        <Route
          exact
          path="/verifyemail/:token"
          element={!isLoggedIn ? <VerifyEmail /> : <Navigate to="/" />}
        />
        <Route exact path="/reset" element={<ResetEmail />} />
        <Route
          exact
          path="/resetpassword/:id/:token"
          element={<ResetPassword />}
        />
        {/* Only for Guest Role */}
        <Route
          exact
          path="/requestpage"
          element={
            isLoggedIn && user.role === "Guest" ? (
              <RequestPage />
            ) : (
              <Navigate to="/error" />
            )
          }
        />
        <Route element={isLoggedIn ? <Layout /> : <Navigate to="/login" />}>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/hrrequests"
            element={
              isLoggedIn &&
              (user.role === "Admin" || user.role === "S_ADMIN") ? (
                <HrRequests />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            exact
            path="/usermanagement"
            element={
              isLoggedIn &&
              (user.role === "Admin" || user.role === "S_ADMIN") ? (
                <UserManagement />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            exact
            path="/hrrequestdetails/:id"
            element={
              isLoggedIn &&
              (user.role === "Admin" || user.role === "S_ADMIN") ? (
                <HrRequestDetails />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/job" element={<JobPortal />} />

          <Route
            exact
            path="/adminjobs"
            element={
              isLoggedIn &&
              (user.role === "Admin" || user.role === "S_ADMIN") ? (
                <AllJobAdmin />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            exact
            path="/applied"
            element={
              isLoggedIn &&
              (user.role === "Admin" ||
                user.role === "S_ADMIN" ||
                user.role === "Cadet" ||
                user.role === "Trainer") ? (
                <AppliedJob />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            exact
            path="/applied/:jobId/:applicantId"
            element={
              isLoggedIn &&
              (user.role === "Admin" ||
                user.role === "S_ADMIN" ||
                user.role === "Cadet" ||
                user.role === "Trainer") ? (
                <ApplicationDetail />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            exact
            path="/createjob"
            element={
              isLoggedIn && (user.role === "S_ADMIN" || user.role === "HR") ? (
                <CreateJob />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            exact
            path="/managejobhr"
            element={
              isLoggedIn &&
              (user.role === "Admin" ||
                user.role === "S_ADMIN" ||
                user.role === "HR") ? (
                <ManageJobHR />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          {/* <Route
            exact
            path="/managejobadmin"
            element={
              isLoggedIn &&
              (user.role === "Admin" || user.role === "S_ADMIN") ? (
                <ManageJobAdmin />
              ) : (
                <Navigate to="/error" />
              )
            }
          /> */}
          {/* <Route exact path="/table" element={<Table />} /> */}
          <Route exact path="/job/:jobId" element={<JobDetail />} />
          <Route exact path="/job/:jobId/:isApplied" element={<JobDetail />} />
          <Route
            exact
            path="/jobdetailhr/:jobId"
            element={
              isLoggedIn &&
              (user.role === "Admin" ||
                user.role === "S_ADMIN" ||
                user.role === "HR") ? (
                <JobDetailHr />
              ) : (
                <Navigate to="/error" />
              )
            }
          >
            <Route path="" element={<JobPage />} />
            <Route path="applied" element={<AppliedCandidates />} />
            <Route path="probable" element={<ProbableCandidates />} />
            <Route path="viewed" element={<ViewedCandidates />} />
          </Route>
          {/* <Route
            exact
            path="/jobdetailadmin"
            element={
              isLoggedIn &&
              (user.role === "Admin" || user.role === "S_ADMIN") ? (
                <JobDetailAdmin />
              ) : (
                <Navigate to="/error" />
              )
            }
          >
            <Route path="" element={<JobPage />} />
            <Route path="applied" element={<AppliedCandidates />} />
          </Route> */}
          <Route
            exact
            path="/expense"
            element={
              isLoggedIn &&
              (user.role === "Admin" ||
                user.role === "S_ADMIN" ||
                user.role === "Trainer") ? (
                <MyExpense />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            exact
            path="/expensedescription/:Id"
            element={
              isLoggedIn &&
              (user.role === "Admin" ||
                user.role === "S_ADMIN" ||
                user.role === "Trainer") ? (
                <ExpenseDescription />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            exact
            path="/expenseadminrequests"
            element={
              isLoggedIn &&
              (user.role === "Admin" || user.role === "S_ADMIN") ? (
                <AdminRequests />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            exact
            path="/expenseadmincamp"
            element={
              isLoggedIn &&
              (user.role === "Admin" || user.role === "S_ADMIN") ? (
                <AdminCamp />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route exact path="/profile/:id" element={<Profile />} />
          <Route exact path="/editprofile/:id" element={<EditProfile />} />
          <Route
            exact
            path="/expense/edit/:id"
            element={
              isLoggedIn &&
              (user.role === "Admin" ||
                user.role === "S_ADMIN" ||
                user.role === "Trainer") ? (
                <EditExpense />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            exact
            path="/expense/create"
            element={
              isLoggedIn &&
              (user.role === "Admin" ||
                user.role === "S_ADMIN" ||
                user.role === "Trainer") ? (
                <CreateExpense />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            exact
            path="/jobapplication/:jobId/:applicant_id"
            element={
              isLoggedIn &&
              (user.role === "Admin" ||
                user.role === "S_ADMIN" ||
                user.role === "HR") ? (
                <JobApplication />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            exact
            path="/expenseadmincampdescription/:campname"
            element={
              isLoggedIn &&
              (user.role === "Admin" || user.role === "S_ADMIN") ? (
                <AdminCampExpenses />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
        </Route>
        <Route exact path="/error" element={<Forbidden />} />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

const Layout = () => {
  return (
    <>
      <Navbar />
      <div
        style={{
          // marginTop: "3.9rem",
          minHeight: "87vh",
        }}
      >
        <Outlet />
      </div>
      {/* Footer has 4vh height */}
      <Footer />
    </>
  );
};
