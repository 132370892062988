import axios from "axios";

const axiosAuth = axios.create({
   baseURL: "https://user-management-tykufsoxbq-el.a.run.app/api/v1",
   headers: {
      "Content-Type": "application/json",
   },
});

const axiosJob = axios.create({
   baseURL: "https://job-service-tykufsoxbq-el.a.run.app/api/v1",
   headers: {
      "Content-Type": "application/json",
   },
});

const axiosExpense = axios.create({
   baseURL: "https://expense-service-tykufsoxbq-el.a.run.app/api/v1",
   headers: {
      "Content-Type": "application/json",
   },
});

const axiosProfile = axios.create({
   baseURL: "https://user-management-tykufsoxbq-el.a.run.app/api/v1",
   headers: {
      "Content-Type": "application/json",
   },
});

const axiosSkill = axios.create({
   baseURL: "https://user-management-tykufsoxbq-el.a.run.app/api/v1/",
   headers: {
      "Content-Type": "application/json",
   },
});

const Axios = { axiosAuth, axiosJob, axiosExpense, axiosProfile, axiosSkill };

export default Axios;