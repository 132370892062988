import Axios from "./axios.service";
import authHeader from "./auth-header.service";

const sendHrRequest = async (companyName, companyDescription, mobile) => {
  const response = await Axios.axiosProfile.post(
    "/profile/requesthr",
    {
      companyName,
      companyDescription,
      mobile,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

const getHrRequests = async () => {
  const response = await Axios.axiosProfile.get(`/profile/hrrequest`, {
    headers: authHeader(),
  });

  return response;
};

const getEntries = async (role) => {
  const response = await Axios.axiosProfile.post(
    `/profile/role`,
    {
      role,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

const getNotify = async (id) => {
  const response = await Axios.axiosProfile.get(`/profile/notify/${id}`, {
    headers: authHeader(),
  });

  return response;
};

const setNotify = async (id, notif) => {
  const response = await Axios.axiosProfile.put(
    `/profile/notify/${id}`,
    {
      notif,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

const updateRoleAction = async (id, role) => {
  const response = await Axios.axiosProfile.put(
    `/profile/role/${id}`,
    {
      role,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

const getProfile = async (id) => {
  const response = await Axios.axiosProfile.get(`/profile/user/${id}`, {
    headers: authHeader(),
  });
  return response;
};

const setHrRequest = async (id, status, comment) => {
  const response = await Axios.axiosProfile.put(
    `/profile/hrrequest/${id}`,
    {
      status,
      comment,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

const saveSkills = async (id, skills) => {
  const response = await Axios.axiosProfile.post(
    `/skillMapping/map/${id}`,
    {
      new_skills: skills,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

const saveProfile = async (id, updatedData) => {
  const response = await Axios.axiosProfile.put(
    `/profile/user/${id}`,
    {
      updatedData: updatedData,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

const getSkillSet = async () => {
  const response = await Axios.axiosProfile.get(`/skills/`, {
    headers: authHeader(),
  });
  return response;
};

const sendAccessRequest = async (email, role) => {
  const response = await Axios.axiosProfile.post(
    "/profile/validate",
    {
      email,
      role,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

const getCamps = async (email) => {
  const response = await Axios.axiosProfile.post(
    "/profile/camps",
    {
      email,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

const ProfileService = {
  sendHrRequest,
  getHrRequests,
  getProfile,
  setHrRequest,
  setNotify,
  getNotify,
  getEntries,
  saveSkills,
  saveProfile,
  updateRoleAction,
  getSkillSet,
  sendAccessRequest,
  getCamps,
};

export default ProfileService;
