import React, { useState, useEffect } from "react";
import "./EditProfile.css";
import {
  Stack,
  CircularProgress,
  LinearProgress,
  TextField,
  Autocomplete,
  InputAdornment,
  Checkbox,
  Chip,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Input from "@mui/material/Input";
import dayjs from "dayjs";
// import { city } from "../../assets/city";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { storage } from "../../firebase/firebaseConnection";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  saveProfile,
  getProfile,
  getSkillSet,
} from "../../actions/profile.action";
import {
  getLocations,
  getLocationsCity,
  getLocationsPincodes,
  getDegreeList,
} from "../../actions/job.action";
import SnackBar from "../../components/Alerts/SnackBar.jsx";

const EditProfile = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isLoading = useSelector((state) => state.loader.isLoading);
  const isLoadingSkills = useSelector((state) => state.loader.isLoadingSkills);
  const isLoadingStatus = useSelector((state) => state.loader.isLoadingStatus);
  const profile = useSelector((state) => state.profile.user);
  const skillSet = useSelector((state) => state.profile.skillSet);
  const navigate = useNavigate();
  const [locationState, setLocationState] = useState("");
  const [fetchState, setFetchState] = useState(false);
  const [fetchCity, setFetchCity] = useState(false);
  const city = useSelector((state) => state.job.city);
  const states = useSelector((state) => state.job.states);
  const pincodes = useSelector((state) => state.job.pincodes);
  const degrees = useSelector((state) => state.job.degrees || []);

  const [loaderResume, setLoaderResume] = useState(false);
  const [error, setError] = useState(false);
  const [fullName, setFullName] = useState("");
  const [location, setLocation] = useState("");
  const [pincode, setPincode] = useState();
  const [skills, setSkills] = useState([""]);
  const [additionalSkills, setAdditionalSkills] = useState([]);
  const [newAdditionalSkill, setNewadditionalSkill] = useState(null);
  const [resume, setResume] = useState("");
  const [Url, setUrl] = useState("");
  const [phone, setPhone] = useState("");
  const [certificates, setCertificates] = useState([
    {
      name: "",
      from: null,
      to: null,
    },
  ]);
  const [educations, setEducations] = useState([
    {
      university: "",
      degree: "",
      branch: "",
      pursuing: false,
      from: null,
      to: null,
    },
  ]);
  // according to user model
  const [companies, setCompanies] = useState([
    {
      role: "",
      current: false,
      company_name: "",
      from: null,
      to: null,
    },
  ]);
  const handleLocationStateChange = (event, newValue) => {
    event.preventDefault();
    // console.log(newValue);
    setLocationState(newValue);
    if (newValue) {
      dispatch(getLocationsCity(newValue));
      setFetchState(true);
      setLocation("");
    } else {
      setFetchState(false);
      setLocation("");
    }
  };

  const handleLocationCityChange = (event, newValue) => {
    event.preventDefault();
    setLocation(newValue);
    setFetchCity(false);
    };

  //for Snackbar
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  useEffect(() => {
    dispatch(getDegreeList());
    dispatch(getProfile(id))
      .then((message) => { })
      .catch((message) => {
        setOpenError(true);
        setMessage(message);
      });
    dispatch(getSkillSet())
      .then((message) => { })
      .catch((message) => {
        setMessage(message);
        setOpenError(true);
      });
    dispatch(getLocations())
      .then((message) => { })
      .catch((message) => {
        setMessage(message);
        setOpenError(true);
      });
  }, []);

  //these states when set after success of getProfile are not able to get the updated profile values so another useEffect that updates whenever the profile changes does the job for us. Interseting.
  useEffect(() => {
    if (profile) {
      setFullName(profile.name);
      if (profile.certifications !== undefined) {
        setCertificates(
          profile.certifications.map((certificate) => {
            if (certificate.name) {
              return certificate;
            } else {
              return {
                name: certificate.name,
                from: null,
                to: null,
              };
            }
          })
        );
      }
      if (profile.state) {
        setLocationState(profile.state);
        dispatch(getLocationsCity(profile.state));
        setFetchState(true);
        if (profile.city) {
          setLocation(profile.city);
          setFetchCity(true);
        }
      }
      setPincode(profile.pincode);
      setPhone(profile.phone);
      if (profile.additionalSkills) {
        setAdditionalSkills(profile.additionalSkills);
      }
      if (profile.education !== undefined) {
        setEducations(profile.education);
      }
      if (profile.work_exp !== undefined) {
        setCompanies(profile.work_exp);
      }
      setSkills(profile.skills);
      // setLocation(profile.city);
      setUrl(profile.resume_url);
    }
  }, [profile]);

  const handleSave = (e) => {
    e.preventDefault();

    const updatedData = {
      name: fullName,
      city: location,
      state: locationState,
      pincode: pincode,
      phone: phone,
      skills: skills,
      additionalSkills: additionalSkills,
      education: educations,
      resume_url: Url,
      certifications: certificates,
      work_exp: companies,
    };

    if (!fullName) {
      setMessage("Invalid Name!");
      setOpenError(true);
      return;
    }
    if (phone.length < 10) {
      setMessage("Enter a valid phone number");
      setOpenError(true);
      return;
    }
    if (pincode.length < 6) {
      setMessage("Enter a valid 6 digit pincode");
      setOpenError(true);
      return;
    }

    if (skills === 0) {
      setMessage("Select Atleast One Skills");
      setOpenError(true);
      return;
    }

    //  if (educations.some((education) => education.university.trim() === "")){
    //   setMessage("enter education details");
    //    setOpenError(true);
    //     return;
    //  }

    dispatch(saveProfile(id, updatedData))
      .then(() => {
        setMessage("Profile Updated");
        setOpenSuccess(true);
        setTimeout(() => {
          navigate(`/profile/${id}`);
        }, 1500);
      })
      .catch(() => {
        setMessage("Profile could NOT be updated");
        setOpenError(true);
      });
  };
  const handleResumeUpload = () => {
    if (!resume) {
      setMessage("File Not Selected");
      setOpenError(true);
      return;
    }
    setLoaderResume(true);
    const uploadTask = storage.ref(`/resumes/${resume.name}`).put(resume);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // You can add progress bar update logic here if needed
      },
      (error) => {
        setMessage("Error Uploading Resume");
        setOpenError(true);
      },
      () => {
        setMessage("Resume Uploaded Successfully");
        setOpenSuccess(true);

        storage
          .ref("resumes")
          .child(resume.name)
          .getDownloadURL()
          .then((url) => {
            setUrl(url);
            setLoaderResume(false);
          });
      }
    );
  };

  const handleSkillsDelete = () => {
    // console.log("delete skills");
  };

  const addCertificates = () => {
    setCertificates([
      ...certificates,
      {
        name: "",
        from: null,
        to: null,
      },
    ]);
  };

  const removeCertificates = (index) => {
    const rem = [...certificates];
    rem.splice(index, 1);
    setCertificates(rem);
  };

  const updateCertificates = (name, index, value) => {
    const updatedFields = [...certificates];
    updatedFields[index][name] = value;
    setCertificates(updatedFields);
  };

  const addEducations = () => {
    setEducations([
      ...educations,
      {
        university: "",
        dergree: "",
        branch: "",
        pursuing: false,
        from: null,
        to: null,
      },
    ]);
  };

  const removeEducations = (index) => {
    const rem = [...educations];
    rem.splice(index, 1);
    setEducations(rem);
  };

  const updateEducations = (index, e, value) => {
    const updatedFields = [...educations];
    if (value) {
      updatedFields[index]["degree"] = value;
    } else if (e.target.name === "pursuing") {
      updatedFields[index][e.target.name] =
        !updatedFields[index][e.target.name];
      updatedFields[index]["to"] = !updatedFields[index][e.target.name]
        ? updatedFields[index]["to"]
        : null;
    } else {
      updatedFields[index][e.target.name] = e.target.value;
    }
    setEducations(updatedFields);
  };

  const addAdditionalSkill = () => {
    const newskill = newAdditionalSkill.trim();
    if (additionalSkills.includes(newskill)) {
      setNewadditionalSkill(newskill);
    } else {
      setAdditionalSkills([...additionalSkills, newskill]);
      setNewadditionalSkill("");
    }
  };
  const updateEducationsfromdate = (index, e) => {
    const updatedFields = [...educations];
    updatedFields[index]["from"] = e;
    setEducations(updatedFields);
  };
  const updateEducationstodate = (index, e) => {
    const updatedFields = [...educations];
    updatedFields[index]["to"] = e;
    setEducations(updatedFields);
  };

  const addCompanies = () => {
    setCompanies([
      ...companies,
      {
        role: "",
        current: false,
        company_name: "",
        from: null,
        to: null,
      },
    ]);
  };

  const removeCompanies = (index) => {
    const rem = [...companies];
    rem.splice(index, 1);
    setCompanies(rem);
  };

  const updateCompanies = (index, e) => {
    const updatedFields = [...companies];
    if (e.target.name === "current") {
      updatedFields[index][e.target.name] =
        !updatedFields[index][e.target.name];
      updatedFields[index]["to"] = !updatedFields[index][e.target.name]
        ? updatedFields[index]["to"]
        : null;
    } else {
      updatedFields[index][e.target.name] = e.target.value;
    }
    setCompanies(updatedFields);
  };
  const updateCompaniesfromdate = (index, e) => {
    const updatedFields = [...companies];
    updatedFields[index]["from"] = e;
    setCompanies(updatedFields);
  };
  const updateCompaniestodate = (index, e) => {
    const updatedFields = [...companies];
    updatedFields[index]["to"] = e;
    setCompanies(updatedFields);
  };
  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <>
      <div className="editProfile">
        <div className="pageHeading">Edit Profile</div>
        <div className="container">
          <div className="details">
            <div className="name col">
              Full Name *
              <TextField
                required
                size="small"
                id="outlined-basic"
                placeholder="Enter your Full Name"
                variant="outlined"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                sx={{
                  minWidth: 300,
                }}
                error={!fullName}
              />
            </div>
            <div className="email col">
              Email
              <TextField
                size="small"
                id="outlined-basic"
                value={profile.email}
                variant="outlined"
                disabled="true"
                sx={{
                  minWidth: 300,
                }}
              />
            </div>
            <div className="phone col">
              Phone Number *
              <TextField
                required
                // error={false}
                // helperText="Phone number should be 10 digits"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                size="small"
                id="outlined-basic"
                placeholder="ex: 8567875098"
                variant="outlined"
                maxLength={10}
                minLength={10}
                type="number"
                value={phone}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setPhone(e.target.value);
                  }
                }}
                sx={{
                  minWidth: 300,
                }}
                error={!phone}
                helperText={!phone ? "Enter a valid Phone Number" : ""}
              />
            </div>
          </div>

          <div className="details">
            <div className="location col">
              Your State
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                options={states?.map((option) => option)}
                onChange={handleLocationStateChange}
                sx={{
                  width: 200,
                }}
                value={locationState}
                //works for async variable on Autocomplete
                // defaultValue={profile.city}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    placeholder="Select your State"
                  // value={location}
                  />
                )}
              />
            </div>

            {fetchState && (
              <div className="location col">
                Your City
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={city?.map((option) => option)}
                  onChange={handleLocationCityChange}
                  sx={{
                    width: 200,
                  }}
                  //works for async variable on Autocomplete
                  value={location}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      placeholder="Select your city"
                    // value={location}
                    />
                  )}
                />
              </div>
            )}
            <div className="location col">
              Pincode *
              <TextField
                required
                // error={false}
                // helperText="Pincode should be 6 digits"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                size="small"
                id="outlined-basic"
                placeholder="ex: 123456"
                variant="outlined"
                maxLength={6}
                minLength={6}
                type="number"
                value={pincode}
                onChange={(e) => {
                  if (e.target.value.length <= 6) {
                    setPincode(e.target.value);
                  }
                }}
                sx={{
                  width: 200,
                }}
                error={!pincode}
                helperText={!pincode ? "Enter a valid 6 digit pincode" : ""}
              />
            </div>
          </div>
          <div className="skillRow">
            {profile.role !== "S_ADMIN" &&
              profile.role !== "Admin" &&
              profile.role !== "HR" && (
                <div className="skills col">
                  Skills
                  <Stack spacing={3} sx={{ width: 1058 }}>
                    <Autocomplete
                      size="small"
                      multiple
                      id="tags-outlined"
                      options={skillSet}
                      onChange={(e, selectedOptions) => {
                        const selectedSkills = selectedOptions.map(
                          (option) => option
                        );
                        setSkills(selectedSkills);
                      }}
                      getOptionLabel={(option) => option}
                      filterSelectedOptions
                      onDelete={handleSkillsDelete}
                      value={skills}
                      //only works for async variable on Autocomplete
                      defaultValue={profile.skills}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          placeholder="Add your skills"
                          onChange={(e) => {
                            // console.log(e.target.value);
                          }}
                          error={skills === 0}
                        />
                      )}
                    />
                  </Stack>
                </div>
              )}
          </div>
          <div className="skillRow">
            {profile.role !== "S_ADMIN" &&
              profile.role !== "Admin" &&
              profile.role !== "HR" && (
                <div className="skills col">
                  Additional Skills
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{ width: 1058 }}
                    className="additionalstack"
                  >
                    {additionalSkills &&
                      additionalSkills.map((skill, index) => (
                        <Chip
                          label={skill}
                          onDelete={() => {
                            additionalSkills.splice(index, 1);
                            setAdditionalSkills([...additionalSkills]);
                          }}
                          deleteIcon={<CancelIcon size="small" />}
                        />
                      ))}
                    <Input
                      placeholder="Add Your Additional skill"
                      disableUnderline="true"
                      value={newAdditionalSkill}
                      onChange={(e) => setNewadditionalSkill(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          addAdditionalSkill();
                        }
                      }}
                    />
                    {/* <TextField
                      size="small"
                      placeholder="ex: IIIT Pune"
                      id="outlined-basic"
                      disableUnderline="true"
                      variant="outlined"
                      name="university"
                      value={newAdditionalSkill}
                      onChange={(e) => setNewadditionalSkill(e.target.value)}
                      sx={{ minWidth: 500 }}
                    /> */}
                  </Stack>
                  {/* <div>
                    <button className="com save" onClick={handleSkillsSave}>
                      {isLoadingSkills ? (
                        <>
                          <CircularProgress
                            color="inherit"
                            size={12}
                            sx={{ marginRight: "4px" }}
                          />
                          Saving
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </button>
                  </div> */}
                </div>
              )}
          </div>

          <div className="careerDetail">
            <Typography sx={{ fontWeight: 500 }}>Education</Typography>
            {educations?.map((field, index) => (
              <div className="data col">
                <div className="row">
                  <div className="col">
                    University/School Name
                    <TextField
                      size="small"
                      placeholder="ex: IIIT Pune"
                      id="outlined-basic"
                      variant="outlined"
                      name="university"
                      value={field.university}
                      onChange={(e) => updateEducations(index, e)}
                      sx={{ minWidth: 500 }}
                      error={field.university === ""}
                      helperText={
                        field.university === ""
                          ? "University/School Name is required"
                          : ""
                      }
                    />
                  </div>
                  <div className="col col-checkbox">
                    <Checkbox
                      checked={field.pursuing}
                      name="pursuing"
                      onChange={(e) => updateEducations(index, e)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    Pursuing
                  </div>
                  {educations?.length !== 1 && (
                    <CloseIcon
                      sx={{
                        position: "relative",
                        left: "15rem",
                        bottom: "1rem",
                        color: "var(--primary-color)",
                        cursor: "pointer",
                      }}
                      onClick={() => removeEducations(index)}
                    />
                  )}
                </div>
                <div className="row">
                  <div className="col">
                    Degree Title
                    <Autocomplete
                      size="small"
                      name="degree"
                      disablePortal
                      id="combo-box-demo"
                      required
                      options={degrees?.map((option) => option)}
                      onChange={(e, newValue) =>
                        updateEducations(index, e, newValue)
                      }
                      sx={{
                        minWidth: 300,
                      }}
                      value={field.degree}
                      //works for async variable on Autocomplete
                      // defaultValue={profile.city}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          placeholder="Select your Degree"
                        // value={location}
                        />
                      )}
                    />
                  </div>
                  <div className="col">
                    Branch
                    <TextField
                      size="small"
                      placeholder="ex: Computer Science Engineering"
                      id="outlined-basic"
                      variant="outlined"
                      name="branch"
                      value={field.branch}
                      onChange={(e) => updateEducations(index, e)}
                      sx={{
                        minWidth: 300,
                      }}
                    />
                  </div>

                  <div className="date col">
                    From
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        views={["month", "year"]}
                        name="from"
                        value={dayjs(field.from)}
                        onChange={(e) => updateEducationsfromdate(index, e)}
                        sx={{ width: 200 }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="date col">
                    To
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled={field.pursuing}
                        formatDensity="dense"
                        views={["month", "year"]}
                        name="to"
                        value={dayjs(field.to)}
                        onChange={(e) => updateEducationstodate(index, e)}
                        sx={{ width: 200 }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            ))}
            {educations?.length && (
              <button className="button" onClick={addEducations}>
                + Add More
              </button>
            )}
          </div>
          <div className="careerDetail">
            <Typography sx={{ fontWeight: 500 }}>Work Experience</Typography>
            {companies?.map((field, index) => (
              <div className="data col">
                <div className="row">
                  <div className="col">
                    Role
                    <TextField
                      size="small"
                      placeholder="ex: Senior Devops"
                      id="outlined-basic"
                      variant="outlined"
                      name="role"
                      value={field.role}
                      onChange={(e) => updateCompanies(index, e)}
                      sx={{ minWidth: 500 }}
                    />
                  </div>
                  <div className="col col-checkbox">
                    <Checkbox
                      checked={field.current}
                      name="current"
                      onChange={(e) => updateCompanies(index, e)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    Current
                  </div>
                  {companies?.length !== 1 && (
                    <CloseIcon
                      sx={{
                        position: "relative",
                        left: "15rem",
                        bottom: "1rem",
                        color: "var(--primary-color)",
                        cursor: "pointer",
                      }}
                      onClick={() => removeCompanies(index)}
                    />
                  )}
                </div>
                <div className="row">
                  <div className="col">
                    Company Name
                    <TextField
                      size="small"
                      placeholder="ex: CGI"
                      id="outlined-basic"
                      variant="outlined"
                      name="company_name"
                      value={field.company_name}
                      onChange={(e) => updateCompanies(index, e)}
                      sx={{
                        minWidth: 300,
                      }}
                    />
                  </div>

                  <div className="date col">
                    From
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        views={["month", "year"]}
                        name="from"
                        value={dayjs(field.from)}
                        onChange={(e) => updateCompaniesfromdate(index, e)}
                        sx={{ width: 200 }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="date col">
                    To
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled={field.current}
                        views={["month", "year"]}
                        name="to"
                        value={dayjs(field.to)}
                        onChange={(e) => updateCompaniestodate(index, e)}
                        sx={{ width: 200 }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            ))}
            {companies?.length && (
              <button className="button" onClick={addCompanies}>
                + Add More
              </button>
            )}
          </div>

          <div className="careerDetail">
            <Typography sx={{ fontWeight: 500 }}>
              Certifications and Achievements
            </Typography>
            {certificates?.map((field, index) => (
              <div className="data col">
                <div className="row">
                  <div className="col">
                    Name
                    <TextField
                      size="small"
                      placeholder="ex: Google Codejam"
                      id="outlined-basic"
                      variant="outlined"
                      value={field.name}
                      onChange={(e) =>
                        updateCertificates("name", index, e.target.value)
                      }
                      sx={{ minWidth: 500 }}
                    />
                  </div>
                  <div className="date col">
                    From
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        views={["month", "year"]}
                        name="from"
                        value={dayjs(field.from)}
                        onChange={(e) => updateCertificates("from", index, e)}
                        sx={{ width: 200 }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="date col">
                    To
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled={field.current}
                        views={["month", "year"]}
                        name="to"
                        value={dayjs(field.to)}
                        onChange={(e) => updateCertificates("to", index, e)}
                        sx={{ width: 200 }}
                      />
                    </LocalizationProvider>
                  </div>
                  {certificates?.length !== 1 && (
                    <CloseIcon
                      sx={{
                        position: "relative",
                        left: "4rem",
                        color: "var(--primary-color)",
                        cursor: "pointer",
                      }}
                      onClick={() => removeCertificates(index)}
                    />
                  )}
                </div>
              </div>
            ))}
            {certificates?.length < 5 && (
              <button className="button" onClick={addCertificates}>
                + Add More
              </button>
            )}
          </div>

          <div className="careerDetail">
            <Typography sx={{ fontWeight: 500 }}>Resume</Typography>
            <div className="data col">
              <input
                // className="com save"
                type="file"
                onChange={(e) => {
                  setResume(e.target.files[0]);
                }}
              />
              <button
                className="button"
                onClick={handleResumeUpload}
                disable={loaderResume}
              >
                {loaderResume ? (
                  <>
                    <CircularProgress
                      color="inherit"
                      size={12}
                      sx={{ marginRight: "4px" }}
                    />
                    Uploading
                  </>
                ) : (
                  <>Upload</>
                )}
              </button>
            </div>
          </div>

          <div className="buttons">
            <button
              className="com cancel"
              onClick={() => {
                navigate(`/profile/${id}`);
              }}
            >
              Cancel
            </button>
            <button
              className="com save"
              onClick={handleSave}
              //making it inactive whilst the saving skills is ongoing
              disabled={isLoadingSkills}
            >
              {isLoadingStatus ? (
                <>
                  <CircularProgress
                    color="inherit"
                    size={12}
                    sx={{ marginRight: "4px" }}
                  />
                  Saving
                </>
              ) : (
                <>Save</>
              )}
            </button>
          </div>
        </div>
        <SnackBar
          message={message}
          openSuccess={openSuccess}
          openError={openError}
          handleClose={handleClose}
        />
      </div>
    </>
  );
};

export default EditProfile;
