import React from "react";
import { ReactComponent as NotFoundSVG } from "../../assets/404.svg";
import "./Error.css";
const NotFound = () => {
  return (
    <div className="errorPage">
      <div className="title"></div>
      <div className="svg">
        <NotFoundSVG />
      </div>
    </div>
  );
};

export default NotFound;
