import React, { useState } from "react";
import "./ExpenseCard.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { getExpenseById } from "../../actions/expense.action";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SnackBar from "../Alerts/SnackBar";
import { updateExpense } from "../../actions/expense.action";

const ExpenseCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.loader.isLoading);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const user = useSelector((state) => state.auth.user);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const handledetails = (e) => {
    e.preventDefault();
    dispatch(getExpenseById(props.id))
      .then(() => {
        navigate(`/expensedescription/${props.id}`);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };
  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <div className="expenseCard" onClick={handledetails}>
      <div className="header">
        <div className="expenseTitle">
          <div className="camp">{props.camp}</div>
          <div className="datetitle">
            {props.status ? "Created :" : "Organised"}
          </div>
          <div className="date">{props.date}</div>
        </div>

        <div className="details">
          {props.status && (
            <>
              <span
                style={{
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  color:
                    props.status === "Reimbursed"
                      ? "#008e0e"
                      : props.status === "Approved"
                      ? "blue"
                      : props.status === "Pending"
                      ? "#f29339"
                      : "#ba1b1b",
                }}
              >
                {props.status === "Approved" ||
                props.status === "Reimbursed" ? (
                  <CheckCircleOutlineIcon fontSize="small" />
                ) : props.status === "Rejected" ? (
                  <CancelOutlinedIcon fontSize="small" />
                ) : (
                  <HourglassTopIcon fontSize="small" />
                )}
                &nbsp;{props.status}
              </span>
            </>
          )}
        </div>
      </div>
      {/* {} */}
       {/*user.role === "S_ADMIN" && (
       <div className="footer"> */}
        {/* {props.comments && ( */}
          {/*<div className="comments">
            <div className="title">
              {props.status ? "Camp Name" : "No of camps" }
            </div>
            <div className="description">{props.camp_name}</div>
          </div>
        
         </div> 
      )} */}  
       
      <div className="footer">
        {props.comments && (
          <div className="comments">
            <div className="title">
              {props.status ? "Admin Comments" : "Number Trainers"}
            </div>
            <div className="description">{props.comments}</div>
          </div>
        )}
        <div className="date">
          {<CurrencyRupeeIcon style={{ fontSize: "15px" }} />}
          {props.amount}
        </div>
      </div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
    </div>
  );
};

export default ExpenseCard;
