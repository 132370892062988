import React, { useEffect, useState } from "react";
import "./AdminCamp.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllCamps } from "../../../actions/expense.action";
import CampCard from "./CampCard";
import { Pagination, Stack, Skeleton } from "@mui/material";
import SnackBar from "../../../components/Alerts/SnackBar";
import EmptyCard from "../../../components/Cards/EmptyCard";
const AdminCamp = () => {
  const dispatch = useDispatch();
  const camps = useSelector((state) => state.expense.camps);
  const isLoading = useSelector((state) => state.loader.isLoading);

  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };
  const [page, setPage] = React.useState(1);

  const handlePageChange = (event) => {
    setPage(event.target.innerText);
    dispatch(getAllCamps(event.target.innerText)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  };

  useEffect(() => {
    dispatch(getAllCamps(page)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  }, [page]);

  return (
    <div className="adminCamps">
      <div className="container">
        <div className="expenses">
          {!isLoading && camps.camps && !camps.camps.length && (
            <EmptyCard message="No Expenses Available" />
          )}
          {!isLoading ? (
            camps.camps &&
            camps.camps.map((expense) => (
              <CampCard
                camp={expense.data.name}
                passcode={expense.data.pass_code}
                description={expense.data.description}
              />
            ))
          ) : (
            <Stack spacing={2}>
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
            </Stack>
          )}
          {camps.camps && camps.camps.length > 0 && (
            <Stack spacing={2}>
              <Pagination
                count={camps.totalPages}
                defaultValue={page}
                onChange={handlePageChange}
              />
            </Stack>
          )}
        </div>
      </div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
    </div>
  );
};

export default AdminCamp;
