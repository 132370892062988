import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Outlet } from "react-router";
import { Tabs, Tab, Box } from "@mui/material";
import "./JobDetailHr.css";

const JobDetailHr = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          textColor=""
          indicatorColor=""
          aria-label="secondary tabs example"
          centered
          // sx={{
          //   "& .Mui-selected": {
          //     backgroundColor: "var(--primary-color)",
          //     color: "var(--light-text-color)",
          //   },
          // }}
        >
          <Tab
            value="one"
            label="Description"
            onClick={() => navigate("")}
            sx={{
              backgroundColor:
                location.pathname === `/jobdetailhr/${jobId}`
                  ? "var(--primary-color)"
                  : "",
              color:
                location.pathname === `/jobdetailhr/${jobId}`
                  ? "var(--light-text-color)"
                  : "var(--dull-text-color)",
            }}
          />
          <Tab
            value="two"
            label="Applied Candidates"
            onClick={() => navigate("applied")}
            sx={{
              backgroundColor:
                location.pathname === `/jobdetailhr/${jobId}/applied`
                  ? "var(--primary-color)"
                  : "",
              color:
                location.pathname === `/jobdetailhr/${jobId}/applied`
                  ? "var(--light-text-color)"
                  : "var(--dull-text-color)",
            }}
          />
          <Tab
            value="three"
            label="Probable Candidates"
            onClick={() => navigate("probable")}
            sx={{
              backgroundColor:
                location.pathname === `/jobdetailhr/${jobId}/probable`
                  ? "var(--primary-color)"
                  : "",
              color:
                location.pathname === `/jobdetailhr/${jobId}/probable`
                  ? "var(--light-text-color)"
                  : "var(--dull-text-color)",
            }}
          />
          <Tab
            value="four"
            label="Viewed Candidates"
            onClick={() => navigate("viewed")}
            sx={{
              backgroundColor:
                location.pathname === `/jobdetailhr/${jobId}/viewed`
                  ? "var(--primary-color)"
                  : "",
              color:
                location.pathname === `/jobdetailhr/${jobId}/viewed`
                  ? "var(--light-text-color)"
                  : "var(--dull-text-color)",
            }}
          />
        </Tabs>
      </Box>
      <Outlet />
    </div>
  );
};

export default JobDetailHr;
