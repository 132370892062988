import JobService from "../services/job.service";
import {
  SET_MESSAGE,
  GET_ALL_JOBS,
  GET_ALL_JOBS_FOR_ADMIN,
  GET_JOB_BY_ID,
  GET_JOBS_BY_HR_ID,
  CREATE_JOB,
  UPDATE_JOB,
  CLOSE_JOB,
  HIDE_JOB,
  UNHIDE_JOB,
  LOADER,
  GET_ALL_CANDIDATES,
  GET_PROBABLE,
  GET_VIEWED,
  INSERT_VIEWED,
  GET_ALL_APPLICATIONS_USER,
  GET_APPLICATION_USER,
  APPLY_JOB,
  UPDATE_STATUS,
  BULK_REJECT,
  STATUS_LOADER,
  GET_LOCATIONS,
  GET_PINCODES,
  GET_DEGREES,
  GET_LOCATIONS_STATES,
} from "./types";

export const getAllJobs = (page, search) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return JobService.getAllJobs(page, search).then(
    (response) => {
      dispatch({ type: GET_ALL_JOBS, payload: { jobs: response.response } });
      dispatch({ type: LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      dispatch({ type: LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const getAllJobsForAdmin =
  (page, status, start_date, end_date) => (dispatch) => {
    dispatch({ type: LOADER, payload: true });
    return JobService.getAllJobsForAdmin(
      page,
      status,
      start_date,
      end_date
    ).then(
      (response) => {
        if (page !== "All") {
          dispatch({
            type: GET_ALL_JOBS_FOR_ADMIN,
            payload: { jobs: response.response },
          });
          dispatch({ type: LOADER, payload: false });
          return Promise.resolve();
        }
        dispatch({ type: LOADER, payload: false });
        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({ type: SET_MESSAGE, payload: message });
        dispatch({ type: LOADER, payload: false });
        return Promise.reject(message);
      }
    );
  };

export const getJobById = (id) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return JobService.getJobById(id).then(
    (response) => {
      dispatch({ type: GET_JOB_BY_ID, payload: { job: response } });
      dispatch({ type: LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      dispatch({ type: SET_MESSAGE, payload: message });
      dispatch({ type: LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const getJobsByHrId =
  (hrId, page, start_date, end_date) => (dispatch) => {
    dispatch({ type: LOADER, payload: true });
    return JobService.getJobsByHrId(hrId, page, start_date, end_date).then(
      (response) => {
        if (page !== "All") {
          dispatch({
            type: GET_JOBS_BY_HR_ID,
            payload: { jobs: response.response },
          });
          dispatch({ type: LOADER, payload: false });
          return Promise.resolve(response.response);
        }
        dispatch({ type: LOADER, payload: false });
        return Promise.resolve(response.response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({ type: SET_MESSAGE, payload: message });
        dispatch({ type: LOADER, payload: false });
        return Promise.reject(message);
      }
    );
  };

export const createJob =
  (
    job_title,
    job_type,
    expiry_date,
    state,
    location,
    skills,
    job_decription,
    short_job_description,
    questions
  ) =>
  (dispatch) => {
    dispatch({ type: STATUS_LOADER, payload: true });
    return JobService.createJob(
      job_title,
      job_type,
      expiry_date,
      state,
      location,
      skills,
      job_decription,
      short_job_description,
      questions
    ).then(
      (response) => {
        dispatch({ type: CREATE_JOB });
        dispatch({ type: SET_MESSAGE, payload: response.data.message });
        dispatch({ type: STATUS_LOADER, payload: false });
        return Promise.resolve();
      },
      (error) => {
        const message =
          error.response.data.error ||
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({ type: SET_MESSAGE, payload: message });
        dispatch({ type: STATUS_LOADER, payload: false });
        return Promise.reject(message);
      }
    );
  };

export const updateJob = (expiry_date, jobId) => (dispatch) => {
  return JobService.updateJob(expiry_date, jobId).then(
    (response) => {
      dispatch({ type: UPDATE_JOB });
      dispatch({ type: SET_MESSAGE, payload: response.data.message });
      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      return Promise.reject(message);
    }
  );
};

export const closeJob = (jobId) => (dispatch) => {
  return JobService.closeJob(jobId).then(
    (response) => {
      dispatch({ type: CLOSE_JOB });
      dispatch({ type: SET_MESSAGE, payload: response.data.message });
      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      return Promise.reject(message);
    }
  );
};

export const hideJob = (jobId, feedback) => (dispatch) => {
  dispatch({ type: STATUS_LOADER, payload: true });
  return JobService.hideJob(jobId, feedback).then(
    (response) => {
      dispatch({ type: HIDE_JOB });
      dispatch({ type: SET_MESSAGE, payload: response.data.message });
      dispatch({ type: STATUS_LOADER, payload: false });
      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      dispatch({ type: STATUS_LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const unhideJob = (jobId) => (dispatch) => {
  dispatch({ type: STATUS_LOADER, payload: true });
  return JobService.unhideJob(jobId).then(
    (response) => {
      dispatch({ type: UNHIDE_JOB });
      dispatch({ type: SET_MESSAGE, payload: response.data.message });
      dispatch({ type: STATUS_LOADER, payload: false });
      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      dispatch({ type: STATUS_LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const getCandidates = (jobId) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return JobService.getCandidates(jobId).then(
    (response) => {
      dispatch({
        type: GET_ALL_CANDIDATES,
        payload: { candidates: response.data },
      });
      dispatch({ type: LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      dispatch({ type: LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const getProbable = (jobId) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return JobService.getProbable(jobId).then(
    (response) => {
      dispatch({ type: GET_PROBABLE, payload: { candidates: response.data } });
      dispatch({ type: LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      dispatch({ type: LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const getViewed = (jobId) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return JobService.getViewed(jobId).then(
    (response) => {
      dispatch({ type: GET_VIEWED, payload: { candidates: response.data } });
      dispatch({ type: LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      dispatch({ type: LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const insertViewed = (jobId, userId, skills) => (dispatch) => {
  dispatch({ type: STATUS_LOADER, payload: true });
  return JobService.insertViewed(jobId, userId, skills).then(
    (response) => {
      dispatch({ type: INSERT_VIEWED });
      dispatch({ type: SET_MESSAGE, payload: response.data.message });
      dispatch({ type: STATUS_LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        error.response.data.error ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      dispatch({ type: STATUS_LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const getApplications =
  (userId, page, start_date, end_date) => (dispatch) => {
    dispatch({ type: LOADER, payload: true });
    return JobService.getApplications(userId, page, start_date, end_date).then(
      (response) => {
        if (page !== "All") {
          dispatch({
            type: GET_ALL_APPLICATIONS_USER,
            payload: { applications: response.data },
          });
          dispatch({ type: LOADER, payload: false });
          return Promise.resolve(response);
        }
        dispatch({ type: LOADER, payload: false });
        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({ type: SET_MESSAGE, payload: message });
        dispatch({ type: LOADER, payload: false });
        return Promise.reject(message);
      }
    );
  };

export const getApplication = (jobId, applicant_id) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return JobService.getApplication(jobId, applicant_id).then(
    (response) => {
      dispatch({
        type: GET_APPLICATION_USER,
        payload: { application: response.data },
      });
      dispatch({ type: LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      dispatch({ type: LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const applyJob = (jobId, quiz) => (dispatch) => {
  dispatch({ type: STATUS_LOADER, payload: true });
  return JobService.applyJob(jobId, quiz).then(
    (response) => {
      dispatch({ type: APPLY_JOB });
      dispatch({ type: SET_MESSAGE, payload: response.data.message });
      dispatch({ type: STATUS_LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      dispatch({ type: STATUS_LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const updateStatus =
  (jobId, status, applicant_id,email, name, feedback ) => (dispatch) => {
    dispatch({ type: STATUS_LOADER, payload: true });
    return JobService.updateStatus(jobId, status, applicant_id, email, name, feedback).then(
      (response) => {
        dispatch({ type: UPDATE_STATUS });
        dispatch({ type: SET_MESSAGE, payload: response.data.message });
        dispatch({ type: STATUS_LOADER, payload: false });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({ type: SET_MESSAGE, payload: message });
        dispatch({ type: STATUS_LOADER, payload: false });
        return Promise.reject(message);
      }
    );
  };

export const bulkReject = (jobId, applicants) => (dispatch) => {
  dispatch({ type: STATUS_LOADER, payload: true });
  return JobService.bulkReject(jobId, applicants).then(
    (response) => {
      dispatch({ type: BULK_REJECT });
      dispatch({ type: SET_MESSAGE, payload: response.data.message });
      dispatch({ type: STATUS_LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      dispatch({ type: STATUS_LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const getLocations = () => (dispatch) => {
  return JobService.getLocations().then(
    (response) => {
      dispatch({
        type: GET_LOCATIONS_STATES,
        payload: { states: response.data },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      return Promise.reject();
    }
  );
};

export const getLocationsCity = (city) => (dispatch) => {
  return JobService.getLocationsCity(city).then(
    (response) => {
      dispatch({ type: GET_LOCATIONS, payload: { city: response.data } });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      return Promise.reject();
    }
  );
};

export const getLocationsPincodes = (state, city) => (dispatch) => {
  return JobService.getLocationsPincodes(state, city).then(
    (response) => {
      dispatch({ type: GET_PINCODES, payload: { pincodes: response.data } });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      return Promise.reject();
    }
  );
};

export const getDegreeList = () => (dispatch) => {
  return JobService.getDegreeList().then(
    (response) => {
      dispatch({ type: GET_DEGREES, payload: { degrees: response.data } });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      return Promise.reject();
    }
  );
};
getDegreeList();
