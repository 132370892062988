import React from "react";
import "./Input.css";

const Input = ({ type, field, placeholder, value, onChange, req, dis }) => {
  return (
    <div className="inputDiv">
      <label className="inputLabel">{field}</label>
      <input
        className="inputBox"
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={req}
        disabled={dis}
      />
    </div>
  );
};

export default Input;
