import React from "react";
import "./ExpenseBill.css";
import {useState} from "react";
 
// const ExpenseBill = ({ bills }) => {
//   const { subexpensename, amount, billUrl } = bills;
//   const openDialog = () => {
//     // Check if the dialog element exists
//     const dialog = document.querySelector('.ui-dialog');
//     if (dialog) {
//       // Open the dialog bar popup
//       dialog.style.display = 'block';
//     } else {
//       // Handle the error
//       console.error('The dialog element does not exist.');
//     }
//   };
//   return (
//     <div className="expenseBill">
//       <div className="subexpense">
//         <div className="nameexp">
//           <div className="exphead">Sub-Expense Name:</div>
//           <div className="expval">{subexpensename}</div>
//         </div>
//         <div className="amountexp">
//           <div className="exphead">Amount:</div>
//           <div className="expval">₹{amount}</div>
//         </div>
//         <div className="billexp">
//           <div className="exphead">Bill:</div>
//           <div className="expval">
//             <a href={billUrl} target="_blank" rel="noreferrer" onClick={openDialog}>
//               {subexpensename} Bill
//             </a>
            
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
const ExpenseBill = ({ bills }) => {
  const { subexpensename, amount, billUrl } = bills;
  const [isOpen, setIsOpen] = useState(false);
 
  const openDialog = () => {
    setIsOpen(true);
  };
 
  const closeDialog = () => {
    setIsOpen(false);
  };
 
  return (
    <div className="expenseBill">
      <div className="subexpense">
        <div className="nameexp">
          <div className="exphead">Sub-Expense Name:</div>
          <div className="expval">{subexpensename}</div>
        </div>
        <div className="amountexp">
          <div className="exphead">Amount:</div>
          <div className="expval">₹{amount}</div>
        </div>
        <div className="billexp">
          <div className="exphead">Bill:</div>
          <div className="expval">
            <button onClick={openDialog}>View Bill</button>
          </div>
        </div>
      </div>
 
      {isOpen && (
        <div className="dialog">
          <div className="dialog-header" >
            <h2>{subexpensename} Bill</h2>
            <button onClick={closeDialog}>Close</button>
          </div>
          <div className="dialog-body">
            <embed src={billUrl} width="965" height="630"></embed>
          </div>
        </div>
      )}
    </div>
  );
};
 
export default ExpenseBill;