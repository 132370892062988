import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verifyEmail } from "../../actions/auth";
import "./VerifyEmail.css";
import { CircularProgress } from "@mui/material";
import SnackBar from "../../components/Alerts/SnackBar";

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const isLoading = useSelector((state) => state.loader.isLoading);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  useEffect(() => {
    const handleVerify = () => {
      dispatch(verifyEmail(token))
        .then((message) => {
          setMessage(message);
          setOpenSuccess(true);
          handleRedirect();
        })
        .catch((message) => {
          setMessage(message);
          setOpenError(true);
        });
    };

    handleVerify();
  }, [dispatch, token]);

  const handleRedirect = () => {
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  };

  return (
    <div className="verifyPage">
      <div className="verifyContainer">
        <div className="title">Email Verification</div>
        {isLoading && (
          <CircularProgress
            color="inherit"
            size={50}
            sx={{ marginRight: "4px" }}
          />
        )}
      </div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
    </div>
  );
};

export default VerifyEmail;
