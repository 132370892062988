import React, { useEffect } from "react";
import "./ApplicationDetail.css";
import { getApplication } from "../../../actions/job.action";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Chip } from "@mui/material";

import {
  HourglassTop,
  CancelOutlined,
  CheckCircleOutline,
  Work,
  FmdGood,
} from "@mui/icons-material";

const ApplicationDetail = () => {
  const dispatch = useDispatch();
  const { jobId, applicantId } = useParams();

  const user = useSelector((state) => state.auth.user);
  const isLoading = useSelector((state) => state.loader.isLoading);
  const application = useSelector((state) => state.job.application);

  useEffect(() => {
    if (!(user.role === "Admin" && user.role === "S_ADMIN")) {
      dispatch(getApplication(jobId, applicantId));
    }
  }, []);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <div className="applicationDetail">
      <div className="pageHeading">Job Description</div>
      <div className="applicationDesc">
        {Object.keys(application).length > 0 && (
          <>
            <div className="jobContainer">
              <div
                className="actions"
                style={{
                  backgroundColor:
                    application.answers.data.status === "Selected"
                      ? "#008e0e"
                      : application.answers.data.status === "Rejected"
                      ? "#ba1b1b"
                      : application.answers.data.status === "Waitlist"
                      ? "var(--primary-color)"
                      : "#ffa500",
                }}
              >
                {application.answers.data.status && (
                  <span
                    style={{
                      fontSize: "25px",
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    {application.answers.data.status === "Selected" ? (
                      <CheckCircleOutline />
                    ) : application.answers.data.status === "Rejected" ? (
                      <CancelOutlined />
                    ) : (
                      <HourglassTop />
                    )}
                    &nbsp;{application.answers.data.status}
                  </span>
                )}
                {application.answers.data.status === "Rejected" && (
                  <div className="feedback">
                    <div className="title">Reason for Rejection</div>
                    <div className="content">
                      {application.answers.data.feedback}
                    </div>
                  </div>
                )}
              </div>

              <div className="card">
                <div className="header">
                  <div className="jobTitle">
                    <div className="title">
                      {application.job.data.job_title}
                    </div>
                    <div className="company">
                      {application.job.data.company_name}
                    </div>
                  </div>
                  <div className="text">
                    <Chip
                      size="small"
                      color="success"
                      icon={<FmdGood />}
                      label={application.job.data.location}
                      variant="outlined"
                    />
                    &nbsp;
                    <Chip
                      size="small"
                      color="warning"
                      icon={
                        <Work
                          fontSize="small"
                          sx={{ paddingLeft: "2px", fontSize: "20px" }}
                        />
                      }
                      label={application.job.data.job_type}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="last">
                  <div className="skills">Skills</div>
                  <div className="tags">
                    {application.job.data.skills !== undefined
                      ? application.job.data.skills.map((skill) => (
                          <Chip
                            size="small"
                            color="info"
                            label={skill}
                            variant="outlined"
                          />
                        ))
                      : null}
                  </div>
                </div>
                <div className="mid">
                  <div className="dates">
                    <div className="postedDate">
                      <div className="label">Posted on:</div>
                      <div className="text">
                        {new Date(
                          application.job.data.created_date
                        ).toDateString()}
                      </div>
                    </div>
                    <div className="lastDate">
                      <div className="label">Last date:</div>
                      <div className="text">
                        {new Date(
                          application.job.data.expiry_date
                        ).toDateString()}
                      </div>
                    </div>
                    <div className="hrDetails">
                      <div className="label">Posted by:</div>
                      <div className="text">
                        {application.job.data.created_by_name}
                      </div>
                    </div>
                  </div>
                  <span
                    style={{
                      fontSize: "14px",
                      color:
                        application.job.data.job_status === "Active"
                          ? "#008e0e"
                          : "#ba1b1b",
                      fontWeight: 500,
                    }}
                  >
                    {application.job.data.job_status.toUpperCase()}
                  </span>
                </div>
              </div>
              <div className="card">
                <div className="heading">About Company</div>
                <div className="text">
                  {application.job.data.company_description}
                </div>
              </div>
              <div className="card">
                <div className="heading">About Job</div>
                <div className="editContainer">
                  <div className="text">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {application.job.data.job_description}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            </div>
            <div className="questions">
              <div className="questionTitle">Application Questions</div>
              {application.job.data.questions !== null &&
                application.job.data.questions.map((question, index) => (
                  <div key={index}>
                    <p className="question">
                      Q{index + 1}: {question}
                    </p>
                    <p className="answer">
                      {application.answers.data.quiz[index]}
                    </p>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ApplicationDetail;
