// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cadet-navbar .profileBtn {
  margin-top: 2px;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--primary-color);
  border: none;
  border-radius: 0.5rem;
  color: var(--light-text-color);
  cursor: pointer;
  font-weight: 500;
  padding: 0.5rem 1rem;
}

.cadet-navbar .loginNav {
  margin-top: 4px;
  margin-left: 1.5rem;
  background-color: var(--primary-color);
  border: none;
  border-radius: 0.5rem;
  color: var(--light-text-color);
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
}
.cadet-navbar .MuiButton-root {
  text-transform: capitalize;
  margin: 0 0 0 1rem;
}
.cadet-navbar .MuiButton-root:hover {
  background-color: var(--primary-color);
  color: var(--light-text-color);
}
.MuiMenu-paper {
  padding: 0rem 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,sCAAsC;EACtC,YAAY;EACZ,qBAAqB;EACrB,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,sCAAsC;EACtC,YAAY;EACZ,qBAAqB;EACrB,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,sBAAsB;AACxB;AACA;EACE,0BAA0B;EAC1B,kBAAkB;AACpB;AACA;EACE,sCAAsC;EACtC,8BAA8B;AAChC;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".cadet-navbar .profileBtn {\n  margin-top: 2px;\n  margin-left: 1.5rem;\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  background-color: var(--primary-color);\n  border: none;\n  border-radius: 0.5rem;\n  color: var(--light-text-color);\n  cursor: pointer;\n  font-weight: 500;\n  padding: 0.5rem 1rem;\n}\n\n.cadet-navbar .loginNav {\n  margin-top: 4px;\n  margin-left: 1.5rem;\n  background-color: var(--primary-color);\n  border: none;\n  border-radius: 0.5rem;\n  color: var(--light-text-color);\n  cursor: pointer;\n  font-weight: 500;\n  font-size: 16px;\n  padding: 0.5rem 1.5rem;\n}\n.cadet-navbar .MuiButton-root {\n  text-transform: capitalize;\n  margin: 0 0 0 1rem;\n}\n.cadet-navbar .MuiButton-root:hover {\n  background-color: var(--primary-color);\n  color: var(--light-text-color);\n}\n.MuiMenu-paper {\n  padding: 0rem 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
