// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verifyPage {
  min-height: 100vh;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verifyPage .verifyContainer {
  background-color: white;
  padding: 30px 10vw;
  border: 1px solid var(--dark-text-color);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.verifyPage .verifyContainer .title {
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--dark-text-color);
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/modules/Register/VerifyEmail.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,yCAAyC;EACzC,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,wCAAwC;EACxC,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,WAAW;AACb","sourcesContent":[".verifyPage {\n  min-height: 100vh;\n  background-color: var(--background-color);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.verifyPage .verifyContainer {\n  background-color: white;\n  padding: 30px 10vw;\n  border: 1px solid var(--dark-text-color);\n  border-radius: 5px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 1rem;\n}\n\n.verifyPage .verifyContainer .title {\n  font-weight: 400;\n  font-size: 1rem;\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n  color: var(--dark-text-color);\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
