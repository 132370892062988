// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jobPortal {
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
}

.jobPortal .left {
  flex: 3 1;
  background-color: white;
  height: max-content;
  border-radius: 10px;
  /* temporary fix */
  max-width: 300px;
  position: sticky;
  top: 5.5rem;
}

.jobPortal .right {
  flex: 9 1;
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.jobPortal .jobs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/modules/Jobs/JobPortal/JobPortal.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,iBAAiB;EACjB,aAAa;EACb,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,SAAO;EACP,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,SAAO;EACP,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".jobPortal {\n  background-color: var(--background-color);\n  min-height: 100vh;\n  display: flex;\n  gap: 1rem;\n  padding: 1.5rem;\n}\n\n.jobPortal .left {\n  flex: 3;\n  background-color: white;\n  height: max-content;\n  border-radius: 10px;\n  /* temporary fix */\n  max-width: 300px;\n  position: sticky;\n  top: 5.5rem;\n}\n\n.jobPortal .right {\n  flex: 9;\n  background: transparent;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.jobPortal .jobs {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
