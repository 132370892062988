import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import "./RequestHr.css";
import Input from "../../components/Input/Input";
import { sendHrRequest, sendAccessRequest } from "../../actions/profile.action";
import { logout } from "../../actions/auth";
import SnackBar from "../../components/Alerts/SnackBar";
import DialogueBox from "../../components/Alerts/DialogueBox";
import logo from "../../assets/Logo.png";


function RequestHr() {
  const navigate = useNavigate();
  const goBack = () =>{ navigate(-1);};
  const dispatch = useDispatch();
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [mobile, setMobile] = useState("");
  const isLoading = useSelector((state) => state.loader.isLoading);
  const user = useSelector((state) => state.auth.user);

  const handleNameChange = (e) => {
    setCompanyName(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setCompanyDescription(e.target.value);
  };
  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };
  const [check, setCheck] = useState(false);
  const [roletype, setRoletype] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [dialogueMessage, setDialogueMessage] = useState(
    "Invalid Request!"
  );

  const handleClickOpen = (role) => {
    if (role === "cadet") {
      setRoletype("cadet");
      setDialogueMessage("Only EXPA CADET Program attendees can access this portal. Are you an attendee?");
    } else if (role === "trainer") {
      setRoletype("trainer");
      setDialogueMessage("Only EXPA CADET Program trainer can get this access. Are you a trainer?");
    } else if (role === "hr") {
      setRoletype("hr");
      setDialogueMessage("Only HR (Recruitment) of organizations can get this access. Are you an HR?");
    }
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleRequestHR = () => {
    setOpenAlert(false);
    if (roletype === "cadet") {
      dispatch(sendAccessRequest(user.email, "Cadet"))
        .then((message) => {
          setMessage(message);
          setOpenSuccess(true);
          setTimeout(() => {
            navigate("/login");
          }, 3700);
          setTimeout(() => {
            dispatch(logout());
          }, 4000);
        })
        .catch((message) => {
          setMessage(message);
          setOpenError(true);
        });
    } else if (roletype === "trainer") {
      dispatch(sendAccessRequest(user.email, "Trainer"))
        .then((message) => {
          setMessage(message);
          setOpenSuccess(true);
          setTimeout(() => {
            navigate("/login");
          }, 3700);
          setTimeout(() => {
            dispatch(logout());
          }, 4000);
        })
        .catch((message) => {
          setMessage(message);
          setOpenError(true);
        });
    } else if (roletype === "hr") {
      dispatch(sendHrRequest(companyName, companyDescription, mobile))
        .then((message) => {
          setMessage(message);
          setOpenSuccess(true);
          handleRedirect();
        })
        .catch((message) => {
          setMessage(message);
          setOpenError(true);
        });
    }
  };

  const handleRedirect = () => {
    setTimeout(() => {
      navigate("/");
    }, 1500);
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <div className="requestPage">
        <div className="requestContainer">
          <div className="title" onClick={() => navigate("/")}>
            <span className="logo">
              <img src={logo} alt="logo" height="60px" width="60px" />
            </span>
            <span className="appName">CADET 360</span>
          </div>
          <div className="request">Request Access</div>
          {!check && (
            <div className="selectaccess">
              <div className="button">
                <Button
                  text="Cadet Access"
                  handleClick={() => handleClickOpen("cadet")}
                />
              </div>
              <div className="button">
                <Button
                  text="Trainer Access"
                  handleClick={() => handleClickOpen("trainer")}
                />
              </div>
              <div className="button">
                <Button text="HR Access" handleClick={() => setCheck(true)} />
              </div>
          <div className="backbtn">
                <a href="../">Go Back</a>
          </div>
            </div>
          )}
          {check && (
            <div className="formContainer">
              <Input
                type="text"
                field="Company Name"
                placeholder="Enter Company Name"
                value={companyName}
                onChange={handleNameChange}
                req={true}
              />
              <Input
                type="number"
                field="Contact Number"
                placeholder="Enter Contact Number"
                value={mobile}
                onChange={handleMobileChange}
                req={true}
              />
              <div className="textField">
                <label className="textLabel">Company Description</label>
                <textarea
                  className="textInput"
                  rows="5"
                  onChange={handleDescriptionChange}
                ></textarea>
              </div>
              <div className="button">
                <Button text="Back" handleClick={() => setCheck(false)} />
                <Button
                  text="Submit"
                  handleClick={() => handleClickOpen("hr")}
                />
              </div>  
            </div>
          )}
        </div>
      </div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
      <DialogueBox
        dialogueMessage={dialogueMessage}
        handleFunction={handleRequestHR}
        handleCloseAlert={handleCloseAlert}
        openAlert={openAlert}
      />
    </>
  );
}

export default RequestHr;
