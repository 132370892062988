import React, { useState, useEffect } from "react";
import "./AdminCampExpenses.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpenseCard from "../../../components/ExpenseComponent/ExpenseCard";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import LinearProgress from "@mui/material/LinearProgress";
import { getExpensesByCamp } from "../../../actions/expense.action";
import { Pagination, Stack, Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/Alerts/SnackBar";
import EmptyCard from "../../../components/Cards/EmptyCard";

const AdminCampExpenses = () => {
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };
  dayjs.extend(relativeTime);
  const [status, setStatus] = useState("All");
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setStatus(event.target.value);
    setPage(1);
    dispatch(getExpensesByCamp(campname, 1, event.target.value)).catch(
      (err) => {
        setMessage(err);
        setOpenError(true);
      }
    );
  };
  const [page, setPage] = React.useState(1);

  const handlePageChange = (event) => {
    setPage(event.target.innerText);
    dispatch(getExpensesByCamp(campname, event.target.innerText, status)).catch(
      (err) => {
        setMessage(err);
        setOpenError(true);
      }
    );
  };
  const isLoading = useSelector((state) => state.loader.isLoading);
  let val = 0;
  const expenses = useSelector((state) => state.expense.expenses);
  const { campname } = useParams();
  expenses.expenses &&
    expenses.expenses.length &&
    expenses.expenses.map((expense) => (val = val + expense.data.total_amount));

  useEffect(() => {
    dispatch(getExpensesByCamp(campname, page, status)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  }, [page]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      {expenses.expenses && expenses.expenses.length > 0 ? (
        <div className="admincampRequests">
          <div className="pageHeading">
            <div>
              <span style={{ fontWeight: "300", fontSize: "18px" }}>
                Number of Requests :{" "}
              </span>
              {expenses.expenses.length}
            </div>
            <div>{campname}</div>
            <div>
              <span style={{ fontWeight: "300", fontSize: "18px" }}>
                Total Amount : ₹{" "}
              </span>
              {val}
            </div>
          </div>
          <div className="container">
            {!isLoading && <div className="status">Expense status</div>}
            {!isLoading && (
              <FormControl sx={{ mb: 2, minWidth: 180 }}>
                <Select
                  value={status}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: 40,
                    backgroundColor: "white",
                    color: "var(--dull-text-color)",
                  }}
                >
                  <MenuItem value="" disabled>
                    Select status
                  </MenuItem>
                  <MenuItem value="All" style={{ color: "black" }}>
                    All Expenses
                  </MenuItem>
                  <MenuItem value="Reimbursed" style={{ color: "#008e0e" }}>
                    Reimbursed
                  </MenuItem>
                  <MenuItem value="Approved" style={{ color: "blue" }}>
                    Approved
                  </MenuItem>
                  <MenuItem value="Revision" style={{ color: "#ba1b1b" }}>
                    Revision
                  </MenuItem>
                  <MenuItem value="Pending" style={{ color: "#f29339" }}>
                    Pending
                  </MenuItem>
                  <MenuItem value="Rejected" style={{ color: "#ba1b1b" }}>
                    Rejected
                  </MenuItem>
                </Select>
              </FormControl>
            )}
            <div className="expenses">
              {!isLoading && expenses.expenses && !expenses.expenses.length && (
                <EmptyCard message="No Expenses Available" />
              )}
              {!isLoading ? (
                expenses.expenses &&
                expenses.expenses.map(
                  (expense) =>
                    (status === "All" || expense.data.status === status) && (
                      <ExpenseCard
                        id={expense.id}
                        camp={expense.data.name}
                        date={dayjs().to(expense.data.created_at)}
                        comments={
                          expense.data.comment !== undefined
                            ? expense.data.comment
                            : ""
                        }
                        amount={expense.data.total_amount}
                        status={expense.data.status}
                      />
                    )
                )
              ) : (
                <Stack spacing={2}>
                  <Skeleton
                    sx={{ bgcolor: "var(--alternate-button-color)" }}
                    variant="rounded"
                    height={50}
                    width={200}
                  />
                  <Skeleton
                    sx={{ bgcolor: "var(--alternate-button-color)" }}
                    variant="rounded"
                    height={150}
                  />
                  <Skeleton
                    sx={{ bgcolor: "var(--alternate-button-color)" }}
                    variant="rounded"
                    height={150}
                  />
                  <Skeleton
                    sx={{ bgcolor: "var(--alternate-button-color)" }}
                    variant="rounded"
                    height={150}
                  />
                  <Skeleton
                    sx={{ bgcolor: "var(--alternate-button-color)" }}
                    variant="rounded"
                    height={150}
                  />
                  <Skeleton
                    sx={{ bgcolor: "var(--alternate-button-color)" }}
                    variant="rounded"
                    height={150}
                  />
                  <Skeleton
                    sx={{ bgcolor: "var(--alternate-button-color)" }}
                    variant="rounded"
                    height={150}
                  />
                  <Skeleton
                    sx={{ bgcolor: "var(--alternate-button-color)" }}
                    variant="rounded"
                    height={150}
                  />
                  <Skeleton
                    sx={{ bgcolor: "var(--alternate-button-color)" }}
                    variant="rounded"
                    height={150}
                  />
                  <Skeleton
                    sx={{ bgcolor: "var(--alternate-button-color)" }}
                    variant="rounded"
                    height={150}
                  />
                  <Skeleton
                    sx={{ bgcolor: "var(--alternate-button-color)" }}
                    variant="rounded"
                    height={150}
                  />
                </Stack>
              )}
              {expenses.expenses && expenses.expenses.length > 0 && (
                <Stack spacing={2}>
                  <Pagination
                    count={expenses.totalPages}
                    defaultValue={page}
                    onChange={handlePageChange}
                  />
                </Stack>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="container"
          style={{ marginTop: "5rem", width: "70%", marginLeft: "15%" }}
        >
          <EmptyCard message="No Expenses Available" />
        </div>
      )}
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
    </>
  );
};

export default AdminCampExpenses;
