import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllJobs, getApplications } from "../../../actions/job.action";
import JobFilter from "../../../components/JobComponent/JobFilter";
import JobCard from "../../../components/JobComponent/JobCard";
import SnackBar from "../../../components/Alerts/SnackBar";
import EmptyCard from "../../../components/Cards/EmptyCard";
import { Pagination, Stack, Skeleton } from "@mui/material";
import "./JobPortal.css";

const JobPortal = (props) => {
  const dispatch = useDispatch();
  const loc = useLocation();
  const prevLocation = useRef(loc);
  const user = useSelector((state) => state.auth.user);
  let applications = useSelector((state) => state.job.applications);
  if (!applications.jobDetails) applications.jobDetails = [];
  console.log(user, applications);
  const jobs = useSelector((state) => state.job.jobs);
  const isLoading = useSelector((state) => state.loader.isLoading);

  const [page, setPage] = useState(1);
  const [message, setMessage] = useState("");
  const [openError, setOpenError] = useState(false);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  useEffect(() => {
    if (loc !== prevLocation.current) {
      // console.log(loc);
    }
    dispatch(getAllJobs(page, loc.search)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
    prevLocation.current = loc;
  }, [page, loc]);

  useEffect(() => {
    if (user.role !== "Guest" && user.role !== "HR") {
      dispatch(getApplications(user.id, page)).catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
    }
  }, [user.id]);

  return (
    <div className="jobPortal">
      <SnackBar
        message={message}
        openError={openError}
        handleClose={handleClose}
      />
      <div className="left">
        <JobFilter />
      </div>
      <div className="right">
        <div className="jobs">
          {!isLoading && jobs.jobs && !jobs.jobs.length && (
            <EmptyCard message="No Jobs Available" />
          )}
          {!isLoading ? (
            jobs.jobs &&
            jobs.jobs.map((job, index) => (
              <div key={index}>
                {job.data.is_active && (
                  <JobCard
                    job={job}
                    key={index}
                    isApplied={
                      applications.jobDetails.length &&
                      applications.jobDetails.find(
                        (app) => app.data.job_id === job.id
                      )
                    }
                    isActiveJobPage="true"
                  />
                )}
              </div>
            ))
          ) : (
            <Stack spacing={2}>
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
            </Stack>
          )}
        </div>
        {jobs.jobs && jobs.jobs.length > 0 && (
          <Stack spacing={2}>
            <Pagination
              count={jobs.totalPages}
              defaultValue={page}
              onChange={handlePageChange}
            />
          </Stack>
        )}
      </div>
    </div>
  );
};

export default JobPortal;
