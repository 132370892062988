import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Data from "../../../components/Cards/Data";
import PersonalCard from "../../../components/Cards/PersonalCard";
import { getApplication, updateStatus } from "../../../actions/job.action";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LinearProgress, CircularProgress } from "@mui/material";
import {
  Alert,
  Snackbar,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import "./JobApplication.css";
import SnackBar from "../../../components/Alerts/SnackBar";
import TextField from "@mui/material/TextField";

const JobApplication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobId, applicant_id } = useParams();

  const application = useSelector((state) => state.job.application);
  const isLoadingStatus = useSelector((state) => state.loader.isLoadingStatus);

  const [message, setMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  const [rejectComment, setRejectComment] = useState(null);
  const [openRejectComment, setOpenRejectComment] = useState(false);

  const { vertical, horizontal } = state;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
    setOpenError(false);
  };

  const handleReject = () => {
    setOpenRejectComment(true);
  };

  const handleNotReject = () => {
    setRejectComment(null);
    setOpenRejectComment(false);
  };

  const handleConfirmReject = () => {
    if (!rejectComment) {
      setMessage("Please enter a comment");
      setOpenError(true);
      return;
    }
    dispatch(
      updateStatus(
        jobId,
        "Rejected",
        applicant_id,
        application.user.user.email,
        application.user.user.name,
        rejectComment
      )
    )
      .then(() => {
        setMessage("Status has been updated successfully");
        setOpenSuccess(true);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };

  const handleAccept = () => {
    dispatch(
      updateStatus(
        jobId,
        "Selected",
        applicant_id,
        application.user.user.email,
        application.user.user.name
      )
    )
      .then(() => {
        setMessage("Status has been updated successfully");
        setOpenSuccess(true);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };

  const handleWaitlist = () => {
    dispatch(
      updateStatus(
        jobId,
        "Waitlist",
        applicant_id,
        application.user.user.email,
        application.user.user.name
      )
    )
      .then(() => {
        setMessage("Status has been updated successfully");
        setOpenSuccess(true);

        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };

  useEffect(() => {
    dispatch(getApplication(jobId, applicant_id)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  }, []);

  const DateConverter = (dateString) => {
    if (!dateString) {
      return "Present";
    }
    const dateObj = new Date(dateString);
    const month = dateObj.toLocaleString("default", { month: "long" });
    const year = dateObj.getFullYear();
    return `${month} ${year}`;
  };

  return (
    <div className="jobApplication">
      <div className="pageHeading">Applicant Profile</div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
      {Object.keys(application).length > 0 && (
        <div className="profile">
          <PersonalCard profile={application.user} />
          <div className="userInfoContainer">
            {/* Work Experience */}
            <Accordion sx={{ borderRadius: "5px" }} disableGutters="true">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="accordionTitle">Work Experience</div>
              </AccordionSummary>
              <AccordionDetails>
                {/* 1 Data component per experience */}
                {application.user.user.work_exp && application.user.user.work_exp.map((experience) => (
                  <>
                    {(experience.role || experience.company_name) && (
                      <Data
                        title={experience.role}
                        subTitle={experience.company_name}
                        duration={`${DateConverter(
                          experience.from
                        )} - ${DateConverter(experience.to)}`}
                      />
                    )}
                    <Divider />
                  </>
                ))}
              </AccordionDetails>
            </Accordion>
            {/* Education */}
            <Accordion sx={{ borderRadius: "5px" }} disableGutters="true">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="accordionTitle">Education</div>
              </AccordionSummary>
              <AccordionDetails>
              {application.user.user.education && application.user.user.education.map((education) => (
                  <>
                    {(education.university || education.degree) && (
                      <Data
                        title={education.university}
                        subTitle={education.degree}
                        duration={`${DateConverter(
                          education.from
                        )} - ${DateConverter(education.to)}`}
                      />
                    )}
                    <Divider />
                  </>
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ borderRadius: "5px" }} disableGutters="true">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="accordionTitle">Certificates</div>
              </AccordionSummary>
              <AccordionDetails>
              {application.user.user.certifications && application.user.user.certifications.map((certificate) => (
                  <>
                    {certificate.name && (
                      <Data
                        title={certificate.name}
                        duration={`${DateConverter(
                          certificate.from
                        )} - ${DateConverter(certificate.to)}`}
                      />
                    )}
                    <Divider />
                  </>
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ borderRadius: "5px" }} disableGutters="true">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="accordionTitle">Resume</div>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  <a
                    href={application.user.user.resume_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {application.user.user.name + ".resume"}
                  </a>
                </p>
              </AccordionDetails>
            </Accordion>
            <div className="questions">
              <div className="accordionTitle">Application Questions</div>
              {application.questions !== null &&
                application.questions.map((question, index) => (
                  <div>
                    <div className="question">
                      Q{index + 1}: {question}
                    </div>
                    <div className="answer">
                      {application.answers.data.lemgth > index
                        ? application.answers.data.quiz[index]
                        : " "}
                    </div>
                  </div>
                ))}
              {openRejectComment ? (
                <div className="rejectBox">
                  <div className="headlabel">
                    Rejection Comments {"(required)"}
                  </div>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    placeholder="Add Rejection Comment"
                    variant="outlined"
                    name="rejectComment"
                    value={rejectComment}
                    onChange={(e) => setRejectComment(e.target.value)}
                    sx={{
                      minWidth: 500,
                      backgroundColor: "white",
                    }}
                  />
                  <div className="rejectButtons">
                    <button className="com cancel" onClick={handleNotReject}>
                      Cancel
                    </button>
                    <button className="com save" onClick={handleConfirmReject}>
                      {isLoadingStatus ? (
                        <>
                          <CircularProgress
                            color="inherit"
                            size={12}
                            sx={{ marginRight: "4px" }}
                          />
                          Rejecting
                        </>
                      ) : (
                        <>Save & Reject</>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {application.answers.data.status === "Rejected" ? (
                    <div className="feedback">
                      <div className="title">Rejection Comment</div>
                      <div className="content">
                        {application.answers.data.feedback}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="buttons">
                    <button
                      className="com accept"
                      onClick={handleAccept}
                      style={{
                        cursor: isLoadingStatus ? "progress" : "pointer",
                      }}
                    >
                      Select
                    </button>
                    <button
                      className="com waitlist"
                      onClick={handleWaitlist}
                      style={{
                        cursor: isLoadingStatus ? "progress" : "pointer",
                      }}
                    >
                      Waitlist
                    </button>
                    {application.answers.data.status === "Rejected" ? (
                      <></>
                    ) : (
                      <button
                        className="com reject"
                        onClick={handleReject}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Reject
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobApplication;
