import React, { useState, useEffect } from "react";
import JobCard from "../../../components/JobComponent/JobCard";
import { useDispatch, useSelector } from "react-redux";
import { getJobsByHrId } from "../../../actions/job.action";
import { Stack, Skeleton, Pagination } from "@mui/material";
import SnackBar from "../../../components/Alerts/SnackBar";
import EmptyCard from "../../../components/Cards/EmptyCard";
import exportFromJSON from "export-from-json";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./ManageJobHR.css";

const ManageJobHR = (props) => {
  dayjs.extend(relativeTime);
  const dispatch = useDispatch();

  const hrId = useSelector((state) => state.auth.user.id);
  const jobs = useSelector((state) => state.job.jobs);
  const isLoading = useSelector((state) => state.loader.isLoading);

  const [page, setPage] = useState(1);
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState("");
  const [check, setCheck] = useState(false);
  const [start_date, setStart_date] = useState(dayjs(Date.now()));
  const [end_date, setEnd_Date] = useState(dayjs(Date.now()));

  const handleOpenedit = () => setCheck(true);
  const handleCloseedit = () => setCheck(false);

  const handleStartDateChange = (event) => {
    setStart_date(event);
  };
  const handleEndDateChange = (event) => {
    setEnd_Date(event);
  };

  const handlePageChange = (event) => {
    setPage(event.target.innerText);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  useEffect(() => {
    if (props.userid === undefined) {
      dispatch(getJobsByHrId(hrId, page)).catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
    } else {
      dispatch(getJobsByHrId(props.userid, page)).catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
    }
  }, [page]);

  const handleDownload = () => {
    if (props.userid === undefined) {
      dispatch(getJobsByHrId(hrId, "All", start_date, end_date))
        .then((response) => {
          const data = response.jobs;
          const fileName = `Past Jobs Posted of ${response.jobs[0].created_by_name}`;
          const exportType = exportFromJSON.types.csv;
          exportFromJSON({ data, fileName, exportType });
          handleCloseedit();
        })
        .catch((err) => {
          setMessage(err);
          setOpenError(true);
          handleCloseedit();
        });
    } else {
      dispatch(getJobsByHrId(props.userid, "All", start_date, end_date))
        .then((response) => {
          const data = response.jobs;
          const fileName = `Past Jobs Posted of ${response.jobs[0].created_by_name}`;
          const exportType = exportFromJSON.types.csv;
          exportFromJSON({ data, fileName, exportType });
          handleCloseedit();
        })
        .catch((err) => {
          setMessage(err);
          setOpenError(true);
          handleCloseedit();
        });
    }
  };

  return (
    <div className="managejobhr">
      {props.userid === undefined && (
        <div className="pageHeading">Manage Jobs</div>
      )}

      <SnackBar
        message={message}
        openError={openError}
        handleClose={handleClose}
      />
      <div className="container">
        {!isLoading && props.userid && (
          <button className="com mainBtn" onClick={handleOpenedit}>
            Download All Jobs
          </button>
        )}
        <div
          className="jobs"
          style={!props.userid ? { marginTop: "2.5rem" } : {}}
        >
          {!isLoading && jobs.jobs && !jobs.jobs.length && (
            <EmptyCard message="No Jobs Available" />
          )}
          {!isLoading ? (
            jobs.jobs &&
            jobs.jobs.map((job, index) => <JobCard job={job} key={index} />)
          ) : (
            <Stack spacing={2}>
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
            </Stack>
          )}
          {jobs.jobs && jobs.jobs.length > 0 && (
            <Stack spacing={2}>
              <Pagination
                count={jobs.totalPages}
                defaultValue={page}
                onChange={handlePageChange}
              />
            </Stack>
          )}
        </div>
      </div>
      <Modal
        open={check}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="Modalcontaineredit">
          <div className="createFA">
            <div className="pageHeadingModal">
              <div>Select Date Range</div>
              <div onClick={handleCloseedit} className="closebtn">
                <CloseIcon />
              </div>
            </div>
            <div className="personalbank">
              <div className="personalbankContainer">
                <div className="formContainer">
                  <div className="inputField">
                    <span>Start Date</span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={start_date}
                        maxDate={dayjs(Date.now())}
                        onChange={handleStartDateChange}
                        sx={{ width: 200 }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="inputField">
                    <span>End Date</span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={end_date}
                        maxDate={dayjs(Date.now())}
                        onChange={handleEndDateChange}
                        sx={{ width: 200 }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="buttonsedit">
                    <button
                      className="comedit createedit"
                      onClick={handleDownload}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ManageJobHR;
