import "./Cards.css";
const EmptyCard = (props) => {
  return (
    <div className="emptyCard">
      <div className="title">{"(*>﹏<*)′"}</div>
      <div className="message">{props.message}</div>
    </div>
  );
};

export default EmptyCard;
