import {
  REQUEST_HR_SUCCESS,
  GET_HR_REQUESTS_SUCCESS,
  GET_PROFILE,
  SET_HR_REQUEST,
  GET_NOTIFY,
  SET_NOTIFY,
  GET_ENTRIES,
  GET_CAMPS,
  SAVE_SKILLS,
  SAVE_PROFILE,
  SET_ROLE,
  GET_SKILLSET,
  REQUEST_ACCESS_SUCCESS,
} from "../actions/types";

const initialState = {
  requests: [],
  user: {},
  notification: false,
  entries: [],
  skillSet: [],
  camps: [],
};

export const profile = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_HR_SUCCESS:
      return state;
    case REQUEST_ACCESS_SUCCESS:
      return state;
    case GET_HR_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: payload.requests.data,
      };
    case GET_PROFILE:
      return {
        ...state,
        user: payload.requestdata.user,
      };
    case SET_HR_REQUEST:
      return {
        ...state,
        user: payload.requestdata.user,
      };
    case GET_NOTIFY:
      return {
        ...state,
        notification: payload.notification,
      };
    case SET_NOTIFY:
      return state;
    case GET_ENTRIES:
      return {
        ...state,
        entries: payload.entries.data,
      };
    case GET_CAMPS:
      return {
        ...state,
        camps: payload.requestdata,
      };
    case SAVE_SKILLS:
      return state;

    case SAVE_PROFILE:
      return state;

    case SET_ROLE:
      return state;

    case GET_SKILLSET:
      return {
        ...state,
        skillSet: payload.requestdata,
      };

    default:
      return state;
  }
};
