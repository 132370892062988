// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admincampRequests {
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admincampRequests .pageHeading {
  position: fixed;
  z-index: 999;
  top: 4rem;
  width: 100%;
  background-color: var(--primary-color);
  color: var(--light-text-color);
  font-size: 20px;
  padding: 1rem 0rem 1rem 0rem;
  display: flex;
  justify-content: space-evenly;
}

.admincampRequests .container {
  width: 70%;
  margin-top: 3rem;
}

.admincampRequests .status {
  font-weight: 500;
  font-size: 14px;
  color: var(--primary-color);
}

.admincampRequests .expenses {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/modules/Expense/AdminCampExpenses/AdminCampExpenses.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,YAAY;EACZ,SAAS;EACT,WAAW;EACX,sCAAsC;EACtC,8BAA8B;EAC9B,eAAe;EACf,4BAA4B;EAC5B,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".admincampRequests {\n  min-height: 100vh;\n  padding: 2rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.admincampRequests .pageHeading {\n  position: fixed;\n  z-index: 999;\n  top: 4rem;\n  width: 100%;\n  background-color: var(--primary-color);\n  color: var(--light-text-color);\n  font-size: 20px;\n  padding: 1rem 0rem 1rem 0rem;\n  display: flex;\n  justify-content: space-evenly;\n}\n\n.admincampRequests .container {\n  width: 70%;\n  margin-top: 3rem;\n}\n\n.admincampRequests .status {\n  font-weight: 500;\n  font-size: 14px;\n  color: var(--primary-color);\n}\n\n.admincampRequests .expenses {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
