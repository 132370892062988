import React, { useState, useEffect } from "react";
import "./AdminRequests.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpenseCard from "../../../components/ExpenseComponent/ExpenseCard";
import EmptyCard from "../../../components/Cards/EmptyCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllExpenses,
  getExpensesByUserIdDownload,
} from "../../../actions/expense.action";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Pagination, Stack, Skeleton } from "@mui/material";
import SnackBar from "../../../components/Alerts/SnackBar";
import exportFromJSON from "export-from-json";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const AdminRequests = () => {
  dayjs.extend(relativeTime);
  const [status, setStatus] = useState("All");
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
    setPage(1);
    dispatch(getAllExpenses(1, event.target.value)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  };

  const [page, setPage] = React.useState(1);

  const handlePageChange = (event) => {
    setPage(event.target.innerText);
    window.scrollTo(0, 0);
    dispatch(getAllExpenses(event.target.innerText, status)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  };

  const [check, setCheck] = useState(false);
  const [start_date, setStart_date] = useState(dayjs(Date.now()));
  const [end_date, setEnd_Date] = useState(dayjs(Date.now()));

  const handleOpenedit = () => setCheck(true);
  const handleCloseedit = () => setCheck(false);

  const handleStartDateChange = (event) => {
    setStart_date(event);
  };
  const handleEndDateChange = (event) => {
    setEnd_Date(event);
  };

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader.isLoading);
  const expenses = useSelector((state) => state.expense.expenses);
  useEffect(() => {
    dispatch(getAllExpenses(page, status)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  }, [page]);
  const handleDownload = () => {
    dispatch(getExpensesByUserIdDownload("All", start_date, end_date))
      .then((response) => {
        // console.log(response.data);
        const data = response.data;
        const fileName = "All Expenses";
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
    handleCloseedit();
  };
  return (
    <div className="adminRequests">
      <div className="container">
        {!isLoading && <div className="status">Expense status</div>}
        {!isLoading && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <FormControl sx={{ mb: 2, minWidth: 180 }}>
              <Select
                value={status}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: 40,
                  backgroundColor: "white",
                  color: "var(--dull-text-color)",
                }}
              >
                <MenuItem value="" disabled>
                  Select status
                </MenuItem>
                <MenuItem value="All" style={{ color: "black" }}>
                  All Expenses
                </MenuItem>
                <MenuItem value="Reimbursed" style={{ color: "#008e0e" }}>
                  Reimbursed
                </MenuItem>
                <MenuItem value="Approved" style={{ color: "blue" }}>
                  Approved
                </MenuItem>
                <MenuItem value="Revision" style={{ color: "#ba1b1b" }}>
                  Revision
                </MenuItem>
                <MenuItem value="Pending" style={{ color: "#f29339" }}>
                  Pending
                </MenuItem>
                <MenuItem value="Rejected" style={{ color: "#ba1b1b" }}>
                  Rejected
                </MenuItem>
              </Select>
            </FormControl>

            <button className="com mainBtn" onClick={handleOpenedit}>
              Download All Expenses
            </button>
          </div>
        )}
        <div className="expenses">
          {!isLoading && expenses.expenses && !expenses.expenses.length && (
            <EmptyCard message="No Expenses Available" />
          )}
          {!isLoading ? (
            expenses.expenses &&
            expenses.expenses.map(
              (expense) =>
                (status === "All" || expense.data.status === status) && (
                  <ExpenseCard
                    id={expense.id}
                    camp={expense.data.name}
                    camp_name={expense.data.camp_name}
                    date={dayjs().to(expense.data.created_at)}
                    comments={
                      expense.data.comment !== undefined
                        ? expense.data.comment
                        : ""
                    }
                    amount={expense.data.total_amount}
                    status={expense.data.status}
                  />
                )
            )
          ) : (
            <Stack spacing={2}>
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={50}
                width={200}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
            </Stack>
          )}
          {expenses.expenses && expenses.expenses.length > 0 && (
            <Stack spacing={2}>
              <Pagination
                count={expenses.totalPages}
                defaultValue={page}
                onChange={handlePageChange}
              />
            </Stack>
          )}
        </div>
      </div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />

      <Modal
        open={check}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="Modalcontaineredit">
          <div className="createFA">
            <div className="pageHeadingModal">
              <div>Select Date Range</div>
              <div onClick={handleCloseedit} className="closebtn">
                <CloseIcon />
              </div>
            </div>
            <div className="personalbank">
              <div className="personalbankContainer">
                <div className="formContainer">
                  <div className="inputField">
                    <span>Start Date </span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={start_date}
                        maxDate={dayjs(Date.now())}
                        onChange={handleStartDateChange}
                        sx={{ width: 200 }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="inputField">
                    <span>End Date</span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={end_date}
                        maxDate={dayjs(Date.now())}
                        onChange={handleEndDateChange}
                        sx={{ width: 200 }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="buttonsedit">
                    <button
                      className="comedit createedit"
                      onClick={handleDownload}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminRequests;
