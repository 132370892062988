import React from "react";
import { useNavigate } from "react-router";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import WorkIcon from "@mui/icons-material/Work";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Chip } from "@mui/material";
import "./ApplicationCard.css";
import { useSelector } from "react-redux";

const ApplicationCard = ({ application }) => {
  dayjs.extend(relativeTime);

  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const { job_title, job_type, location, company_name } = application.jobs;
  const { applicant_id, job_id, applied_date, status } = application.data;

  const date = dayjs().to(applied_date);

  const nav =
    user.role === "Admin" || user.role === "S_ADMIN"
      ? `/job/${job_id}`
      : `/applied/${job_id}/${applicant_id}`;

  return (
    <div className="applicationCard" onClick={() => navigate(nav)}>
      <div className="header">
        <div className="jobTitle">
          <div className="title">{job_title}</div>
          <div className="company">{company_name}</div>
        </div>
        <div className="date">{date}</div>
      </div>
      <div className="footer">
        <div className="tags">
          <Chip
            size="small"
            color="success"
            icon={<FmdGoodIcon />}
            label={location}
            variant="outlined"
          />
          <Chip
            size="small"
            color="warning"
            icon={
              <WorkIcon
                fontSize="small"
                sx={{ paddingLeft: "2px", fontSize: "20px" }}
              />
            }
            label={job_type}
            variant="outlined"
          />
        </div>
        <div className="details">
          {status && (
            <span
              style={{
                fontSize: "14px",
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                color:
                  status === "Selected"
                    ? "#008e0e"
                    : status === "Rejected"
                    ? "#ba1b1b"
                    : status === "Waitlist"
                    ? "var(--primary-color)"
                    : "#ffa500",
              }}
            >
              {status === "Selected" ? (
                <CheckCircleOutlineIcon />
              ) : status === "Rejected" ? (
                <CancelOutlinedIcon />
              ) : (
                <HourglassTopIcon />
              )}
              {status}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationCard;
