import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendResetEmail } from "../../actions/auth";
import Button from '../../components/Button/Button';
import './Forgot.css';
import Input from '../../components/Input/Input';
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import SnackBar from "../../components/Alerts/SnackBar";
import DialogueBox from "../../components/Alerts/DialogueBox";
import logo from "../../assets/Logo.png";

const ResetEmail = () => {
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const isLoading = useSelector((state) => state.loader.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBack = () =>{ navigate(-1);};
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [dialogueMessage, setDialogueMessage] = useState(
    "Do you really want to proceed with this email?"
  );

  const handleClickOpen = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleRedirect = () => {
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  const handleEmailSubmit = () => {
    setOpenAlert(false);
    dispatch(sendResetEmail(email))
      .then((message) => {
        setMessage(message);
        setOpenSuccess(true);
        handleRedirect();
      })
      .catch((message) => {
        setMessage(message);
        setOpenError(true);
      });
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <div className="forgotPage">
        <div className="forgotContainer">
          <div className="title" onClick={() => navigate("/")}>
            <span className="logo">
              <img src={logo} alt="logo" height="60px" width="60px" />
            </span>
            <span className="appName">CADET 360</span>
          </div>
          <div className="forgot">Reset Password</div>
          <div className="formContainer">
            <div className="inputField">
              <Input
                type="email"
                field="Email"
                placeholder="Enter Email"
                value={email}
                onChange={handleEmailChange}
                req={true}
              />
            </div>
            <div className="redirect">
              <p>
                *Please enter your registered Email ID.
              </p>
            </div>
            <div className="button">
              <Button text="Submit" handleClick={handleClickOpen} />
            </div>
            <div className="backbtn">
                <a href="../">Go Back</a>
            </div>
          </div>
        </div>
      </div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
      <DialogueBox
        dialogueMessage={dialogueMessage}
        handleFunction={handleEmailSubmit}
        handleCloseAlert={handleCloseAlert}
        openAlert={openAlert}
      />
    </>
  );
};

export default ResetEmail;