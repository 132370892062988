// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expenseBill {
  background-color: #d9e0ef;
  padding: 1rem 2.5rem 1rem 1.5rem;
  border-radius: 5px;
  box-sizing: border-box;
  border: 0.5px solid #2d3092;
  display: flex;
  flex-direction: column;
  width: 50%;
}
 
.expenseBill .subexpense {
  display: flex;
  justify-content: space-between;
}
 
.expenseBill .exphead {
  font-size: 14px;
  font-weight: 500;
  color: #2d3092;
}
 
.expenseBill .expval {
  font-size: 13px;
  letter-spacing: 0.03em;
  font-weight: 500;
}
.dialog {
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:"1000";
  height:"700";
  background-color: white;
  border: 1px solid black;
  z-index: 2;
  position: absolute;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

h2 {
  font-family: 'Arial', sans-serif;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 10px;
  margin: auto;
  display: block;
  background-color:  #2d3092;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
 `, "",{"version":3,"sources":["webpack://./src/components/ExpenseComponent/ExpenseBill.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gCAAgC;EAChC,kBAAkB;EAClB,sBAAsB;EACtB,2BAA2B;EAC3B,aAAa;EACb,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY;EACZ,YAAY;EACZ,uBAAuB;EACvB,uBAAuB;EACvB,UAAU;EACV,kBAAkB;EAClB,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,gCAAgC;EAChC,cAAc;EACd,2CAA2C;EAC3C,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,YAAY;EACZ,cAAc;EACd,0BAA0B;EAC1B,gBAAgB;EAChB,0BAA0B;EAC1B,wBAAwB;EACxB,wBAAwB;EACxB,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":[".expenseBill {\n  background-color: #d9e0ef;\n  padding: 1rem 2.5rem 1rem 1.5rem;\n  border-radius: 5px;\n  box-sizing: border-box;\n  border: 0.5px solid #2d3092;\n  display: flex;\n  flex-direction: column;\n  width: 50%;\n}\n \n.expenseBill .subexpense {\n  display: flex;\n  justify-content: space-between;\n}\n \n.expenseBill .exphead {\n  font-size: 14px;\n  font-weight: 500;\n  color: #2d3092;\n}\n \n.expenseBill .expval {\n  font-size: 13px;\n  letter-spacing: 0.03em;\n  font-weight: 500;\n}\n.dialog {\n  top: 75%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width:\"1000\";\n  height:\"700\";\n  background-color: white;\n  border: 1px solid black;\n  z-index: 2;\n  position: absolute;\n  border-radius: 10px;\n  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);\n}\n\nh2 {\n  font-family: 'Arial', sans-serif;\n  color: #ffffff;\n  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);\n  letter-spacing: 1px;\n  text-transform: uppercase;\n  padding: 10px;\n  margin: auto;\n  display: block;\n  background-color:  #2d3092;\n  font-size: 1.5em;\n  margin-block-start: 0.83em;\n  margin-block-end: 0.83em;\n  margin-inline-start: 0px;\n  margin-inline-end: 0px;\n  font-weight: bold;\n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
