import {
  GET_ALL_CAMPS,
  CREATE_CONTACT_ID,
  CREATE_FA_ID,
  CREATE_EXPENSE,
  GET_ALL_EXPENSES,
  GET_FAID,
  GET_EXPENSE_BY_ID,
  REJECT_EXPENSE,
  PAY_EXPENSE,
  GET_ALL_EXPENSES_BY_ID,
  GET_ALL_EXPENSES_BY_CAMP,
  UPDATE_EXPENSE,
} from "../actions/types";

const initialState = {
  camps: [],
  camp: "",
  contactId: {},
  faId: "",
  expenses: [],
  expense: {},
  email: "",
  bank:{},
};

export const expense = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_CAMPS:
      return {
        ...state,
        camps: payload.camps,
      };
    case CREATE_CONTACT_ID:
      return {
        ...state,
        contactId: payload.contactId,
      };
    case CREATE_FA_ID:
      return {
        ...state,
        faId: payload.faId,
        email: payload.email,
      };
    case CREATE_EXPENSE:
      return state;
    case UPDATE_EXPENSE:
      return state;
    case GET_ALL_EXPENSES:
      return {
        ...state,
        expenses: payload.expenses,
      };
    case GET_FAID:
      return {
        ...state,
        faId: payload.faId,
        email: payload.email,
        bank: payload.bank,
      };
    case GET_EXPENSE_BY_ID:
      return {
        ...state,
        expense: payload.expense,
      };
    case REJECT_EXPENSE:
      return state;
    case PAY_EXPENSE:
      return state;
    case GET_ALL_EXPENSES_BY_ID:
      return {
        ...state,
        expenses: payload.expenses,
      };
    case GET_ALL_EXPENSES_BY_CAMP:
      return {
        ...state,
        expenses: payload.expenses,
        camp: payload.camp,
      };
    default:
      return state;
  }
};
