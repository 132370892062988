// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tablecontainer {
  width: 90%;
  background-color: white;
  margin: 0 auto;
  height: 100%;
}

.tablecontainer .reject {
  margin: 0.5rem;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #ba1b1b;
  color: var(--light-text-color);
  border-radius: 5px;
}

.tablecontainer .loading {
  margin: 0.5rem;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  border: 1.5px solid #ba1b1b;
  background-color: #e77c7c54;
  color: #ba1b1b;
}
`, "",{"version":3,"sources":["webpack://./src/components/JobComponent/Table.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,uBAAuB;EACvB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,yBAAyB;EACzB,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,2BAA2B;EAC3B,2BAA2B;EAC3B,cAAc;AAChB","sourcesContent":[".tablecontainer {\n  width: 90%;\n  background-color: white;\n  margin: 0 auto;\n  height: 100%;\n}\n\n.tablecontainer .reject {\n  margin: 0.5rem;\n  padding: 8px 20px;\n  border: none;\n  border-radius: 5px;\n  font-weight: 500;\n  cursor: pointer;\n  background-color: #ba1b1b;\n  color: var(--light-text-color);\n  border-radius: 5px;\n}\n\n.tablecontainer .loading {\n  margin: 0.5rem;\n  padding: 8px 20px;\n  border: none;\n  border-radius: 5px;\n  font-weight: 500;\n  cursor: pointer;\n  border: 1.5px solid #ba1b1b;\n  background-color: #e77c7c54;\n  color: #ba1b1b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
