import ExpenseService from "../services/expense.service";
import {
  SET_MESSAGE,
  GET_ALL_CAMPS,
  LOADER,
  CREATE_CONTACT_ID,
  CREATE_FA_ID,
  CREATE_EXPENSE,
  GET_ALL_EXPENSES,
  GET_FAID,
  GET_EXPENSE_BY_ID,
  REJECT_EXPENSE,
  PAY_EXPENSE,
  GET_ALL_EXPENSES_BY_ID,
  GET_ALL_EXPENSES_BY_CAMP,
  FAID_LOADER,
  UPDATE_EXPENSE,
} from "./types";

export const getAllCamps = (page) => (dispatch) => {
  if(page!=="All") dispatch({ type: LOADER, payload: true });
  return ExpenseService.getAllCamps(page).then(
    (response) => {
      dispatch({ type: GET_ALL_CAMPS, payload: { camps: response.data } });
      dispatch({ type: LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      if(page!=="All") dispatch({ type: LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const createContactId =
  (name, email, contact, user_id) => (dispatch) => {
    dispatch({ type: FAID_LOADER, payload: true });
    return ExpenseService.createContactId(name, email, contact, user_id).then(
      (response) => {
        let cont_id = "";
        if (response.data.contact_data !== undefined) {
          cont_id = response.data.contact_data.id;
        } else {
          cont_id = response.data.data.contact_id;
        }
        dispatch({ type: CREATE_CONTACT_ID, payload: { contactId: cont_id } });
        dispatch({ type: SET_MESSAGE, payload: response.data.msg });
        dispatch({ type: FAID_LOADER, payload: false });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({ type: SET_MESSAGE, payload: message });
        return Promise.reject(message);
      }
    );
  };

export const createFaId =
  (contact_id, name, email, ifsc, account_number) => (dispatch) => {
    dispatch({ type: LOADER, payload: true });
    return ExpenseService.createFaId(
      contact_id,
      name,
      email,
      ifsc,
      account_number
    ).then(
      (response) => {
        let f_id = "";
        let eml = "";
        if (response.data.fa_data !== undefined) {
          f_id = response.data.fa_data.id;
          eml = response.data.email;
        } else {
          f_id = response.data.data.fa_id;
          eml = response.data.data.email;
        }
        dispatch({ type: CREATE_FA_ID, payload: { faId: f_id, email: eml } });
        dispatch({ type: SET_MESSAGE, payload: response.msg });
        dispatch({ type: LOADER, payload: false });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({ type: SET_MESSAGE, payload: message });
        return Promise.reject(message);
      }
    );
  };

export const createExpense =
  (camp_name, fa_id, created_by, status, total_amount, bills, name, email) =>
  (dispatch) => {
    // console.log(fa_id);
    dispatch({ type: LOADER, payload: true });
    return ExpenseService.createExpense(
      camp_name,
      fa_id,
      created_by,
      status,
      total_amount,
      bills,
      name,
      email
    ).then(
      (response) => {
        dispatch({ type: CREATE_EXPENSE });
        dispatch({ type: SET_MESSAGE, payload: response.msg });
        dispatch({ type: LOADER, payload: false });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({ type: SET_MESSAGE, payload: message });
        return Promise.reject(message);
      }
    );
  };

export const getAllExpenses = (page, status) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return ExpenseService.getAllExpenses(page, status).then(
    (response) => {
      dispatch({
        type: GET_ALL_EXPENSES,
        payload: { expenses: response.data },
      });
      dispatch({ type: LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      dispatch({ type: LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const getFaId = (user_id) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return ExpenseService.getFaId(user_id).then(
    (response) => {
      // console.log(response.data);
      let f_id = "";
      let eml = "";
      if (response.data.fa_response === undefined) {
        f_id = "null";
      }
      else{
        f_id = response.data.fa_response.id;
        eml = response.data.email;
      }
      // console.log(f_id);
      // console.log(eml);
      dispatch({ type: GET_FAID, payload: { faId: f_id, email: eml, bank:response.data.fa_response } });
      dispatch({ type: SET_MESSAGE, payload: response.msg });
      dispatch({ type: LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      return Promise.reject(message);
    }
  );
};

export const getExpenseById = (id) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return ExpenseService.getExpenseById(id).then(
    (response) => {
      dispatch({
        type: GET_EXPENSE_BY_ID,
        payload: { expense: response.data.response },
      });
      dispatch({ type: SET_MESSAGE, payload: response.msg });
      dispatch({ type: LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      return Promise.reject(message);
    }
  );
};

export const rejectExpense = (docId, email, comment, status, name) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return ExpenseService.rejectExpense(docId, email, comment, status, name).then(
    (response) => {
      dispatch({ type: REJECT_EXPENSE });
      dispatch({ type: SET_MESSAGE, payload: response.msg });
      dispatch({ type: LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      return Promise.reject(message);
    }
  );
};

export const payExpense =
  (docId, fa_id, email, total_amount, status, mode, name) => (dispatch) => {
    dispatch({ type: LOADER, payload: true });
    return ExpenseService.payExpense(
      docId,
      fa_id,
      email,
      total_amount,
      status,
      mode,
      name
    ).then(
      (response) => {
        dispatch({ type: PAY_EXPENSE });
        dispatch({ type: SET_MESSAGE, payload: response.msg });
        dispatch({ type: LOADER, payload: false });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({ type: SET_MESSAGE, payload: message });
        return Promise.reject(message);
      }
    );
  };

export const getExpensesByUserId = (id, page, status) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return ExpenseService.getExpensesByUserId(id, page, status).then(
    (response) => {
      dispatch({
        type: GET_ALL_EXPENSES_BY_ID,
        payload: { expenses: response.data },
      });
      dispatch({ type: SET_MESSAGE, payload: response.msg });
      dispatch({ type: LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      return Promise.reject(message);
    }
  );
};

export const getExpensesByCamp = (camp_name, page, status) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return ExpenseService.getExpensesByCamp(camp_name, page, status).then(
    (response) => {
      dispatch({
        type: GET_ALL_EXPENSES_BY_CAMP,
        payload: { expenses: response.data, camp: camp_name },
      });
      dispatch({ type: SET_MESSAGE, payload: response.msg });
      dispatch({ type: LOADER, payload: false });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      return Promise.reject(message);
    }
  );
};


export const getExpensesByUserIdDownload = (id, start_date, end_date) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return ExpenseService.getExpensesByUserIdDownload(id, start_date, end_date).then(
    (response) => {
      dispatch({ type: SET_MESSAGE, payload: response.msg });
      dispatch({ type: LOADER, payload: false });
      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: SET_MESSAGE, payload: message });
      return Promise.reject(message);
    }
  );
};

export const updateExpense =
  (camp_name, fa_id, created_by, status, total_amount, bills, name, email, id) =>
  (dispatch) => {
    console.log(fa_id);
    dispatch({ type: LOADER, payload: true });
    return ExpenseService.updateExpense(
      camp_name,
      fa_id,
      created_by,
      status,
      total_amount,
      bills,
      name,
      email,
      id
    ).then(
      (response) => {
        dispatch({ type: UPDATE_EXPENSE });
        dispatch({ type: SET_MESSAGE, payload: response.msg });
        dispatch({ type: LOADER, payload: false });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({ type: SET_MESSAGE, payload: message });
        return Promise.reject(message);
      }
    );
  };