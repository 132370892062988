// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  gap: 1rem;
  padding: 1rem;
}
.errorPage .title {
  font-size: 40px;
  color: var(--primary-color);
  font-weight: bold;
}
.errorPage .svg {
  width: 40%;
  height: 40%;
}
`, "",{"version":3,"sources":["webpack://./src/modules/Error/Error.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,SAAS;EACT,aAAa;AACf;AACA;EACE,eAAe;EACf,2BAA2B;EAC3B,iBAAiB;AACnB;AACA;EACE,UAAU;EACV,WAAW;AACb","sourcesContent":[".errorPage {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  max-width: 100%;\n  max-height: 100%;\n  gap: 1rem;\n  padding: 1rem;\n}\n.errorPage .title {\n  font-size: 40px;\n  color: var(--primary-color);\n  font-weight: bold;\n}\n.errorPage .svg {\n  width: 40%;\n  height: 40%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
