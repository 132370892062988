import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { contactUs } from "../../actions/auth";
import SnackBar from "../../components/Alerts/SnackBar";
import Input from "../../components/Input/Input";
import logo from "../../assets/Logo.png";
import "./Contact.css";

const Contact = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const isLoadingStatus = useSelector((state) => state.loader.isLoadingStatus);

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState("");
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = () => {
    if (!subject || !message) {
      setMessages("Please fill all the fields!");
      setOpenError(true);
      return;
    }

    dispatch(contactUs(user.name, user.email, subject, message))
      .then((msg) => {
        setMessages(msg);
        setOpenSuccess(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      })
      .catch((err) => {
        setMessages(err);
        setOpenError(true);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  return (
    <div className="contactPage">
      <div className="contactCard">
        <div className="logo">
          <div>
            <img src={logo} alt="logo" height="130px" width="130px" />
          </div>
          {/* <div>CADET 360</div> */}
          <div  className="contactDetails" >CADET 360</div>
        </div>
        <div className="contactDetails">
          <div className="contact">Contact Us</div>
          <div className="tagLine">
            Exchange Participants Association India(EXPA)
            <br /> Bengaluru, Karnataka, India
          </div>
        </div>
        <div className="mailDetails">
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/fluency/48/secured-letter.png"
            alt="secured-letter"
          />
          <div><a href="https://info@cadetprogram.org" style={{color:"white"}}>info@cadetprogram.org</a></div>
        </div>
        <div className="socialMedia">
          <a href="https://www.instagram.com/cadet_expa_india/" target="_blank">
            <img
              width="40"
              height="40"
              src="https://img.icons8.com/fluency/48/instagram-new.png"
              alt="instagram-new"
            />
          </a>
          <a href="https://twitter.com/expacadetprogr1" target="_blank">
            <img
              width="40"
              height="40"
              src="https://img.icons8.com/color/48/twitter--v1.png"
              alt="twitter--v1"
            />
          </a>
          <a href="https://www.facebook.com/EXPACADETProgram/" target="_blank">
            <img
              width="40"
              height="40"
              src="https://img.icons8.com/color/48/facebook-new.png"
              alt="facebook-new"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/the-cadet-program"
            target="_blank"
          >
            <img
              width="40"
              height="40"
              src="https://img.icons8.com/fluency/48/linkedin.png"
              alt="linkedin"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCdXrGifpsSqV8nc7Ss15IcQ"
            target="_blank"
          >
            <img
              width="40"
              height="40"
              src="https://img.icons8.com/fluency/48/youtube-play.png"
              alt="youtube-play"
            />
          </a>
        </div>
      </div>
      <div className="contactInfoContainer">
        <div className="message">
          <div className="headLine">We're here to help!</div>
          <div>Whether you have a question, feedback,<br/> or just want to say hello, we'd love to hear from you</div>
        </div>
        <div className="formContainer">
          <div className="inputField">
            <Input type="text" field="Full Name" value={user.name} req={true} />
          </div>
          <div className="inputField">
            <Input type="email" field="Email" value={user.email} req={true} />
          </div>
          <div className="inputField">
            <Input
              type="subject"
              field="Subject"
              placeholder="Enter Subject"
              value={subject}
              onChange={handleSubjectChange}
              req={true}
            />
          </div>
          <div className="textField">
            <label className="textLabel">Message</label>
            <textarea
              className="textInput"
              rows="5"
              value={message}
              onChange={handleMessageChange}
            ></textarea>
          </div>
          <button
            className="btn"
            onClick={handleSubmit}
            //making it inactive whilst the saving skills is ongoing
            disabled={isLoadingStatus}
          >
            {isLoadingStatus ? (
              <>
                <CircularProgress
                  color="inherit"
                  size={12}
                  sx={{ marginRight: "4px" }}
                />
                Submitting
              </>
            ) : (
              <>Submit</>
            )}
          </button>
        </div>
      </div>
      <SnackBar
        message={messages}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Contact;
