import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DrawerComp from "./Drawer.jsx";
import { logout } from "../../actions/auth";
import {
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./Navbar.css";
import logo from "../../assets/Logo.png";

const Navbar = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  const [value, setValue] = useState("one");
  const theme = useTheme();
  const isMatchDown = useMediaQuery(theme.breakpoints.down("md"));
  //was looking for height breakpoint but this is for width as well...
  const customMatch = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  // for profile menu
  const [anchorProfile, setAnchorProfile] = useState(null);
  const openProfile = Boolean(anchorProfile);
  const handleProfileMenu = (event) => {
    setAnchorProfile(event.currentTarget);
  };
  const closeProfileMenu = () => {
    setAnchorProfile(null);
  };

  //for Admin menu
  const [anchorAdmin, setAnchorAdmin] = useState(null);
  const openAdmin = Boolean(anchorAdmin);
  const handleAdminMenu = (event) => {
    setAnchorAdmin(event.currentTarget);
  };
  const closeAdminMenu = () => {
    setAnchorAdmin(null);
  };

  //for job menu
  const [anchorJob, setAnchorJob] = useState(null);
  const openJob = Boolean(anchorJob);
  const handleJobMenu = (event) => {
    setAnchorJob(event.currentTarget);
  };
  const closeJobMenu = () => {
    setAnchorJob(null);
  };

  //for Expense menu
  const [anchorExpense, setAnchorExpense] = useState(null);
  const openExpense = Boolean(anchorExpense);
  const handleExpenseMenu = (event) => {
    setAnchorExpense(event.currentTarget);
  };
  const closeExpenseMenu = () => {
    setAnchorExpense(null);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const handleSignIn = () => {
    navigate("/login");
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div className="cadet-navbar" style={{ height: "9vh", minHeight: "64px" }}>
      <AppBar
        position="fixed"
        sx={{
          fontFamily: "DM Sans",
          background: "#ffffff",
          color: "#000000",
          boxShadow: "2px 2px 4px rgba(45, 48, 146, 0.25)",
          height: "inherit",
          minHeight: "64px",
        }}
      >
        <Toolbar sx={{ height: "inherit" }}>
          {isMatchDown || customMatch ? (
            <>
              <Link to="/">
                <img
                  src={logo}
                  alt="logo"
                  height="50px"
                  width="55px"
                />
              </Link>
              <Typography
                sx={{ fontSize: "1.5rem", cursor: "pointer" }}
                onClick={() => navigate("/")}
              >
                CADET 360
              </Typography>
              <DrawerComp user={user} />
            </>
          ) : (
            <>
              <Link to="/">
                <img
                  src={logo}
                  alt="logo"
                  height="50px"
                  width="55px"
                />
              </Link>
              <Typography
                sx={{ fontSize: "1.5rem", cursor: "pointer" }}
                onClick={() => navigate("/")}
              >
                CADET 360
              </Typography>
              <Tabs
                value={value}
                onChange={(e, value) => setValue(value)}
                indicatorColor=""
                textColor=""
                sx={{ marginLeft: "auto" }}
              >
                
                {user.isLoggedIn &&
                  (user.user.role === "Admin" ||
                    user.user.role === "S_ADMIN") && (
                    <Tab
                      value="three"
                      id="admin-button"
                      label="Admin"
                      aria-controls={openAdmin ? "admin-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openAdmin ? "true" : undefined}
                      onClick={handleAdminMenu}
                      sx={{
                        textTransform: "none",
                        fontSize: "1rem",
                        color:
                          location.pathname === "/hrrequests" ||
                          location.pathname === "/usermanagement" ||
                          location.pathname === "/expenseadminrequests" ||
                          location.pathname === "/expenseadmincamp"
                            ? "var(--primary-color)"
                            : "var(--dark-text-color)",
                      }}
                    />
                  )}
                <Menu
                  id="admin-menu"
                  anchorEl={anchorAdmin}
                  open={openAdmin}
                  onClose={closeAdminMenu}
                  MenuListProps={{
                    "aria-labelledby": "admin-button",
                  }}
                >
                  {user.isLoggedIn &&
                    (user.user.role === "Admin" ||
                      user.user.role === "S_ADMIN") && (
                      <Link className="linkMod" to="/adminjobs">
                        <MenuItem onClick={closeAdminMenu}>All Jobs</MenuItem>
                      </Link>
                    )}
                  {user.isLoggedIn &&
                    (user.user.role === "Admin" ||
                      user.user.role === "S_ADMIN") && (
                      <Link className="linkMod" to="/hrrequests">
                        <MenuItem onClick={closeAdminMenu}>
                          HR Requests
                        </MenuItem>
                      </Link>
                    )}
                  {user.isLoggedIn &&
                    (user.user.role === "Admin" ||
                      user.user.role === "S_ADMIN") && (
                      <Link className="linkMod" to="/usermanagement">
                        <MenuItem onClick={closeAdminMenu}>
                          User Management
                        </MenuItem>
                      </Link>
                    )}
                  {user.isLoggedIn &&
                    (user.user.role === "Admin" ||
                      user.user.role === "S_ADMIN") && (
                      <Link className="linkMod" to="/expenseadminrequests">
                        <MenuItem onClick={closeAdminMenu}>
                          All Expense Requests
                        </MenuItem>
                      </Link>
                    )}
                  {user.isLoggedIn &&
                    (user.user.role === "Admin" ||
                      user.user.role === "S_ADMIN") && (
                      <Link className="linkMod" to="/expenseadmincamp">
                        <MenuItem onClick={closeAdminMenu}>
                          Campwise Expenses
                        </MenuItem>
                      </Link>
                    )}
                </Menu>
                {user.isLoggedIn &&
                  (user.user.role === "Admin" ||
                    user.user.role === "S_ADMIN" ||
                    user.user.role === "Trainer") && (
                    <Tab
                      value="four"
                      id="expense-button"
                      label="My Expenses"
                      onClick={() => navigate("/expense")}
                      sx={{
                        textTransform: "none",
                        fontSize: "1rem",
                        color:
                          location.pathname === "/expense"
                            ? "var(--primary-color)"
                            : "var(--dark-text-color)",
                      }}
                    />
                  )}

                <Tab
                  value="five"
                  id="job-button"
                  label="Jobs"
                  aria-controls={openJob ? "job-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openJob ? "true" : undefined}
                  onClick={handleJobMenu}
                  sx={{
                    textTransform: "none",
                    fontSize: "1rem",
                    color:
                      location.pathname === "/job" ||
                      location.pathname === "/applied" ||
                      location.pathname === "/managejobhr" ||
                      location.pathname === "/createjob"
                        ? "var(--primary-color)"
                        : "var(--dark-text-color)",
                  }}
                />
                <Menu
                  id="job-menu"
                  anchorEl={anchorJob}
                  open={openJob}
                  onClose={closeJobMenu}
                  MenuListProps={{
                    "aria-labelledby": "job-button",
                  }}
                >
                  <Link className="linkMod" to="/job">
                    <MenuItem onClick={closeJobMenu}>Active Jobs</MenuItem>
                  </Link>
                  {user.isLoggedIn &&
                    (user.user.role === "Cadet" ||
                      user.user.role === "Trainer") && (
                      <Link className="linkMod" to="/applied">
                        <MenuItem onClick={closeJobMenu}>
                          My Applied Jobs
                        </MenuItem>
                      </Link>
                    )}
                  {user.isLoggedIn && user.user.role === "HR" && (
                    <Link className="linkMod" to="/managejobhr">
                      <MenuItem onClick={closeJobMenu}>Manage My Jobs</MenuItem>
                    </Link>
                  )}
                  {user.isLoggedIn && user.user.role === "HR" && (
                    <Link className="linkMod" to="/createjob">
                      <MenuItem onClick={closeJobMenu}>Post a Job</MenuItem>
                    </Link>
                  )}
                </Menu>
              
              <Tab
                  value="one"
                  label="About Us"
                  onClick={() => navigate("/")}
                  sx={{
                    textTransform: "none",
                    fontSize: "1rem",
                    color:
                      location.pathname === "/"
                        ? "var(--primary-color)"
                        : "var(--dark-text-color)",
                  }}
                />
                  <Tab
                  value="two"
                  label="Contact Us"
                  onClick={() => navigate("/contact")}
                  sx={{
                    textTransform: "none",
                    fontSize: "1rem",
                    color:
                      location.pathname === "/contact"
                        ? "var(--primary-color)"
                        : "var(--dark-text-color)",
                  }}
                />
                </Tabs>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {user.isLoggedIn ? (
                  <div>
                    <Button
                      id="profile-button"
                      variant="contained"
                      aria-controls={openProfile ? "profile-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openProfile ? "true" : undefined}
                      onClick={handleProfileMenu}
                      sx={{ backgroundColor: "var(--primary-color)" }}
                    >
                      <AccountCircleIcon sx={{ padding: "0 0.3rem 0 0" }} />
                      {"My Account"}
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorProfile}
                      open={openProfile}
                      onClose={closeProfileMenu}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <Link className="linkMod" to={`/profile/${user.user.id}`}>
                        <MenuItem onClick={closeProfileMenu}>Profile</MenuItem>
                      </Link>
                      {user.isLoggedIn && user.user.role === "Guest" && (
                        <Link className="linkMod" to={`/requestpage`}>
                          <MenuItem onClick={closeProfileMenu}>
                            Request Access
                          </MenuItem>
                        </Link>
                      )}
                      <Link className="linkMod" to={`/reset`}>
                        <MenuItem onClick={closeProfileMenu}>
                          Reset Password
                        </MenuItem>
                      </Link>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                  </div>
                ) : (
                  <button className="loginNav" onClick={handleSignIn}>
                    Login
                  </button>
                )}
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
