import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import WorkIcon from "@mui/icons-material/Work";
import { Chip } from "@mui/material";
import "./JobCard.css";

const JobCard = ({ job, isApplied, isActiveJobPage = false }) => {
  dayjs.extend(relativeTime);

  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const jobId = job.id;
  const {
    job_title,
    created_date,
    company_name,
    short_job_description,
    location,
    job_type,
    skills,
    created_by,
    job_status,
  } = job.data;

  const nav =
    (user.role === "HR" && user.id === created_by) ||
    user.role === "Admin" ||
    user.role === "S_ADMIN"
      ? `/jobdetailhr/${jobId}`
      : `/job/${jobId}/${isApplied?.data?.applied_date ? "true" : "false"}`;
  const date = dayjs().to(created_date);

  return (
    <div className="jobCard" onClick={() => navigate(nav)}>
      <div className="header">
        <div className="jobTitle">
          <div className="title">{job_title}</div>
          <div className="company">{company_name}</div>
        </div>
        <div className="date">{date}</div>
      </div>
      <div className="description">{short_job_description}</div>
      <div className="footer">
        <div className="tags">
          <Chip
            size="small"
            color="success"
            icon={<FmdGoodIcon />}
            label={location}
            variant="outlined"
          />
          <Chip
            size="small"
            color="warning"
            icon={
              <WorkIcon
                fontSize="small"
                sx={{ paddingLeft: "2px", fontSize: "20px" }}
              />
            }
            label={job_type}
            variant="outlined"
          />
          <div className="skills">
            {skills &&
              skills.map((skill, index) => (
                <Chip
                  size="small"
                  color="info"
                  label={skill}
                  variant="outlined"
                  key={index}
                />
              ))}
          </div>
        </div>
        <div className="details">
          {user.role !== "S_ADMIN" &&
            user.role !== "Admin" &&
            user.role !== "HR" &&
            isApplied?.data?.applied_date && (
              <span
                style={{
                  fontSize: "14px",
                  color: isApplied ? "#008e0e" : "#ba1b1b",
                  fontWeight: 500,
                }}
              >
                Applied on :{" "}
                {new Date(isApplied.data.applied_date).toLocaleDateString()}
              </span>
            )}
          {(user.id === created_by ||
            user.role === "Admin" ||
            user.role === "S_ADMIN") &&
            job_status &&
            !isActiveJobPage && (
              <span
                style={{
                  fontSize: "14px",
                  color: job_status === "Active" ? "#008e0e" : "#ba1b1b",
                  fontWeight: 500,
                }}
              >
                {job_status.toUpperCase()}
              </span>
            )}
        </div>
      </div>
    </div>
  );
};

export default JobCard;
