// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.managejobhr {
    min-height: 100vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .managejobhr .pageHeading {
    position: fixed;
    z-index: 999;
    top: 4rem;
    width: 100%;
    background-color: var(--primary-color);
    color: var(--light-text-color);
    font-size: 20px;
    padding: 1rem 0 1rem 3.5em;
  }
  
  .managejobhr .container {
    width: 70%;
  }
  
  .managejobhr .jobs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .managejobhr .container .mainBtn {
    background-color: var(--primary-color);
    color: var(--light-text-color);
    border: 1.5px solid var(--primary-color);
  }
  .managejobhr .container .com {
    padding: 8px 20px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 16px;
  }`, "",{"version":3,"sources":["webpack://./src/modules/Jobs/ManageJobHR/ManageJobHR.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,SAAS;IACT,WAAW;IACX,sCAAsC;IACtC,8BAA8B;IAC9B,eAAe;IACf,0BAA0B;EAC5B;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;EACA;IACE,sCAAsC;IACtC,8BAA8B;IAC9B,wCAAwC;EAC1C;EACA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,mBAAmB;EACrB","sourcesContent":[".managejobhr {\n    min-height: 100vh;\n    padding: 2rem;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 1rem;\n  }\n  \n  .managejobhr .pageHeading {\n    position: fixed;\n    z-index: 999;\n    top: 4rem;\n    width: 100%;\n    background-color: var(--primary-color);\n    color: var(--light-text-color);\n    font-size: 20px;\n    padding: 1rem 0 1rem 3.5em;\n  }\n  \n  .managejobhr .container {\n    width: 70%;\n  }\n  \n  .managejobhr .jobs {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n  }\n  .managejobhr .container .mainBtn {\n    background-color: var(--primary-color);\n    color: var(--light-text-color);\n    border: 1.5px solid var(--primary-color);\n  }\n  .managejobhr .container .com {\n    padding: 8px 20px;\n    border-radius: 5px;\n    font-weight: 500;\n    cursor: pointer;\n    margin-bottom: 16px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
