import React, { useEffect, useState, useRef } from "react";
import "./Home.css";
import logo from "../../assets/Logo.png";
import { initializeApp } from 'firebase/app';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
// import DashboardVideo from 'https://firebasestorage.googleapis.com/v0/b/cadet-tech.appspot.com/o/assets%2FDashboardVideo.mp4?alt=media&token=b348ab2b-4f40-4615-b24b-d268a5d0f436&_gl=1*2al5en*_ga*NzYyNzI3MTguMTY5NjQxMDU1NQ..*_ga_CW55HF8NVT*MTY5NzQ1MzM1NC45LjEuMTY5NzQ1MzM2OC40Ni4wLjA.';



const firebaseConfig = {
  apiKey: "AIzaSyCYceS47-9vRKtk6zfiwFdhYb1jQhfaDAw",
  authDomain: "cadet-tech.firebaseapp.com",
  projectId: "cadet-tech",
  storageBucket: "cadet-tech.appspot.com",
  messagingSenderId: "37972231419",
  appId: "1:37972231419:web:06eb7b88ba4e8483845c5e",
  measurementId: "G-F04EMHTWQ0"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);


const Home = () => {
  const [photoUrls, setPhotoUrls] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const videoElement = useRef(null);

  useEffect(() => {
    const photosFolderRef = ref(storage, 'banner');
    listAll(photosFolderRef).then(async (res) => {
      const urls = [];
      for (const itemRef of res.items) {
        const url = await getDownloadURL(itemRef);
        urls.push(url);
      }
      setPhotoUrls(urls);
    });

    const videoRef = ref(storage, 'https://firebasestorage.googleapis.com/v0/b/cadet-tech.appspot.com/o/assets%2FDashboardVideo.mp4?alt=media&token=b348ab2b-4f40-4615-b24b-d268a5d0f436&_gl=1*1ekk1ql*_ga*NzYyNzI3MTguMTY5NjQxMDU1NQ..*_ga_CW55HF8NVT*MTY5NzQ0MDU4MC44LjEuMTY5NzQ0MTExMC42MC4wLjA.');
    getDownloadURL(videoRef).then((url) => {
      console.log(url)
      setVideoUrl(url);
    });

  }, [photoUrls, videoUrl]);

  // Mute the video and play it
  useEffect(() => {
    if (videoElement.current) {
      videoElement.current.muted = true;
      videoElement.current.loop = true;
      videoElement.current.autoplay = true;
      videoElement.current.play();
    }
  }, [videoElement]);

  return (
    <div className="home-page">
      <div className="top-panel">
        <div className="left-panel">
          <div className="left-pan">
            <div className="text1">
              <span> Step into the future </span>
            </div>
            <div className="text2">
              <span>
                {" "}
                Discover Exciting Career Paths For EXPA CADETS Program Attendees
              </span>
            </div>
          </div>
        </div>
        <div className="right-panel">
          <div className="logo-container">
            {/* <div>
              <video src={videoUrl} ref={videoElement} width="500" height="300" controls></video>
            </div> */}
          </div>
        </div>
      </div>
      <div className="bottom-panel">
        <div className="bottom-container">
          <span className="text2">The CADET Program:</span>
          <span className="text3"> A Yearning To Give Back</span>
          <div className="text4">
          In 2016, ex-NCC YEP cadets (or EXPAs) came together to start an organized effort to give back to the community that they gained so much from - the NCC. This led to a discussion with the then DDG of Karnataka and Goa NCC Directorate which inturn led to the birth of The CADET 360
          </div>
        </div>
        <div className="carousel-container">
          <div className="home-logos">
            <div className="logos-slide">
              {photoUrls.map((url, index) => (
                  <img key={index} src={url} alt={`Photo ${index}`} />
              ))}
            </div>
            
          </div>
        </div>
      </div>
      {/*<div className="main-footer">
        </div> */}
    </div>
  );
};

export default Home;
