import React from "react";
import { ReactComponent as ForbiddenSVG } from "../../assets/403.svg";
import "./Error.css";
const Forbidden = () => {
  return (
    <div className="errorPage">
      <div className="title">You are NOT Authorized</div>
      <div className="svg">
        <ForbiddenSVG />
      </div>
    </div>
  );
};

export default Forbidden;
