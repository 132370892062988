import React, { useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";

import logo from "../../assets/Logo.png";
import Input from "../../components/Input/Input";
import "./Register.css";
import { register, googleLogin } from "../../actions/auth";
import { CircularProgress } from "@mui/material";
import SnackBar from "../../components/Alerts/SnackBar";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const isLoadingStatus = useSelector((state) => state.loader.isLoadingStatus);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const handleRedirect = () => {
    setTimeout(() => {
      navigate("/login");
    }, 1500);
  };

  const handleLoginSuccess = async (response) => {
    if (response !== null) {
      dispatch(googleLogin(response))
        .then((message) => {
          setMessage(message);
          setOpenSuccess(true);
          setTimeout(() => {
            navigate("/");
          }, 1500);
        })
        .catch((message) => {
          setMessage(message);
          setOpenError(true);
        });
    } else {
      setMessage("User could not be authenticated!");
      setOpenError(true);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (
      name !== "" &&
      email !== "" &&
      password !== "" &&
      confirmPassword !== ""
    ) {
      dispatch(register(name, email, password, confirmPassword))
        .then((message) => {
          setMessage(message);
          setOpenSuccess(true);
          handleRedirect();
        })
        .catch((message) => {
          setMessage(message);
          setOpenError(true);
        });
    } else {
      setMessage("Please fill all the fields");
      setOpenError(true);
    }
  };

  return (
    <>
      <div className="registerPage">
        <div className="left">
          <div className="logo-container">
            <div>
              <img src={logo} alt="logo" height="25%" width="25%" />
            </div>
            <div>
              <span>CADET 360</span>
            </div>
          </div>

          <div className="mid">
            <span>Step into the Future:</span>
          </div>

          <div className="lower">
            <span>
              Discover Exciting <br /> Career Paths
            </span>
          </div>

          <div className="end">
            <span>
              Exclusively for EXPA CADET <br /> Program Attendees.
            </span>
          </div>
        </div>
        <div className="right">
          <div className="registerContainer">
            <div className="register">Register</div>
            <div className="redirect">
              <p>
                Already have an account?{" "}
                <Link to="/login" style={{ textDecoration: "none" }}>
                  Sign In
                </Link>
              </p>
            </div>
            <div className="formContainer">
              <Input
                type="text"
                field="Name"
                placeholder="Enter Name"
                value={name}
                onChange={handleNameChange}
                req={true}
              />
              <Input
                type="email"
                field="Email"
                placeholder="Enter Email"
                value={email}
                onChange={handleEmailChange}
                req={true}
              />
              <Input
                type="password"
                field="Password"
                placeholder="Enter Password"
                value={password}
                onChange={handlePasswordChange}
                req={true}
              />
              <Input
                type="password"
                field="Confirm Password"
                placeholder="Re-enter Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                req={true}
              />
              <button
                className={`com ${isLoadingStatus ? "loading" : "button"}`}
                onClick={handleRegister}
              >
                {isLoadingStatus ? (
                  <>
                    <CircularProgress
                      color="inherit"
                      size={18}
                      sx={{ marginRight: "4px" }}
                    />
                    Registering...
                  </>
                ) : (
                  <>Register</>
                )}
              </button>
            </div>
            <div className="divider">
              <div style={{ width: "100%" }}>
                <hr />
              </div>
              <div
                style={{
                  color: "rgba(0, 0, 0, 0.5)",
                  fontWeight: "500",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  fontSize: "0.7rem",
                }}
              >
                <p>OR</p>
              </div>
              <div style={{ width: "100%" }}>
                <hr />
              </div>
            </div>
            <div className="redirect">
              <p>Want to be an HR ? Use your organization email</p>
            </div>
            <div className="social">
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  const data = jwtDecode(credentialResponse.credential);
                  handleLoginSuccess(data);
                }}
                onError={() => {
                  setMessage("Login Failed");
                  setOpenError(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
    </>
  );
};

export default Register;
