import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Mail from "@mui/icons-material/MailOutline";
import Location from "@mui/icons-material/LocationOnOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import Person from "@mui/icons-material/PersonOutlined";
import { Chip, Tooltip, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import NotifOn from "@mui/icons-material/NotificationsActive";
import NotifOff from "@mui/icons-material/NotificationsOff";
import { getNotify, setNotify } from "../../actions/profile.action";
import SnackBar from "../Alerts/SnackBar";
import "./Cards.css";
import logo from "../../assets/Logo.png";

const PersonalCard = ({ profile }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  // const isLoading = useSelector((state) => state.loader.isLoading);
  // const user = useSelector((state) => state.auth.user);
  const isLoadingStatus = useSelector((state) => state.loader.isLoadingStatus);
  const notification = useSelector((state) => state.profile.notification);

  //for Snackbar
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  useEffect(() => {
    if (profile.user.role === "HR") {
      dispatch(getNotify(id))
        .then(() => {
          // setOpenSuccess(true);
          // setMessage("Email Notification Fetched")
        })
        .catch((err) => {
          setOpenError(true);
          setMessage(err);
        });
    } else {
    }
  }, []);

  //setting avatar according to roles
  const avatarRole = `avatar ${profile.user.role}-color`;
  //setting inittials in the avatar according to username
  const initials =
    Object.keys(profile.user).length > 0
      ? profile.user.name
          .split(" ")
          .map((n) => n[0])
          .join(".")
      : "";

  const toggleNotify = () => {
    dispatch(setNotify(id, !notification))
      .then((message) => {
        setOpenSuccess(true);
        setMessage(message);
        console.log(message);
      })
      .catch((message) => {
        setOpenError(true);
        setMessage(message);
      });
    //need to update notification in the redux state after update
    dispatch(getNotify(id))
      .then(() => {})
      .catch((err) => {
        setOpenError(true);
        setMessage(err);
      });
  };
  return (
    <div className="personalCard">
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
      {profile.user && (
        <>
          {profile.user.role === "HR" && (
            <div className="notif" onClick={toggleNotify}>
              {isLoadingStatus ? (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <>
                  {notification ? (
                    <Tooltip title="Email Notification is ON">
                      <NotifOn fontSize="small" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Email Notification is OFF">
                      <NotifOff fontSize="small" />
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          )}
          <div className="profile_avtar">
            <div className={avatarRole}>
              <Typography variant="h5" component="h5">
                {profile.user.role === "S_ADMIN" ? (
                  <img src={logo} alt="Admin Logo" style={{ width: "160px" }} />
                ) : (
                  initials
                )}
              </Typography>
            </div>
            <Tooltip title={profile.user.name} placement="bottom-start">
              <div className="name">{profile.user.name}</div>
            </Tooltip>
          </div>
          <div className="personalInfo">
            {profile.user.role && (
              <div className="alignContent">
                <Person fontSize="small" />
                {profile.user.role}
              </div>
            )}
            {profile.user.email && (
              <div className="alignContent">
                <Mail fontSize="small" />
                {profile.user.email}
              </div>
            )}
            {profile.user.city && (
              <div className="alignContent">
                <Location fontSize="small" />
                {profile.user.city}
              </div>
            )}
            {profile.user.phone && (
              <div className="alignContent">
                <PhoneIcon fontSize="small" />
                {profile.user.phone}
              </div>
            )}
          </div>
          {profile.user.role !== "S_ADMIN" &&
            profile.user.role !== "Admin" &&
            profile.user.role !== "HR" &&
            profile.user.skills && (
              <div className="skillsContainer">
                <div className="title">Skills</div>
                <div className="skills">
                  {profile.user.skills !== undefined &&
                    profile.user.skills.map((skill) => (
                      <Chip
                        size="small"
                        color="info"
                        label={skill}
                        variant="outlined"
                      />
                    ))}
                </div>
              </div>
            )}
          {profile.user.role !== "S_ADMIN" &&
            profile.user.role !== "Admin" &&
            profile.user.role !== "HR" &&
            profile.user.additionalSkills && (
              <div className="skillsContainer">
                <div className="title">Additional Skills</div>
                <div className="skills">
                  {profile.user.additionalSkills !== undefined &&
                    profile.user.additionalSkills.map((skill) => (
                      <Chip
                        size="small"
                        color="info"
                        label={skill}
                        variant="outlined"
                      />
                    ))}
                </div>
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default PersonalCard;
