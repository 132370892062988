import firebase from 'firebase/compat/app'
import { initializeApp } from "firebase/app";


import 'firebase/compat/storage'

const firebaseConfig = {
  apiKey: "AIzaSyCYceS47-9vRKtk6zfiwFdhYb1jQhfaDAw",
  authDomain:"cadet-tech.firebaseapp.com",
  projectId:  "cadet-tech",
  storageBucket: "cadet-tech.appspot.com",
  messagingSenderId:  "37972231419",
  appId:  "1:37972231419:web:06eb7b88ba4e8483845c5e",
  measurementId: "G-F04EMHTWQ0"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const storage = firebase.storage()


export default firebase;