import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router";
import { getHrRequests } from "../../../actions/profile.action";
import "./HrRequests.css";
import LinearProgress from "@mui/material/LinearProgress";
import SnackBar from "../../../components/Alerts/SnackBar";

const HrRequests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("Role action done");
  const requests = useSelector((state) => state.profile.requests);
  const isLoading = useSelector((state) => state.loader.isLoading);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const handleRequests = () => {
      if (user?.role === "Admin" || user?.role === "S_ADMIN") {
        dispatch(getHrRequests()).catch((message) => {
          setMessage(message);
          setOpenError(true);
        });
      }
    };
    handleRequests();
  }, [dispatch]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  //defining columns and its properties
  const columns = [
    { field: "name", headerName: "Name", minWidth: 220 },
    { field: "role", headerName: "Current Role", minWidth: 220 },
    { field: "status", headerName: "Status",
    renderCell: (params) => (
        <div
          style={{
            color:
              params.value === "Approved"
                ? "#008e0e"
                : params.value === "Rejected"
                ? "#ba1b1b"
                : params.value === "Pending"
                ? "var(--primary-color)"
                : "#ffa500",
            fontWeight: 500,
          }}
        >
          {params.value}
        </div>
      ),
      minWidth: 220 },
    { field: "company", headerName: "Company", minWidth: 220 },
    { field: "phone", headerName: "Phone" },
  ];

  //creating rows from the test data

  const rows = requests.map((row) => ({
    name: row.data.name,
    id: row.id,
    status: row.data.request_hr_status,
    role: row.data.role,
    company: row.data.company_title,
    phone: row.data.phone,
  }));

  const handleRowClick = (params) => {
    navigate(`/hrrequestdetails/${params.row.id}`);
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <div className="hrRequests">
        <div className="tablecontainer">
          <DataGrid
            sx={{ cursor: "pointer", paddingLeft: "1rem" }}
            onRowClick={handleRowClick}
            rows={rows}
            columns={columns}
            //defining initial state of the table, which options are required at startup
            initialState={{
              pagination: {
                paginationModel: {
                  page: 0,
                  pageSize: 10,
                },
              },
            }}
            // checkboxSelection
            //enabling toolbar, can also replace default GridToolbar with custom
            slots={{ toolbar: GridToolbar }}
            //configuring toolbar
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </div>
      </div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
    </>
  );
};

export default HrRequests;
