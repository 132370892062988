import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./MyExpense.css";
import ExpenseCard from "../../../components/ExpenseComponent/ExpenseCard";
import EmptyCard from "../../../components/Cards/EmptyCard";
import { useNavigate } from "react-router-dom";
import Faid from "../FA_ID/Faid";
import { useDispatch, useSelector } from "react-redux";
import {
  getExpensesByUserId,
  getFaId,
  getExpensesByUserIdDownload,
} from "../../../actions/expense.action";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Pagination, Stack, Skeleton } from "@mui/material";
import SnackBar from "../../../components/Alerts/SnackBar";
import exportFromJSON from "export-from-json";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const MyExpense = (props) => {
  dayjs.extend(relativeTime);
  const [status, setStatus] = React.useState("All");
  const navigate = useNavigate();
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleClosesnacbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
    setPage(1);
    if (props.userid === undefined) {
      dispatch(getFaId(user_id)).catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
      dispatch(getExpensesByUserId(user_id, 1, event.target.value)).catch(
        (err) => {
          setMessage(err);
          setOpenError(true);
        }
      );
      // console.log("my");
    } else {
      // console.log("user");
      dispatch(getExpensesByUserId(props.userid, 1, event.target.value)).catch(
        (err) => {
          setMessage(err);
          setOpenError(true);
        }
      );
    }
  };

  const [page, setPage] = React.useState(1);

  const handlePageChange = (event) => {
    setPage(event.target.innerText);
    if (props.userid === undefined) {
      dispatch(getFaId(user_id));
      dispatch(
        getExpensesByUserId(user_id, event.target.innerText, status)
      ).catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
      // console.log("my");
    } else {
      // console.log("user");
      dispatch(
        getExpensesByUserId(props.userid, event.target.innerText, status)
      ).catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
    }
  };

  const [contactid, setContactid] = React.useState(false);
  const handleOpen = () => setContactid(true);
  const handleClose = () => setContactid(false);

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader.isLoading);
  const user_id = useSelector((state) => state.auth.user.id);
  const fa_Id = useSelector((state) => state.expense.faId);
  const myexpenses = useSelector((state) => state.expense.expenses);

  useEffect(() => {
    if (props.userid === undefined) {
      dispatch(getFaId(user_id)).catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
      dispatch(getExpensesByUserId(user_id, page, status)).catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
      // console.log("my");
    } else {
      // console.log("user");
      dispatch(getExpensesByUserId(props.userid, page, status)).catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
    }
  }, [page]);
  const handleCreate = () => {
    if (fa_Id !== "null") {
      navigate("/expense/create");
    } else {
      setContactid(true);
    }
  };

  const [check, setCheck] = useState(false);
  const [start_date, setStart_date] = useState(dayjs(Date.now()));
  const [end_date, setEnd_Date] = useState(dayjs(Date.now()));

  const handleOpenedit = () => setCheck(true);
  const handleCloseedit = () => setCheck(false);

  const handleStartDateChange = (event) => {
    setStart_date(event);
  };
  const handleEndDateChange = (event) => {
    setEnd_Date(event);
  };

  const handleDownload = () => {
    if (props.userid === undefined) {
      dispatch(getExpensesByUserIdDownload(user_id, start_date, end_date))
        .then((response) => {
          // console.log(response.data);
          const data = response.data;
          const fileName = `Past Expenses of ${response.data[0].name}`;
          const exportType = exportFromJSON.types.csv;
          exportFromJSON({ data, fileName, exportType });
        })
        .catch((err) => {
          setMessage(err);
          setOpenError(true);
        });
    } else {
      dispatch(getExpensesByUserIdDownload(props.userid, start_date, end_date))
        .then((response) => {
          // console.log(response.data);
          const data = response.data;
          const fileName = `Past Expenses of ${response.data[0].name}`;
          const exportType = exportFromJSON.types.csv;
          exportFromJSON({ data, fileName, exportType });
        })
        .catch((err) => {
          setMessage(err);
          setOpenError(true);
        });
    }
    handleCloseedit();
  };

  return (
    <div className="myExpense">
      {props.userid === undefined && (
        <div className="pageHeading">
          <div>My Expenses</div>
          <button className="com cancel" onClick={handleOpen}>
            Link Bank Account
          </button>
        </div>
      )}
      <div
        className="container"
        style={
          !props.userid
            ? {
                //  marginTop: "4rem"
              }
            : {}
        }
      >
        {!isLoading && <div className="status">Expense status</div>}
        {!isLoading && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <FormControl
              // size="small"
              sx={{ width: 180 }}
            >
              <Select
                // size="small"
                value={status}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: 40,
                  backgroundColor: "white",
                  color: "var(--dull-text-color)",
                }}
              >
                <MenuItem value="" disabled>
                  Select status
                </MenuItem>
                <MenuItem value="All" style={{ color: "black" }}>
                  All Expenses
                </MenuItem>
                <MenuItem value="Reimbursed" style={{ color: "#008e0e" }}>
                  Reimbursed
                </MenuItem>
                <MenuItem value="Approved" style={{ color: "blue" }}>
                  Approved
                </MenuItem>
                <MenuItem value="Revision" style={{ color: "#ba1b1b" }}>
                  Revision
                </MenuItem>
                <MenuItem value="Pending" style={{ color: "#f29339" }}>
                  Pending
                </MenuItem>
                <MenuItem value="Rejected" style={{ color: "#ba1b1b" }}>
                  Rejected
                </MenuItem>
              </Select>
            </FormControl>
            {props.userid && (
              <button className="com mainBtn" onClick={handleOpenedit}>
                Download All Expenses
              </button>
            )}
          </div>
        )}
        <div className="expenses">
          {!isLoading && myexpenses.expenses && !myexpenses.expenses.length && (
            // <h1>No Expenses</h1>
            <EmptyCard message="No Expenses Available" />
          )}
          {!isLoading ? (
            myexpenses.expenses &&
            myexpenses.expenses.map(
              (expense) =>
                (status === "All" || expense.data.status === status) && (
                  <ExpenseCard
                    id={expense.id}
                    camp={expense.data.camp_name}
                    date={dayjs().to(expense.data.created_at)}
                    comments={
                      expense.data.comment !== undefined
                        ? expense.data.comment
                        : ""
                    }
                    amount={expense.data.total_amount}
                    status={expense.data.status}
                  />
                )
            )
          ) : (
            <Stack spacing={2}>
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={50}
                width={200}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
            </Stack>
          )}
          {myexpenses.expenses && myexpenses.expenses.length > 0 && (
            <Stack spacing={2}>
              <Pagination
                count={myexpenses.totalPages}
                defaultValue={page}
                onChange={handlePageChange}
              />
            </Stack>
          )}
        </div>
      </div>
      {props.userid === undefined && (
        <div className="createExpensenew" onClick={handleCreate}>
          Add Expense
        </div>
      )}
      <Faid check={contactid} handleClose={handleClose} />
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClosesnacbar}
      />
      <Modal
        open={check}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="Modalcontaineredit">
          <div className="createFA">
            <div className="pageHeadingModal">
              <div>Select Date Range</div>
              <div onClick={handleCloseedit} className="closebtn">
                <CloseIcon />
              </div>
            </div>
            <div className="personalbank">
              <div className="personalbankContainer">
                <div className="formContainer">
                  <div className="inputField">
                    <span>Start Date</span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={start_date}
                        maxDate={dayjs(Date.now())}
                        onChange={handleStartDateChange}
                        sx={{ width: 200 }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="inputField">
                    <span>End Date</span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={end_date}
                        maxDate={dayjs(Date.now())}
                        onChange={handleEndDateChange}
                        sx={{ width: 200 }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="buttonsedit">
                    <button
                      className="comedit createedit"
                      onClick={handleDownload}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MyExpense;
