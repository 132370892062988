// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgotPage {
  min-height: 100vh;
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgotPage .forgotContainer {
  background-color: white;
  width: 20vw;
  padding: 30px 10vw;
  border: 1px solid var(--dark-text-color);
  border-radius: 5px;
}

.forgotPage .title {
  font-weight: 500;
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: var(--dark-text-color);
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
}

.forgotPage .forgot {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
}

.forgotPage .redirect {
  font-weight: 400;
  font-size: 0.8rem;
  color: var(--dark-text-color);
  display: flex;
  justify-content: flex-start;
}

.forgotPage .formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 5px 0;
}

.forgotPage .button {
  display: flex;
  justify-content: center;
  width: 100%;
}

.forgotPage .inputField {
  display: flex;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/modules/Forgot/Forgot.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,yCAAyC;EACzC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,kBAAkB;EAClB,wCAAwC;EACxC,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,6BAA6B;EAC7B,6BAA6B;EAC7B,WAAW;EACX,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,6BAA6B;EAC7B,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;AACb","sourcesContent":[".forgotPage {\n  min-height: 100vh;\n  background-color: var(--background-color);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.forgotPage .forgotContainer {\n  background-color: white;\n  width: 20vw;\n  padding: 30px 10vw;\n  border: 1px solid var(--dark-text-color);\n  border-radius: 5px;\n}\n\n.forgotPage .title {\n  font-weight: 500;\n  font-size: 2rem;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-evenly;\n  color: var(--dark-text-color);\n  width: 100%;\n  margin-bottom: 10px;\n  cursor: pointer;\n}\n\n.forgotPage .forgot {\n  font-size: 1.5rem;\n  font-weight: 400;\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n.forgotPage .redirect {\n  font-weight: 400;\n  font-size: 0.8rem;\n  color: var(--dark-text-color);\n  display: flex;\n  justify-content: flex-start;\n}\n\n.forgotPage .formContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  margin: 5px 0;\n}\n\n.forgotPage .button {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.forgotPage .inputField {\n  display: flex;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
