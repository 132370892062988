export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const SEND_RESET_EMAIL_SUCCESS = "SEND_RESET_EMAIL_SUCCESS";
export const GET_ALL_JOBS_FOR_ADMIN = "GET_ALL_JOBS_FOR_ADMIN";
export const REQUEST_ACCESS_SUCCESS = "REQUEST_ACCESS_SUCCESS";
export const REQUEST_HR_SUCCESS = "REQUEST_HR_SUCCESS";
export const GET_HR_REQUESTS_SUCCESS = "GET_HR_REQUESTS_SUCCESS";
export const GET_PROFILE = "GET_PROFILE";
export const SET_HR_REQUEST = "SET_HR_REQUEST";
export const GET_NOTIFY = "GET_NOTIFY";
export const SET_NOTIFY = "SET_NOTIFY";
export const GET_SKILLSET = "GET_SKILLSET";
export const GET_CAMPS = "GET_CAMPS";

export const SET_ROLE = "SET_NOTIFY";

export const CHECK_USER_SUCCESS = "CHECK_USER_SUCCESS";
export const RESET_PASS_SUCCESS = "RESET_PASS_SUCCESS";

export const GET_ENTRIES = "GET_ENTRIES";

export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const LOADER = "LOADER";
export const FAID_LOADER = "FAID_LOADER";
export const STATUS_LOADER = "STATUS_LOADER";
export const SKILLS_LOADER = "SKILLS_LOADER";
export const CAMPS_LOADER = "CAMPS_LOADER";

export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const GET_JOB_BY_ID = "GET_JOB_BY_ID";
export const GET_JOBS_BY_HR_ID = "GET_JOBS_BY_HR_ID";
export const CREATE_JOB = "CREATE_JOB";
export const UPDATE_JOB = "UPDATE_JOB";
export const CLOSE_JOB = "CLOSE_JOB";
export const HIDE_JOB = "HIDE_JOB";
export const UNHIDE_JOB = "UNHIDE_JOB";
export const GET_ALL_CANDIDATES = "GET_ALL_CANDIDATES";
export const GET_PROBABLE = "GET_PROBABLE";
export const GET_VIEWED = "GET_VIEWED";
export const INSERT_VIEWED = "INSERT_VIEWED";

export const GET_ALL_APPLICATIONS_USER = "GET_ALL_APPLICATIONS_USER";
export const GET_APPLICATION_USER = "GET_APPLICATION_USER";
export const APPLY_JOB = "APPLY_JOB";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const BULK_REJECT = "BULK_REJECT";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_PINCODES = "GET_PINCODES";
export const GET_DEGREES = "GET_DEGREES";
export const GET_LOCATIONS_STATES = "GET_LOCATIONS_STATES";

export const GET_ALL_CAMPS = "GET_ALL_CAMPS";
export const CREATE_CONTACT_ID = "CREATE_CONTACT_ID";
export const CREATE_FA_ID = "CREATE_FA_ID";
export const CREATE_EXPENSE = "CREATE_EXPENSE";
export const GET_ALL_EXPENSES = "GET_ALL_EXPENSES";
export const GET_FAID = "GET_FAID";
export const GET_EXPENSE_BY_ID = "GET_EXPENSE_BY ID";
export const REJECT_EXPENSE = "REJECT_EXPENSE";
export const PAY_EXPENSE = "PAY_EXPENSE";
export const GET_ALL_EXPENSES_BY_ID = "GET_ALL_EXPENSES_BY_ID";
export const GET_ALL_EXPENSES_BY_CAMP = "GET_ALL_EXPENSES_BY_CAMP";
export const SAVE_SKILLS = "SAVE_SKILLS";
export const SAVE_PROFILE = "SAVE_PROFILE";
export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
