import React, { useState, useEffect } from "react";
import JobCard from "../../../components/JobComponent/JobCard";
import "./AllJobAdmin.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllJobsForAdmin, getAllJobs } from "../../../actions/job.action";
import { Stack, Skeleton, Pagination } from "@mui/material";
import SnackBar from "../../../components/Alerts/SnackBar";
import exportFromJSON from "export-from-json";
import EmptyCard from "../../../components/Cards/EmptyCard";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const JobPortal = (props) => {
  dayjs.extend(relativeTime);
  const dispatch = useDispatch();

  const jobs = useSelector((state) => state.job.jobs);
  const isLoading = useSelector((state) => state.loader.isLoading);

  const [page, setPage] = useState(1);
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState("");
  const [check, setCheck] = useState(false);
  const [start_date, setStart_date] = useState(dayjs(Date.now()));
  const [end_date, setEnd_Date] = useState(dayjs(Date.now()));

  const handleOpenedit = () => setCheck(true);
  const handleCloseedit = () => setCheck(false);

  const handleStartDateChange = (event) => {
    setStart_date(event);
  };
  const handleEndDateChange = (event) => {
    setEnd_Date(event);
  };

  const handlePageChange = (event) => {
    setPage(event.target.innerText);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const [status, setStatus] = useState("All");
  const handleChange = (event) => {
    setStatus(event.target.value);
    setPage(1);
    dispatch(getAllJobsForAdmin(1, event.target.value)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  };

  useEffect(() => {
    dispatch(getAllJobsForAdmin(page, status)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  }, [page]);

  const handleDownload = () => {
    dispatch(getAllJobsForAdmin("All", status, start_date, end_date))
      .then((response) => {
        // console.log(response);
        const data = response;
        const fileName = `All Jobs for ADMIN`;
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
        handleCloseedit();
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
        handleCloseedit();
      });
  };

  return (
    <div className="managejobadmin">
      <SnackBar
        message={message}
        openError={openError}
        handleClose={handleClose}
      />
      <div className="container">
        {!isLoading && <div className="status">Job status</div>}
        {!isLoading && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <FormControl sx={{ mb: 2, minWidth: 180 }}>
              <Select
                value={status}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: 40,
                  backgroundColor: "white",
                  color: "var(--dull-text-color)",
                }}
              >
                <MenuItem value="" disabled>
                  Select status
                </MenuItem>
                <MenuItem value="All" style={{ color: "black" }}>
                  All Jobs
                </MenuItem>
                <MenuItem value="Active" style={{ color: "black" }}>
                  Active
                </MenuItem>
                <MenuItem value="Closed" style={{ color: "black" }}>
                  Closed
                </MenuItem>
                <MenuItem value="Hidden" style={{ color: "black" }}>
                  Hidden
                </MenuItem>
              </Select>
            </FormControl>

            <button className="com mainBtn" onClick={handleOpenedit}>
              Download All Jobs
            </button>
          </div>
        )}
        <div className="jobs">
          {!isLoading && jobs.jobs && !jobs.jobs.length && (
            <EmptyCard message="No Jobs Available" />
          )}
          {!isLoading ? (
            jobs.jobs &&
            jobs.jobs.map((job, index) => (
              <div key={index}>
                <JobCard job={job} key={index} />
              </div>
            ))
          ) : (
            <Stack spacing={2}>
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
              <Skeleton
                sx={{ bgcolor: "var(--alternate-button-color)" }}
                variant="rounded"
                height={150}
              />
            </Stack>
          )}
          {jobs.jobs && jobs.jobs.length > 0 && (
            <Stack spacing={2}>
              <Pagination
                count={jobs.totalPages}
                defaultValue={page}
                onChange={handlePageChange}
              />
            </Stack>
          )}
        </div>
      </div>
      <Modal
        open={check}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="Modalcontaineredit">
          <div className="createFA">
            <div className="pageHeadingModal">
              <div>Select Date Range</div>
              <div onClick={handleCloseedit} className="closebtn">
                <CloseIcon />
              </div>
            </div>
            <div className="personalbank">
              <div className="personalbankContainer">
                <div className="formContainer">
                  <div className="inputField">
                    <span>Start Date</span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={start_date}
                        maxDate={dayjs(Date.now())}
                        onChange={handleStartDateChange}
                        sx={{ width: 200 }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="inputField">
                    <span>End Date</span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={end_date}
                        maxDate={dayjs(Date.now())}
                        onChange={handleEndDateChange}
                        sx={{ width: 200 }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="buttonsedit">
                    <button
                      className="comedit createedit"
                      onClick={handleDownload}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default JobPortal;
