import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Select, FormControl, MenuItem } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  getEntries,
  updateRoleAction,
} from "../../../actions/profile.action.js";
import LinearProgress from "@mui/material/LinearProgress";
import "./UserManagement.css";

import SnackBar from "../../../components/Alerts/SnackBar";
import DialogueBox from "../../../components/Alerts/DialogueBox";

const CustomSelectCell = ({ row, value }) => {
  const dispatch = useDispatch();
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("Role action done");
  const [selectedValue, setSelectedValue] = useState(value);
  const user = useSelector((state) => state.auth.user);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [dialogueMessage, setDialogueMessage] = useState(
    "Do you really want to change their role?"
  );

  const handleClickOpen = (event) => {
    setSelectedValue(event.target.value);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleRoleChange = () => {
    setOpenAlert(false);
    setMessage("Role Update Successful");
    dispatch(updateRoleAction(row.id, selectedValue))
      .then(() => {
        setOpenSuccess(true);
        setTimeout(() => {
          dispatch(getEntries(value)).catch((message) => {
            setMessage(message);
            setOpenError(true);
          });
        }, 1000);
      })
      .catch((message) => {
        setMessage(message);
        setOpenError(true);
      });
  };

  return (
    <>
      <FormControl className="custom-select-cell">
        <Select
          disableUnderline
          value={value}
          onChange={handleClickOpen}
          renderValue={(selected) => selected}
        >
          <MenuItem value="Cadet" style={{ color: "#00940F" }}>
            Cadet
          </MenuItem>
          <MenuItem value="Trainer" style={{ color: "#230094" }}>
            Trainer
          </MenuItem>
          {user?.role === "S_ADMIN" && (
            <MenuItem value="Admin" style={{ color: "#FFA500" }}>
              Admin
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
      <DialogueBox
        dialogueMessage={dialogueMessage}
        handleFunction={handleRoleChange}
        handleCloseAlert={handleCloseAlert}
        openAlert={openAlert}
      />
    </>
  );
};

const UserManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState("Cadet");
  const roleEntry = useSelector((state) => state.profile.entries);
  const isLoading = useSelector((state) => state.loader.isLoading);
  const user = useSelector((state) => state.auth.user);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const handleChange = (event) => {
    setValue(event);
  };

  useEffect(() => {
    const handleRequests = () => {
      if (user?.role === "Admin" || user?.role === "S_ADMIN") {
        dispatch(getEntries(value)).catch((message) => {
          setMessage(message);
          setOpenError(true);
        });
      }
    };
    handleRequests();
  }, [dispatch, value]);

  //defining columns and its properties
  const columns = [
    { field: "name", headerName: "Name", minWidth: 300 },
    {
      field: "role",
      headerName: "Role",
      width: 300,
      renderCell: (params) => (
        <CustomSelectCell row={params.row} value={params.value} />
      ),
    },
    { field: "email", headerName: "Email", minWidth: 300 },
  ];

  //creating rows from the test data
  const rows =
    roleEntry && roleEntry.length > 0
      ? roleEntry.map((row) => ({
          name: row.data.name,
          id: row.id,
          role: row.data.role,
          email: row.data.email,
        }))
      : [];

  const handleRowClick = (params) => {
    navigate(`/profile/${params.row.id}`);
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <div className="userManagement">
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            variant="fullWidth"
            textColor="inherit"
            indicatorColor=""
            aria-label="secondary tabs example"
            centered
            sx={{
              "& .Mui-selected": {
                backgroundColor: "var(--primary-color)",
                color: "var(--light-text-color)",
              },
            }}
          >
            <Tab
              value="Cadet"
              label="Cadets"
              onClick={() => handleChange("Cadet")}
            />
            <Tab
              value="Trainer"
              label="Trainers"
              onClick={() => handleChange("Trainer")}
            />
            <Tab value="HR" label="HRs" onClick={() => handleChange("HR")} />
            <Tab
              value="Guest"
              label="Guests"
              onClick={() => handleChange("Guest")}
            />
            {user?.role === "S_ADMIN" && (
              <Tab
                value="Admin"
                label="Admins"
                onClick={() => handleChange("Admin")}
              />
            )}
          </Tabs>
        </Box>
        <div className="tablecontainer">
          <DataGrid
            autoHeight={true}
            sx={{ cursor: "pointer", paddingLeft: "1rem" }}
            onRowClick={handleRowClick}
            rows={rows}
            columns={columns}
            //defining initial state of the table, which options are required at startup
            initialState={{
              pagination: {
                paginationModel: {
                  page: 0,
                  pageSize: 10,
                },
              },
            }}
            // checkboxSelection
            //enabling toolbar, can also replace default GridToolbar with custom
            slots={{ toolbar: GridToolbar }}
            //configuring toolbar
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </div>
        <SnackBar
          message={message}
          openSuccess={openSuccess}
          openError={openError}
          handleClose={handleClose}
        />
      </div>
    </>
  );
};

export default UserManagement;
