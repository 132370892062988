import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  getProbable,
  insertViewed,
  getJobById,
} from "../../actions/job.action.js";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import SnackBar from "../Alerts/SnackBar.jsx";

const ProbableCandidates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobId } = useParams();

  const user = useSelector((state) => state.auth.user);
  const job = useSelector((state) => state.job.job.data);
  //console.log(job);
  const probable = useSelector((state) => state.job.probable);
  const isLoading = useSelector((state) => state.loader.isLoading);

  const [message, setMessage] = useState("");
  const [openError, setOpenError] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  useEffect(() => {
    dispatch(getProbable(jobId)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
    dispatch(getJobById(jobId)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  }, []);
  //console.log(job);

  //defining columns and its properties
  const columns = [
    { field: "name", headerName: "Name", minWidth: 300 },
    { field: "location", headerName: "Location", minWidth: 300 },
    {
      field: "skillsMatched",
      //temp fix
      headerName: "Skills Matched",
      minWidth: 300,
    },
  ];

  //creating rows from the test data
  const rows =
    probable && probable.length > 0
      ? probable.map((row) => ({
          name: row.name,
          id: row.id,
          // applicant_id: row.data.applicant_id,
          location: row.location,
          skillsMatched: `${row.skills}/${job?.skills.length}`,
          // applied: new Date(row.data.applied_date).toDateString(),
          // status: row.data.status,
        }))
      : [];

  const handleRowClick = (params) => {
    // navigate(`/jobapplication/${jobId}/${params.row.id}`);
    if (!(user.role === "Admin" || user.role === "S_ADMIN")) {
      //console.log(params);
      dispatch(
        insertViewed(jobId, params.row.id, params.row.skillsMatched)
      ).catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
    }
    navigate(`/profile/${params.row.id}`);
  };

  return (
    <div className="tablecontainer">
      <SnackBar
        message={message}
        openError={openError}
        handleClose={handleClose}
      />
      <DataGrid
        sx={{ cursor: "pointer" }}
        autoHeight={true}
        onRowClick={handleRowClick}
        rows={rows}
        columns={columns}
        //defining intial state of the table, which options are required at startup
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 10,
            },
          },
        }}
        checkboxSelection
        //enabling toolbar, can also replace default GridToolbar with custom
        slots={{ toolbar: GridToolbar, loadingOverlay: LinearProgress }}
        loading={isLoading}
        //configuring toolbar
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
};

export default ProbableCandidates;
