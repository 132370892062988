// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.inputLabel {
  font-size: 0.8em;
  color: var(--dull-text-color);
  font-weight: bold;
}
.inputBox {
  width: 94%;
  border: 1px solid var(--dark-text-color);
  background: #fbfbfb;
  color: var(--dark-text-color);
  border-radius: 5px;
  height: 20px;
  outline: none;
  padding: 0.6em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}`, "",{"version":3,"sources":["webpack://./src/components/Input/Input.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,6BAA6B;EAC7B,iBAAiB;AACnB;AACA;EACE,UAAU;EACV,wCAAwC;EACxC,mBAAmB;EACnB,6BAA6B;EAC7B,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,cAAc;AAChB;;AAEA,gCAAgC;AAChC;;EAEE,wBAAwB;EACxB,SAAS;AACX;AACA,YAAY;AACZ;EACE,0BAA0B;AAC5B","sourcesContent":[".inputDiv {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n}\n.inputLabel {\n  font-size: 0.8em;\n  color: var(--dull-text-color);\n  font-weight: bold;\n}\n.inputBox {\n  width: 94%;\n  border: 1px solid var(--dark-text-color);\n  background: #fbfbfb;\n  color: var(--dark-text-color);\n  border-radius: 5px;\n  height: 20px;\n  outline: none;\n  padding: 0.6em;\n}\n\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n/* Firefox */\ninput[type=number] {\n  -moz-appearance: textfield;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
