import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import PersonalCard from "../../components/Cards/PersonalCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Data from "../../components/Cards/Data";
import EditIcon from "@mui/icons-material/Edit";
import FileIcon from "@mui/icons-material/DescriptionRounded";
import { useNavigate } from "react-router-dom";
import { getProfile, getCamps } from "../../actions/profile.action";
import {
  Box,
  Tabs,
  Tab,
  Fab,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./Profile.css";
import MyExpense from "../Expense/MyExpense/MyExpense";
import AppliedJob from "../Jobs/AppliedJob/AppliedJob";
import ManageJobHR from "../Jobs/ManageJobHR/ManageJobHR";
import SnackBar from "../../components/Alerts/SnackBar";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const isLoading = useSelector((state) => state.loader.isLoading);
  const isLoadingCamps = useSelector((state) => state.loader.isLoadingCamps);
  const profile = useSelector((state) => state.profile);
  const user = useSelector((state) => state.auth.user);
  const camps = useSelector((state) => state.profile.camps);

  const [value, setValue] = useState("one");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const DateConverter = (dateString) => {
    if (!dateString) {
      return "Present";
    }
    const dateObj = new Date(dateString);
    const month = dateObj.toLocaleString("default", { month: "long" });
    const year = dateObj.getFullYear();
    return `${month} ${year}`;
  };
  const [check, setCheck] = useState("userprofile");

  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  // const [camps, setCamps] = useState([]);

  const handleCampsAccordion = (event, expanded) => {
    if (expanded) {
      dispatch(getCamps(profile.user.email))
        .then(() => {
          // setCamps(profile.camps);
        })
        .catch((message) => {
          setMessage(message);
          setOpenError(true);
        });
    }
  };

  useEffect(() => {
    dispatch(getProfile(id)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  }, [dispatch, id]);
  return (
    <div className="profilePage">
      {isLoading && check === "userprofile" && <LinearProgress />}
      {user.id !== id && (user.role === "Admin" || user.role === "S_ADMIN")
        ? (!isLoading ||
            check === "userexpense" ||
            check === "userappliedjobs" ||
            check === "userpostedjobs") && (
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                textColor="inherit"
                indicatorColor=""
                aria-label="secondary tabs example"
                centered
                sx={{
                  "& .Mui-selected": {
                    backgroundColor: "var(--primary-color)",
                    color: "var(--light-text-color)",
                  },
                }}
              >
                <Tab
                  value="one"
                  label="Profile"
                  onClick={() => setCheck("userprofile")}
                />
                {(profile.user.role === "Trainer" ||
                  profile.user.role === "Admin") && (
                  <Tab
                    value="two"
                    label="Past Expenses"
                    onClick={() => setCheck("userexpense")}
                  />
                )}
                {profile.user.role !== "HR" && (
                  <Tab
                    value="three"
                    label="Applied Jobs"
                    onClick={() => setCheck("userappliedjobs")}
                  />
                )}
                {profile.user.role === "HR" && (
                  <Tab
                    value="three"
                    label="Posted Jobs"
                    onClick={() => setCheck("userpostedjobs")}
                  />
                )}
              </Tabs>
            </Box>
          )
        : !isLoading && (
            <div className="pageHeading">
              <Typography sx={{ fontWeight: 500, fontSize: "20px" }}>
                Profile
              </Typography>
              <div>
                <Link className="linkMod" to={`/editprofile/${id} `}>
                  <Button variant="outlined" style={{ color: "white" }}>
                    Edit Profile
                  </Button>
                </Link>
              </div>
            </div>
          )}
      {!isLoading && (check === "userprofile" || user.id === id) && (
        <div className="profile">
          <PersonalCard profile={profile} />
          <div className="userInfoContainer">
            {/* EDUCATION */}
            <Accordion
              sx={{
                borderRadius: "5px",
                boxShadow: " 0px 4px 4px rgba(45, 48, 146, 0.25)",
              }}
              disableGutters="true"
              // defaultExpanded="true"
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="accordionTitle">Education</div>
              </AccordionSummary>
              <AccordionDetails>
                {profile.user.education !== undefined &&
                  profile.user.education.map((data, index) => (
                    <>
                      <Divider />
                      {(data.university || data.degree) && (
                        <Data
                          key={index}
                          title={data.university}
                          subTitle={data.degree}
                          duration={`${DateConverter(
                            data.from
                          )} - ${DateConverter(data.to)}`}
                        />
                      )}
                    </>
                  ))}
              </AccordionDetails>
            </Accordion>
            {/*WORK*/}
            <Accordion
              sx={{
                borderRadius: "5px",
                boxShadow: " 0px 4px 4px rgba(45, 48, 146, 0.25)",
              }}
              disableGutters="true"
              defaultExpanded="true"
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="accordionTitle">Work Experience</div>
              </AccordionSummary>
              <AccordionDetails>
                {profile.user.work_exp !== undefined &&
                  profile.user.work_exp.map((data, index) => (
                    <>
                      <Divider />
                      {(data.role || data.company_name) && (
                        <Data
                          key={index}
                          title={data.role}
                          subTitle={data.company_name}
                          duration={`${DateConverter(
                            data.from
                          )}-${DateConverter(data.to)}`}
                        />
                      )}
                    </>
                  ))}
              </AccordionDetails>
            </Accordion>
            {/* CERTIFICATES */}
            <Accordion
              sx={{
                borderRadius: "5px",
                boxShadow: " 0px 4px 4px rgba(45, 48, 146, 0.25)",
              }}
              disableGutters="true"
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="accordionTitle">Certificates</div>
              </AccordionSummary>
              <AccordionDetails>
                {profile.user.certifications !== undefined &&
                  profile.user.certifications.map((data, index) => (
                    <>
                      <Divider />
                      {data.name && (
                        <Data
                          title={data.name}
                          key={index}
                          subTitle={null}
                          duration={`${DateConverter(
                            data.from
                          )} - ${DateConverter(data.to)}`}
                        />
                      )}
                    </>
                  ))}
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                borderRadius: "5px",
                boxShadow: " 0px 4px 4px rgba(45, 48, 146, 0.25)",
              }}
              disableGutters="true"
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="accordionTitle">Resume</div>
              </AccordionSummary>
              <AccordionDetails>
                {profile.user.resume_url && (
                  <a
                    className="linkMod resumeBox"
                    target="_blank"
                    href={profile.user.resume_url}
                    rel="noreferrer"
                  >
                    <FileIcon sx={{ color: "#C60008" }} />
                    &nbsp;Resume
                  </a>
                )}
              </AccordionDetails>
            </Accordion>
            {(profile.user.role === "Trainer" ||
              profile.user.role === "Cadet") && (
              <>
                <Accordion
                  sx={{
                    borderRadius: "5px",
                    boxShadow: " 0px 4px 4px rgba(45, 48, 146, 0.25)",
                  }}
                  disableGutters="true"
                  onChange={handleCampsAccordion}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="accordionTitle">Camps Data</div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="campsBox">
                      {isLoadingCamps ? (
                        <>
                          <CircularProgress
                            size={24}
                            sx={{
                              margin: "0 auto",
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <div className="title">
                            Number of Camps Attended:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {camps.length}
                            </span>
                          </div>
                          <div className="content">
                            {camps.length > 0 &&
                              camps.map((camp) => <li>{camp}</li>)}
                          </div>
                        </>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </div>
        </div>
      )}
      {user.id !== id && check === "userexpense" && <MyExpense userid={id} />}
      {user.id !== id && check === "userappliedjobs" && (
        <AppliedJob userid={id} />
      )}
      {user.id !== id && check === "userpostedjobs" && (
        <ManageJobHR userid={id} />
      )}
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Profile;
