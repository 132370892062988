import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const DialogueBox = (props) => {
  return (
    <Dialog
      open={props.openAlert}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleCloseAlert}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Are you sure?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {props.dialogueMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCloseAlert}>No</Button>
        <Button onClick={props.handleFunction}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogueBox;
