import Axios from "./axios.service";
import authHeader from "./auth-header.service";

// get all jobs
const getAllJobs = async (page, search) => {
  let response;
  if (search) {
    response = await Axios.axiosJob.get(`/job${search}&page=${page}`, {
      headers: authHeader(),
    });
  } else {
    response = await Axios.axiosJob.get(`/job?page=${page}`, {
      headers: authHeader(),
    });
  }
  return response.data;
};

// get all jobs for admin
const getAllJobsForAdmin = async (page, status, start_date, end_date) => {
  const response = await Axios.axiosJob.get(
    `/job/jobs/all?status=${status}&page=${page}&start=${start_date}&end=${end_date}`,
    {
      headers: authHeader(),
    }
  );
  // console.log(response);
  return response.data;
};

// get job by id
const getJobById = async (jobId) => {
  const response = await Axios.axiosJob.get(`/job/${jobId}`, {
    headers: authHeader(),
  });
  return response.data.job;
};

// get jobs by hr id
const getJobsByHrId = async (hrId, page, start_date, end_date) => {
  const response = await Axios.axiosJob.get(
    `/job/hr/${hrId}?page=${page}&start=${start_date}&end=${end_date}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

// create job
const createJob = async (
  job_title,
  job_type,
  expiry_date,
  state,
  location,
  skills,
  job_description,
  short_job_description,
  questions
) => {
  const response = await Axios.axiosJob.post(
    "/job",
    {
      job_title,
      job_type,
      expiry_date,
      state,
      location,
      skills,
      job_description,
      short_job_description,
      questions,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

// update job -> expiry date
const updateJob = async (expiry_date, jobId) => {
  const response = await Axios.axiosJob.put(
    `/job/${jobId}`,
    {
      expiry_date,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

// close job
const closeJob = async (jobId) => {
  const response = await Axios.axiosJob.put(
    `/job/close/${jobId}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response;
};

// hide job
const hideJob = async (jobId, feedback) => {
  const response = await Axios.axiosJob.put(
    `/job/hide/${jobId}`,
    {
      feedback,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

//unhide job
const unhideJob = async (jobId) => {
  const response = await Axios.axiosJob.put(
    `/job/unhide/${jobId}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response;
};

// get all candidates for a job
const getCandidates = async (jobId) => {
  const respose = await Axios.axiosJob.get(`/application/users/${jobId}`, {
    headers: authHeader(),
  });

  return respose;
};

const getProbable = async (jobId) => {
  const response = await Axios.axiosJob.get(
    `/job/probable-candidates/${jobId}`,
    {
      headers: authHeader(),
    }
  );

  return response;
};

const getViewed = async (jobId) => {
  const response = await Axios.axiosJob.get(`/job/viewed-users/${jobId}`, {
    headers: authHeader(),
  });

  return response;
};

const insertViewed = async (jobId, userId, skills) => {
  // console.log(skills);
  const response = await Axios.axiosJob.post(
    "/job/viewed-users",
    {
      jobId,
      userId,
      skills,
    },
    {
      headers: authHeader(),
    }
  );
  // console.log(response.data);
  return response;
};

// get all applications for a user
const getApplications = async (userId, page, start_date, end_date) => {
  const respose = await Axios.axiosJob.get(
    `/application/${userId}?page=${page}&start=${start_date}&end=${end_date}`,
    {
      headers: authHeader(),
    }
  );

  return respose;
};

// get application for a user
const getApplication = async (jobId, applicantId) => {
  const respose = await Axios.axiosJob.get(
    `/application/user/${jobId}/${applicantId}`,
    {
      headers: authHeader(),
    }
  );
  return respose;
};

// apply job
const applyJob = async (job_id, quiz) => {
  const respose = await Axios.axiosJob.post(
    "/application",
    { job_id, quiz },
    { headers: authHeader() }
  );

  return respose;
};

// update status of application
const updateStatus = async (jobId, status, applicant_id, email, name, feedback) => {
  const respose = await Axios.axiosJob.put(
    `/application/hr/${jobId}`,
    { status, applicant_id, email, name, feedback},
    { headers: authHeader() }
  );

  return respose;
};

// bulk reject application
const bulkReject = async (jobId, applicants) => {
  const respose = await Axios.axiosJob.put(
    `/application/reject/${jobId}`,
    { applicants },
    { headers: authHeader() }
  );

  return respose;
};

// get states
const getLocations = async () => {
  const response = await Axios.axiosJob.get(`/job/locations/detail`, {
    headers: authHeader(),
  });

  return response;
};

// get cities
const getLocationsCity = async (city) => {
  const response = await Axios.axiosJob.get(`/job/locations/detail/${city}`, {
    headers: authHeader(),
  });

  return response;
};

// get Pincodes
const getLocationsPincodes = async (state, city) => {
  const response = await Axios.axiosJob.get(
    `/job/locations/detail/${state}/${city}`,
    {
      headers: authHeader(),
    }
  );

  return response;
};
//get degree list
const getDegreeList = async () => {
  const response = await Axios.axiosJob.get(`/job/degree/detail`, {
    headers: authHeader(),
  });

  return response;
};
const JobService = {
  getAllJobs,
  getJobById,
  getJobsByHrId,
  createJob,
  updateJob,
  closeJob,
  hideJob,
  unhideJob,
  getCandidates,
  getProbable,
  getViewed,
  insertViewed,
  getApplications,
  getApplication,
  applyJob,
  updateStatus,
  bulkReject,
  getLocations,
  getLocationsCity,
  getLocationsPincodes,
  getAllJobsForAdmin,
  getDegreeList,
};

export default JobService;
