import {
  APPLY_JOB,
  BULK_REJECT,
  CLOSE_JOB,
  CREATE_JOB,
  GET_ALL_APPLICATIONS_USER,
  GET_ALL_CANDIDATES,
  GET_PROBABLE,
  GET_VIEWED,
  INSERT_VIEWED,
  GET_ALL_JOBS,
  GET_APPLICATION_USER,
  GET_JOBS_BY_HR_ID,
  GET_JOB_BY_ID,
  HIDE_JOB,
  UNHIDE_JOB,
  UPDATE_JOB,
  UPDATE_STATUS,
  GET_LOCATIONS,
  GET_PINCODES,
  GET_DEGREES,
  GET_LOCATIONS_STATES,
  GET_ALL_JOBS_FOR_ADMIN,
} from "../actions/types";

const initialState = {
  jobs: [],
  job: {},
  candidates: [],
  probable: [],
  viewed: [],
  applications: [],
  application: {},
  states: [],
  city: [],
};

export const job = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_JOBS:
      return {
        ...state,
        jobs: payload.jobs,
      };
    case GET_ALL_JOBS_FOR_ADMIN:
      return {
        ...state,
        jobs: payload.jobs,
      };
    case GET_JOB_BY_ID:
      return {
        ...state,
        job: payload.job,
      };
    case GET_JOBS_BY_HR_ID:
      return {
        ...state,
        jobs: payload.jobs,
      };
    case CREATE_JOB:
      return state;
    case UPDATE_JOB:
      return state;
    case CLOSE_JOB:
      return state;
    case HIDE_JOB:
      return state;
    case UNHIDE_JOB:
      return state;
    case GET_ALL_CANDIDATES:
      return {
        ...state,
        candidates: payload.candidates,
      };
    case GET_PROBABLE:
      return {
        ...state,
        probable: payload.candidates,
      };
    case GET_VIEWED:
      return {
        ...state,
        viewed: payload.candidates,
      };
    case INSERT_VIEWED:
      return state;
    case GET_ALL_APPLICATIONS_USER:
      return {
        ...state,
        applications: payload.applications,
      };
    case GET_APPLICATION_USER:
      return {
        ...state,
        application: payload.application,
      };
    case APPLY_JOB:
      return state;
    case UPDATE_STATUS:
      return state;
    case BULK_REJECT:
      return state;
    case GET_LOCATIONS_STATES:
      return {
        ...state,
        states: payload.states,
      };
    case GET_LOCATIONS:
      return {
        ...state,
        city: payload.city,
      };
    case GET_PINCODES:
      return {
        ...state,
        pincodes: payload.pincodes,
      };
    case GET_DEGREES:
      return {
        ...state,
        degrees: payload.degrees,
      };
    default:
      return state;
  }
};
