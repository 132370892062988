import Axios from "./axios.service";
import authHeader from "./auth-header.service";

// get all Camps
const getAllCamps = async (page) => {
  const response = await Axios.axiosExpense.get(`/camp/getall?page=${page}`, {
    headers: authHeader(),
  });
  return response;
};

// create contact id
const createContactId = async (name, email, contact, user_id) => {
  const response = await Axios.axiosExpense.post(
    "/payout/contact",
    {
      name,
      email,
      contact,
      user_id,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

// create fund id
const createFaId = async (contact_id, name, email, ifsc, account_number) => {
  const response = await Axios.axiosExpense.post(
    "/payout/fund",
    {
      contact_id,
      name,
      email,
      ifsc,
      account_number,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

// create new expense
const createExpense = async (
  camp_name,
  fa_id,
  created_by,
  status,
  total_amount,
  bills,
  name,
  email
) => {
  const response = await Axios.axiosExpense.post(
    "/expense/create",
    {
      camp_name,
      fa_id,
      created_by,
      status,
      total_amount,
      bills,
      name,
      email,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

// get all expenses
const getAllExpenses = async (page, status) => {
  const response = await Axios.axiosExpense.get(`/expense/getall?status=${status}&page=${page}`, {
    headers: authHeader(),
  });
  return response;
};

// get FaId
const getFaId = async (user_id) => {
  const response = await Axios.axiosExpense.get(`/payout/getfaid/${user_id}`, {
    headers: authHeader(),
  });
  return response;
};

// get expense detail by expense id
const getExpenseById = async (id) => {
  const response = await Axios.axiosExpense.get(`/expense/${id}`, {
    headers: authHeader(),
  });
  return response;
};

// Reject Expense
const rejectExpense = async (docId, email, comment, status, name) => {
  const response = await Axios.axiosExpense.post(
    "/payout/reject",
    {
      docId,
      email,
      comment,
      status,
      name
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

// Pay Expense
const payExpense = async (docId, fa_id, email, total_amount, status, mode, name) => {
  const response = await Axios.axiosExpense.post(
    "/payout/pay",
    {
      docId,
      fa_id,
      email,
      total_amount,
      status,
      mode,
      name
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

// get my expenses
const getExpensesByUserId = async (id, page, status) => {
  const response = await Axios.axiosExpense.get(`/expense/getExpense/${id}?status=${status}&page=${page}`, {
    headers: authHeader(),
  });
  return response;
};

// get expenses by camp
const getExpensesByCamp = async (camp_name, page, status) => {
  const response = await Axios.axiosExpense.post(
    `/expense/getExpensesByCamp?status=${status}&page=${page}`,
    {
      camp_name,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

// download expenses
const getExpensesByUserIdDownload = async (id, start_date, end_date) => {
  const response = await Axios.axiosExpense.get(`/expense/downloadExpense/${id}/${start_date}/${end_date}`, {
    headers: authHeader(),
  });
  return response;
};

const updateExpense = async (
  camp_name,
  fa_id,
  created_by,
  status,
  total_amount,
  bills,
  name,
  email,
  id
) => {
  const response = await Axios.axiosExpense.post(
    `/expense/update/${id}`,
    {
      camp_name,
      fa_id,
      created_by,
      status,
      total_amount,
      bills,
      name,
      email,
    },
    {
      headers: authHeader(),
    }
  );
  return response;
};

const ExpenseService = {
  getAllCamps,
  createContactId,
  createFaId,
  createExpense,
  getAllExpenses,
  getFaId,
  getExpenseById,
  rejectExpense,
  payExpense,
  getExpensesByUserId,
  getExpensesByCamp,
  getExpensesByUserIdDownload,
  updateExpense
};

export default ExpenseService;
