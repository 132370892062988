import React, { useState, useEffect } from "react";
import "./EditExpense.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCamps,
  getFaId,
  getExpenseById,
  updateExpense,
} from "../../../actions/expense.action";
import LinearProgress from "@mui/material/LinearProgress";
import { storage } from "../../../firebase/firebaseConnection";
import SnackBar from "../../../components/Alerts/SnackBar";

const EditExpense = () => {
  const navigate = useNavigate();

  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const [camp, setCamp] = useState("");
  const [total, setTotal] = useState(0);
  const [date, setDate] = useState(dayjs(Date.now()));

  const [bill, setBill] = useState("");

  const handleBillUpload = (index) => {
    if (bill == null) return;
    storage
      .ref(`/bills/${bill.name}`)
      .put(bill)
      .on(
        "state_changed",
        setMessage("Uploaded Successfully"),
        setOpenSuccess(true),
        () => {
          storage
            .ref("bills")
            .child(bill.name)
            .getDownloadURL()
            .then((url) => {
              const updatedFields = [...fields];
              updatedFields[index]["billUrl"] = url;
              setFields(updatedFields);
            });
        }
      );
  };

  const handleCampChange = (event) => {
    setCamp(event.target.innerText);
  };

  const handleDateChange = (event) => {
    setDate(event);
  };

  const handleCancel = (event) => {
    navigate(-1);
  };

  const [fields, setFields] = useState([
    { subexpensename: "", amount: 0, billUrl: "" },
  ]);

  const addField = () => {
    setFields([...fields, { subexpensename: "", amount: 0, billUrl: "" }]);
    // console.log(fields);
  };

  const removeField = (index) => {
    const rem = [...fields];
    rem.splice(index, 1);
    setFields(rem);
    let val1 = fields[index]["amount"];
    let v = total;
    val1 = Number(v - Number(val1));
    // console.log(val1);
    setTotal(val1);
  };

  const updateField = (index, e) => {
    const updatedFields = [...fields];
    updatedFields[index][e.target.name] = e.target.value;
    setFields(updatedFields);
    let val = 0;
    fields.map((am) => (val = Number(val + Number(am.amount))));
    setTotal(val);
  };

  const isLoading = useSelector((state) => state.loader.isLoading);
  const dispatch = useDispatch();
  const camps = useSelector((state) => state.expense.camps);
  const fa_id = useSelector((state) => state.expense.faId);
  const bank = useSelector((state) => state.expense.bank);
  const email = useSelector((state) => state.expense.email);
  const created_by = useSelector((state) => state.auth.user.id);
  const name = useSelector((state) => state.auth.user.name);
  const expense = useSelector((state) => state.expense.expense);

  const status = "pending";
  const handleCreate = (e) => {
    e.preventDefault();
    if (
      !camp ||
      !fa_id ||
      total === 0 ||
      !fields[0].billUrl ||
      !fields[0].subexpensename
    ) {
      setMessage("Please fill all the fields!");
      setOpenError(true);
      return;
    }

    dispatch(
      updateExpense(
        camp,
        fa_id,
        created_by,
        status,
        total,
        fields,
        name,
        email,
        expense.id
      )
    )
      .then(() => {
        setMessage("Expense Updated Successfully");
        setOpenSuccess(true);
        setTimeout(() => {
          navigate("/expense");
        }, 3000);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };
  const { id } = useParams();

  useEffect(() => {
    // console.log(bank);
    dispatch(getFaId(created_by)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
    dispatch(getAllCamps("All")).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
    dispatch(getExpenseById(id)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  }, []);
  useEffect(() => {
    if (expense.data) {
      if (expense.data.status !== "Revision") {
        navigate(-1);
      }
      if (expense.data.created_by !== created_by) {
        navigate(-1);
      }
      setCamp(expense.data.camp_name);
      if (expense.data.bills !== undefined) {
        setFields(expense.data.bills);
        let valt = 0;
        fields.map((am) => (valt = Number(valt + Number(am.amount))));
        setTotal(valt);
      }
    }
  }, [expense]);
  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <>
      {bank && camps && fa_id && email && name && expense.data && camp && (
        <div className="editExpense">
          <div className="pageHeading">Edit Expense</div>
          <div className="container">
            <div className="details">
              <div className="camp col">
                Camp Name
                <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  options={camps.map((option) => option.data.name)}
                  sx={{
                    width: 200,
                  }}
                  defaultValue={camp}
                  onChange={handleCampChange}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Camp Name" />
                  )}
                />
              </div>
              <div className="date col">
                Date of Expense
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={date}
                    onChange={handleDateChange}
                    sx={{ width: 200 }}
                  />
                </LocalizationProvider>
              </div>

              <div className="fa col">
                IFSC Code
                <div className="faval">{bank.bank_account.ifsc}</div>
              </div>
              <div className="fa col">
                Bank Name
                <div className="faval">{bank.bank_account.bank_name}</div>
              </div>
              <div className="fa col">
                Account Number
                <div className="faval">{bank.bank_account.account_number}</div>
              </div>
            </div>
            <div className="sub">
              {fields.map((field, index) => (
                <div className="subexpensetab">
                  <div className="subexpense col">
                    Sub-Expense Name
                    <TextField
                      id="outlined-basic"
                      size="small"
                      placeholder="Ex: Food"
                      variant="outlined"
                      name="subexpensename"
                      value={field.subexpensename}
                      onChange={(e) => updateField(index, e)}
                      sx={{
                        minWidth: 300,
                      }}
                    />
                  </div>
                  <div className="amountexpense col">
                    Amount
                    <TextField
                      size="small"
                      id="outlined-basic"
                      placeholder=""
                      variant="outlined"
                      name="amount"
                      value={field.amount}
                      type="number"
                      onChange={(e) => updateField(index, e)}
                      sx={{
                        minWidth: 300,
                      }}
                    />
                  </div>
                  <div className="billupload col">
                    Bill
                    <span
                      style={{
                        fontSize: "10px",
                        fontWeight: "400",
                        color: "#2d3092",
                      }}
                    >
                      If you want to change the Bill?*
                    </span>
                    <input
                      type="file"
                      onChange={(e) => {
                        setBill(e.target.files[0]);
                      }}
                    />
                    <button onClick={(e) => handleBillUpload(index)}>
                      {" "}
                      Upload{" "}
                    </button>
                  </div>
                  {fields.length !== 1 && (
                    <CloseIcon
                      sx={{ color: "var(--primary-color)", cursor: "pointer" }}
                      onClick={() => removeField(index)}
                    />
                  )}
                </div>
              ))}
              <div className="buton">
                <button className="com add" onClick={addField}>
                  + Add Sub-Expense
                </button>
              </div>
            </div>
            <div className="detailend">
              <div className="totalamount col">
                Total Amount
                <div className="amount">₹{total}</div>
              </div>
              <div className="buttons">
                <button className="com cancel" onClick={handleCancel}>
                  Cancel
                </button>
                <button className="com create" onClick={handleCreate}>
                  Save
                </button>
              </div>
            </div>
          </div>
          <SnackBar
            message={message}
            openSuccess={openSuccess}
            openError={openError}
            handleClose={handleClose}
          />
        </div>
      )}
    </>
  );
};

export default EditExpense;
