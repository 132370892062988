// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminRequests {
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.adminRequests .container {
  width: 70%;
}

.adminRequests .status {
  font-weight: 500;
  font-size: 14px;
  color: var(--primary-color);
}

.adminRequests .expenses {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.adminRequests .container .mainBtn {
  background-color: var(--primary-color);
  color: var(--light-text-color);
  border: 1.5px solid var(--primary-color);
}
.adminRequests .container .com {
  padding: 8px 20px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 16px;
}

.inputField {
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/modules/Expense/AdminRequests/AdminRequests.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,sCAAsC;EACtC,8BAA8B;EAC9B,wCAAwC;AAC1C;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".adminRequests {\n  min-height: 100vh;\n  padding: 2rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.adminRequests .container {\n  width: 70%;\n}\n\n.adminRequests .status {\n  font-weight: 500;\n  font-size: 14px;\n  color: var(--primary-color);\n}\n\n.adminRequests .expenses {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n.adminRequests .container .mainBtn {\n  background-color: var(--primary-color);\n  color: var(--light-text-color);\n  border: 1.5px solid var(--primary-color);\n}\n.adminRequests .container .com {\n  padding: 8px 20px;\n  border-radius: 5px;\n  font-weight: 500;\n  cursor: pointer;\n  margin-bottom: 16px;\n}\n\n.inputField {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
