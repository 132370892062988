import React from "react";
import "./Cards.css";
const Data = (props) => {
  return (
    <div className="data">
      <div className="title">{props.title}</div>
      <div className="subTitle">{props.subTitle}</div>
      <div className="duration">{props.duration}</div>
    </div>
  );
};

export default Data;
