import {
  LOADER,
  FAID_LOADER,
  STATUS_LOADER,
  SKILLS_LOADER,
  CAMPS_LOADER,
} from "../actions/types";

const initialState = {
  isLoading: false,
  isLoadingFaid: false,
  isLoadingStatus: false,
  isLoadingSkills: false,
  isLoadingCamps: false,
};

export const loader = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADER:
      return {
        ...state,
        isLoading: payload,
      };
    case FAID_LOADER:
      return {
        ...state,
        isLoadingFaid: payload,
      };
    case STATUS_LOADER:
      return {
        ...state,
        isLoadingStatus: payload,
      };
    case SKILLS_LOADER:
      return {
        ...state,
        isLoadingSkills: payload,
      };
    case CAMPS_LOADER:
      return {
        ...state,
        isLoadingCamps: payload,
      };
    default:
      return state;
  }
};
