import React, { useState, useEffect } from "react";
import "./JobPage.css";
import JobDescription from "./JobDescription";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  closeJob,
  getJobById,
  updateJob,
  hideJob,
  unhideJob,
} from "../../actions/job.action";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CircularProgress, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import SnackBar from "../Alerts/SnackBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JobPage = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const job = useSelector((state) => state.job.job);
  const user = useSelector((state) => state.auth.user);
  const isLoading = useSelector((state) => state.loader.isLoading);
  const isLoadingStatus = useSelector((state) => state.loader.isLoadingStatus);

  const [check, setCheck] = useState(false);
  const [date, setDate] = useState(dayjs(Date.now()));
  const [message, setMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const [hideComment, setHideComment] = useState(null);
  const [openHideComment, setOpenHideComment] = useState(false);

  const handleHide = () => {
    setOpenHideComment(true);
  };

  const handleNotHide = () => {
    setHideComment(null);
    setOpenHideComment(false);
  };

  const handleOpenedit = () => setCheck(true);
  const handleCloseedit = () => setCheck(false);

  const handleDateChange = (event) => {
    setDate(event);
  };

  const handleUpdateDate = (e) => {
    e.preventDefault();
    dispatch(updateJob(date, jobId))
      .then(() => {
        setCheck(false);
        setMessage("Updated Successfully");
        setOpenSuccess(true);
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };

  useEffect(() => {
    dispatch(getJobById(jobId)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
    setOpenError(false);
  };

  const handleCloseJob = () => {
    dispatch(closeJob(jobId))
      .then(() => {
        setOpenAlert(false);
        setMessage("Job has been closed");
        setOpenSuccess(true);
      })
      .catch((err) => {
        setOpenAlert(false);
        setMessage(err);
        setOpenError(true);
      });
  };

  const handleClickOpen = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleConfirmHide = () => {
    if (!hideComment) {
      setMessage("Please give a comment");
      setOpenError(true);
      return;
    }
    dispatch(hideJob(jobId, hideComment))
      .then((message) => {
        setMessage(message);
        setOpenSuccess(true);
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };
  const handleUnhide = () => {
    dispatch(unhideJob(jobId))
      .then((message) => {
        setMessage(message);
        setOpenSuccess(true);
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      })
      .catch((message) => {
        setMessage(message);
        setOpenError(true);
      });
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <div className="jobPage">
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
      {job.data && (
        <>
          <div className="actions">
            <div className="buttons">
              {user.role === "HR" && (
                <>
                  {job.data.job_status === "Active" ? (
                    <>
                      <div className="com back" onClick={() => navigate(-1)}>
                        <ArrowBackIcon />
                      </div>
                      <div className="toEnd">
                        <button
                          className={`com ${
                            isLoading ? "loadingBtn" : "warningBtn"
                          }`}
                          onClick={handleClickOpen}
                        >
                          {isLoading ? (
                            <>
                              <CircularProgress
                                color="inherit"
                                size={12}
                                sx={{ marginRight: "4px" }}
                              />
                              Closing
                            </>
                          ) : (
                            <>Close Job</>
                          )}
                        </button>
                        <button className="com altBtn" onClick={handleOpenedit}>
                          Edit Date
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      {job.data.job_status === "Hidden" ? (
                        <>
                          <div
                            className="com back"
                            onClick={() => navigate(-1)}
                          >
                            <ArrowBackIcon />
                          </div>
                          <div className="feedback">
                            <div className="title">Reason for hiding</div>
                            <div className="content">{job.data.feedback}</div>
                          </div>
                        </>
                      ) : (
                        <>
                          {job.data.job_status === "Closed" ? (
                            <>
                              <div
                                className="com back"
                                onClick={() => navigate(-1)}
                              >
                                <ArrowBackIcon />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {user.role === "Admin" || user.role === "S_ADMIN" ? (
                <>
                  {job.data.job_status === "Hidden" ? (
                    <>
                      <div className="com back" onClick={() => navigate(-1)}>
                        <ArrowBackIcon />
                      </div>
                      <button
                        className={`com ${
                          isLoadingStatus ? "loadingBtn" : "warningBtn"
                        }`}
                        onClick={handleUnhide}
                        disabled={isLoadingStatus}
                      >
                        {isLoadingStatus ? (
                          <>
                            <CircularProgress
                              color="inherit"
                              size={12}
                              sx={{ marginRight: "4px" }}
                            />
                            Unhiding
                          </>
                        ) : (
                          <>Unhide This Job</>
                        )}
                      </button>
                    </>
                  ) : (
                    <>
                      {job.data.job_status === "Active" ? (
                        <>
                          {openHideComment ? (
                            <div className="rejectBox">
                              <div className="headlabel">
                                Hide Comments {"(required)"}
                              </div>
                              <TextField
                                id="outlined-basic"
                                size="small"
                                placeholder="Add Hide Comment"
                                variant="outlined"
                                name="hideComment"
                                value={hideComment}
                                onChange={(e) => setHideComment(e.target.value)}
                                sx={{
                                  backgroundColor: "white",
                                }}
                              />
                              <div className="rejectButtons">
                                <button
                                  className="com altBtn"
                                  onClick={handleNotHide}
                                >
                                  Cancel
                                </button>
                                <button
                                  className={`com ${
                                    isLoadingStatus
                                      ? "loadingBtn"
                                      : "warningBtn"
                                  }`}
                                  onClick={handleConfirmHide}
                                  disabled={isLoadingStatus}
                                >
                                  {isLoadingStatus ? (
                                    <>
                                      <CircularProgress
                                        color="inherit"
                                        size={12}
                                        sx={{ marginRight: "4px" }}
                                      />
                                      Hiding
                                    </>
                                  ) : (
                                    <>Save & Hide</>
                                  )}
                                </button>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div
                                className="com back"
                                onClick={() => navigate(-1)}
                              >
                                <ArrowBackIcon />
                              </div>
                              <button
                                className="com warningBtn"
                                onClick={handleHide}
                              >
                                Hide This Job
                              </button>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {job.data.job_status === "Closed" ? (
                            <>
                              <div
                                className="com back"
                                onClick={() => navigate(-1)}
                              >
                                <ArrowBackIcon />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <JobDescription />
          <div className="questionsBox">
            {job.data.questions !== null &&
              job.data.questions.map((question, index) => (
                <div className="question" key={index}>
                  <div className="questionText">
                    Q{index + 1}: {question}
                  </div>
                </div>
              ))}

            <div>
              <Dialog
                open={openAlert}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseAlert}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>{"Are you sure?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Do you really want to close the job?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseAlert}>Disagree</Button>
                  <Button onClick={handleCloseJob}>Agree</Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
          <Modal
            open={check}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="Modalcontaineredit">
              <div className="createFA">
                <div className="pageHeadingModal">
                  <div>Edit Last Date</div>
                  <div onClick={handleCloseedit} className="closebtn">
                    <CloseIcon />
                  </div>
                </div>
                <div className="personalbank">
                  <div className="personalbankContainer">
                    <div className="formContainer">
                      <div className="inputField">
                        <span>Last Date</span>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={date}
                            minDate={dayjs(Date.now())}
                            onChange={handleDateChange}
                            sx={{ width: 200 }}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="buttonsedit">
                        <button
                          className="comedit createedit"
                          onClick={handleUpdateDate}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default JobPage;
