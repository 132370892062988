import React from "react";
import Modal from "@mui/material/Modal";
import Personal from "../FA_ID/Personal";
import CloseIcon from "@mui/icons-material/Close";

const Faid = (props) => {
  return (
    <Modal
      open={props.check}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="Modalcontainer">
        <div className="createFA">
          <div className="pageHeadingModal">
            <div>Add Bank Details</div>
            <div onClick={props.handleClose} className="closebtn">
              <CloseIcon />
            </div>
          </div>
          <Personal handleClose = {props.handleClose} />
        </div>
      </div>
    </Modal>
  );
};

export default Faid;