import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { bulkReject, getCandidates } from "../../actions/job.action";
import { LinearProgress, CircularProgress } from "@mui/material";
import SnackBar from "../Alerts/SnackBar";
import "./Table.css";

const AppliedCandidates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobId } = useParams();
  const candidates = useSelector((state) => state.job.candidates);
  const isLoading = useSelector((state) => state.loader.isLoading);
  const isLoadingStatus = useSelector((state) => state.loader.isLoadingStatus);

  const [message, setMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const handleSelection = (event) => {
    setSelectionModel(event);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
    setOpenError(false);
  };

  const handleReject = () => {
    dispatch(bulkReject(jobId, selectionModel))
      .then(() => {
        setMessage("Candidates rejected");
        setOpenSuccess(true);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };

  useEffect(() => {
    dispatch(getCandidates(jobId)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  }, []);

  //defining columns and its properties
  const columns = [
    { field: "name", headerName: "Name", minWidth: 250 },
    { field: "location", headerName: "Location", minWidth: 350 },
    { field: "applied", headerName: "Applied Date", minWidth: 250 },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <div
          style={{
            color:
              params.value === "Selected"
                ? "#008e0e"
                : params.value === "Rejected"
                ? "#ba1b1b"
                : params.value === "Waitlist"
                ? "var(--primary-color)"
                : "#ffa500",
            fontWeight: 500,
          }}
        >
          {params.value}
        </div>
      ),
    },
  ];

  //creating rows from the test data
  const rows =
    candidates && candidates.length > 0
      ? candidates.map((row) => ({
          name: row.user,
          id: row.data.applicant_id,
          location: row.location,
          applied: new Date(row.data.applied_date).toDateString(),
          status: row.data.status,
        }))
      : [];

  const handleRowClick = (params) => {
    navigate(`/jobapplication/${jobId}/${params.row.id}`);
  };

  return (
    <div className="tablecontainer">
      <SnackBar
        message={message}
        openError={openError}
        openSuccess={openSuccess}
        handleClose={handleClose}
      />
      <DataGrid
        sx={{ cursor: "pointer" }}
        autoHeight={true}
        onRowClick={handleRowClick}
        rows={rows}
        columns={columns}
        //defining intial state of the table, which options are required at startup
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 10,
            },
          },
        }}
        checkboxSelection
        //enabling toolbar, can also replace default GridToolbar with custom
        slots={{
          toolbar: GridToolbar,
          loadingOverlay: LinearProgress,
        }}
        loading={isLoading}
        //configuring toolbar
        onRowSelectionModelChange={handleSelection}
        selectionModel={selectionModel}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
      {selectionModel.length > 0 && (
        // <div>
        <button
          className={`${isLoadingStatus ? "loading" : "reject"}`}
          onClick={handleReject}
          disabled={isLoadingStatus}
        >
          {isLoadingStatus ? (
            <>
              <CircularProgress
                color="inherit"
                size={12}
                sx={{ marginRight: "4px" }}
              />
              Rejecting
            </>
          ) : (
            <>Reject applicants</>
          )}
        </button>
        // </div>
      )}
    </div>
  );
};

export default AppliedCandidates;
