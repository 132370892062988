import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import JobDescription from "../../../components/JobComponent/JobDescription";
import { applyJob, getJobById, hideJob } from "../../../actions/job.action";
import { TextField, LinearProgress, CircularProgress } from "@mui/material";
import SnackBar from "../../../components/Alerts/SnackBar";
import "./JobDetail.css";

const JobDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const job = useSelector((state) => state.job.job);
  const isLoading = useSelector((state) => state.loader.isLoading);
  const isLoadingStatus = useSelector((state) => state.loader.isLoadingStatus);

  const [answers, setAnswers] = useState("");
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  useEffect(() => {
    dispatch(getJobById(params.jobId)).catch((err) => {
      setMessage(err);
      setOpenError(true);
    });
  }, []);

  const handleAnswer1Change = (index, e) => {
    const temp = [...answers];
    temp[index] = e.target.value;
    setAnswers(temp);
  };

  const handleApplyJob = () => {
    dispatch(applyJob(params.jobId, answers))
      .then(() => {
        setMessage("Applied successfully");
        setOpenSuccess(true);
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };

  const handleHideJob = () => {
    dispatch(hideJob(params.jobId))
      .then(() => {
        setMessage("Job hidden successfully");
        setOpenSuccess(true);
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <div className="jobDetail">
      <div className="pageHeading">Job Description</div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
      {job.data && (
        <div className="jobDes">
          {/* <div className="actions">actions here</div> */}
          <JobDescription />
          <div className="questionsBox">
            {job.data.questions &&
              job.data.questions.map((field, index) => (
                <div className="question">
                  <div className="questionText">
                    Q{index + 1}: {job.data.questions[index]}
                  </div>
                  {(user.role === "Cadet" || user.role === "Trainer") && (
                    <TextField
                      id="outlined-basic"
                      size="small"
                      placeholder="I am very experienced"
                      variant="outlined"
                      value={answers[index]}
                      onChange={(e) => handleAnswer1Change(index, e)}
                      sx={{
                        minWidth: "100%",
                        "& .MuiInputBase-root": {
                          // height: 40,
                          fontSize: 14,
                        },
                      }}
                    />
                  )}
                </div>
              ))}
            <div className="buttons">
              <button className="com cancel" onClick={() => navigate(-1)}>
                Back
              </button>
              {(user.role === "Cadet" || user.role === "Trainer") && (
                <button
                  className={`com ${isLoadingStatus ? "loading" : "create"}`}
                  onClick={handleApplyJob}
                  disabled={isLoadingStatus || params?.isApplied === "true"}
                >
                  {isLoadingStatus ? (
                    <>
                      <CircularProgress
                        color="inherit"
                        size={12}
                        sx={{ marginRight: "4px" }}
                      />
                      Applying
                    </>
                  ) : (
                    <>
                      {params?.isApplied === "true"
                        ? "Already Applied"
                        : "Apply"}
                    </>
                  )}
                </button>
              )}
              {(user.role === "Admin" || user.role === "S_ADMIN") && (
                <button
                  className={`com ${isLoadingStatus ? "loading" : "create"}`}
                  onClick={handleHideJob}
                  disabled={isLoadingStatus}
                >
                  {isLoadingStatus ? (
                    <>
                      <CircularProgress
                        color="inherit"
                        size={12}
                        sx={{ marginRight: "4px" }}
                      />
                      Hiding
                    </>
                  ) : (
                    <>Hide</>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobDetail;
