import { combineReducers } from "redux";
import { loader } from "./loader.reducer";
import { auth } from "./auth";
import { message } from "./message";
import { job } from "./job.reducer";
import { expense } from "./expense.reducer";
import { profile } from "./profile.reducer";

export default combineReducers({
  loader,
  auth,
  message,
  job,
  expense,
  profile,
});
