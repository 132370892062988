import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocations, getLocationsCity } from "../../actions/job.action";
import { getSkillSet } from "../../actions/profile.action";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import WorkIcon from "@mui/icons-material/WorkOutline";
import SchoolIcon from "@mui/icons-material/SchoolOutlined";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";
import {
  TextField,
  Autocomplete,
  Stack,
  // FormControl,
  // Select,
  // MenuItem,
} from "@mui/material";
import SnackBar from "../../components/Alerts/SnackBar";
import "./JobFilter.css";

const JobFilter = () => {
  const dispatch = useDispatch();
  const loc = useLocation();
  const navigate = useNavigate();

  // const user = useSelector((state) => state.auth.user);
  const city = useSelector((state) => state.job.city);
  const states = useSelector((state) => state.job.states);
  const skillSet = useSelector((state) => state.profile.skillSet);

  const [jobTitle, setJobTitle] = useState("");
  const [skills, setSkills] = useState([]);
  const [location, setLocation] = useState("");
  const [state, setLocationState] = useState("");
  const [fetchState, setFetchState] = useState(false);
  const [status, setStatus] = useState("");
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const handleJobTitle = (event) => {
    setJobTitle(event.target.value);
  };

  const handleSkillsChange = (event, newValue) => {
    if (newValue) setSkills(newValue);
  };

  // const handleSelectStatus = (event) => {
  //   setStatus(event.target.value);
  // };

  const handleLocationStateChange = (event, newValue) => {
    event.preventDefault();
    // console.log(newValue);
    setLocationState(newValue);
    if (newValue) {
      dispatch(getLocationsCity(newValue));
      setFetchState(true);
    } else {
      setFetchState(false);
      setLocation("");
    }
  };

  const handleApplyFilter = () => {
    const searchParams = new URLSearchParams(loc.search);
    if (jobTitle) {
      searchParams.set("title", `${jobTitle}`);
    } else {
      searchParams.delete("title");
    }

    if (state) {
      searchParams.set("state", `${state}`);
    } else {
      searchParams.delete("state");
    }

    if (location) {
      searchParams.set("location", `${location}`);
    } else {
      searchParams.delete("location");
    }

    if (skills.length) {
      const skillsString = JSON.stringify(skills);
      searchParams.set("skills", `${skillsString}`);
    } else {
      searchParams.delete("skills");
    }

    if (status) {
      searchParams.set("status", status);
    } else {
      searchParams.delete("status");
    }

    navigate({ search: `?${searchParams.toString()}` });
  };

  const handleClearFilter = () => {
    setJobTitle("");
    setLocation("");
    setLocationState("");
    setFetchState(false);
    setSkills([]);

    navigate({ search: "" });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  useEffect(() => {
    dispatch(getSkillSet())
      .then((message) => {})
      .catch((message) => {
        setMessage(message);
        setOpenError(true);
      });
    dispatch(getLocations())
      .then((message) => {})
      .catch((message) => {
        setMessage(message);
        setOpenError(true);
      });
  }, []);

  return (
    <div className="jobFilter">
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
      <div className="head">
        <FilterAltIcon />
        <div className="title">Filters</div>
      </div>
{/*       
      <div className="filter">
        <div className="header">
          <WorkIcon fontSize="small" />
          <div className="title">Job Title</div>
        </div>
        <TextField
          // className="mod"
          size="small"
          placeholder="Select by title..."
          value={jobTitle}
          onChange={handleJobTitle}
        />
      </div> */}
      <div className="filter">
        <div className="header">
          <LocationIcon fontSize="small" />
          <div className="title">Location</div>
        </div>
        <Autocomplete
          disablePortal
          autoHighlight
          id="combo-box-demo"
          size="small"
          value={state}
          options={states.map((option) => option)}
          onChange={handleLocationStateChange}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select State" />
          )}
        />
        {fetchState && (
          <Autocomplete
            sx={{ marginTop: 1 }}
            disablePortal
            autoHighlight
            id="combo-box-demo"
            size="small"
            value={location}
            options={city.map((option) => option)}
            onChange={(event, newValue) => setLocation(newValue)}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select City" />
            )}
          />
        )}
      </div>
      <div className="filter">
        <div className="header">
          <SchoolIcon fontSize="small" />
          <div className="title">Skills</div>
        </div>
        <Stack spacing={3}>
          <Autocomplete
            multiple
            size="small"
            id="tags-outlined"
            options={skillSet}
            getOptionLabel={(option) => option}
            filterSelectedOptions
            value={skills}
            onChange={handleSkillsChange}
            renderInput={(params) => (
              <TextField {...params} placeholder="Required Skills" />
            )}
          />
        </Stack>
      </div>
      {/* {(user.role === "Admin" || user.role === "S_ADMIN") && (
        <div className="filter">
          <div className="header">
            <LocationIcon fontSize="small" />
            <div className="title">Job Status</div>
          </div>
          <FormControl>
            <Select
              displayEmpty
              id="demo-simple-select"
              value={status}
              onChange={handleSelectStatus}
              sx={{
                height: 40,
                backgroundColor: "white",
                color: "var(--dull-text-color)",
              }}
            >
              <MenuItem value="" disabled>
                Select status
              </MenuItem>
              <MenuItem value="All">All Jobs</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Closed">Closed</MenuItem>
              <MenuItem value="Hidden">Hidden</MenuItem>
            </Select>
          </FormControl>
        </div>
      )} */}
      <div className="buttons">
        <button className="filterBtn" onClick={handleApplyFilter}>
          Apply
        </button>
        <button className="clearBtn" onClick={handleClearFilter}>
          Clear
        </button>
      </div>
    </div>
  );
};

export default JobFilter;
