import React, { useState } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../components/Input/Input";
import logo from "../../assets/Logo.png";
import { googleLogin, login } from "../../actions/auth";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { CircularProgress } from "@mui/material";
import SnackBar from "../../components/Alerts/SnackBar";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const isLoadingStatus = useSelector((state) => state.loader.isLoadingStatus);
  const [isPageDisabled, setPageDisabled] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const handleRedirect = () => {
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (email !== "" && password !== "") {
      setPageDisabled(true);
      dispatch(login(email, password))
        .then((message) => {
          setMessage(message);
          setOpenSuccess(true);
          handleRedirect();
        })
        .catch((message) => {
          setMessage(message);
          setOpenError(true);
          setPageDisabled(false);
        });
        
    } else {
      setMessage("Please enter email and password!");
      setOpenError(true);
      setPageDisabled(false);
    }
  };

  const handleLoginSuccess = async (response) => {
    if (response !== null) {
      dispatch(googleLogin(response))
        .then((message) => {
          setMessage(message);
          setOpenSuccess(true);
          handleRedirect();
        })
        .catch((message) => {
          setMessage(message);
          setOpenError(true);
        });
    } else {
      setMessage("User could not be authenticated!");
      setOpenError(true);
      setPageDisabled(false);
    }
  };

  return (
    <>
      <div className={`signInPage ${isPageDisabled ? "disabled" : ""}`}>
        <div className="left">
          <div className="logo-container">
            <div>
              <img src={logo} alt="logo" height="25%" width="25%" />
            </div>
            <div>
              <span>CADET 360</span>
            </div>
          </div>

          <div className="mid">
            <span>Step Into The Future</span>
          </div>

          <div className="lower">
            <span>
              Discover Exciting <br /> Career Paths
            </span>
          </div>

          <div className="end">
            <span>
              Exclusively For EXPA CADET <br /> Program Attendees.
            </span>
          </div>
        </div>
        <div className="right">
          {/* <div className="signinPage"> */}
          <div className="signInContainer">
            <div className="signin">Sign In</div>
            <div className="redirect">
              <p>
                New user ?{" "}
                <Link to="/register" style={{ textDecoration: "none" }}>
                  Create an account
                </Link>
              </p>
            </div>
            <div className="formContainer">
              <div className="inputField">
                <Input
                  type="email"
                  field="Email"
                  placeholder="Enter Email"
                  value={email}
                  onChange={handleEmailChange}
                  req={true}
                  dis={isLoadingStatus}
                />
              </div>
              <div className="inputField">
                <Input
                  type="password"
                  field="Password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={handlePasswordChange}
                  req={true}
                  dis={isLoadingStatus}
                 // showPassword={showPassword}
                />
              </div>
              <button
                className={`com ${isLoadingStatus ? "loading" : "button"}`}
                onClick={handleLogin}
              >
                {isLoadingStatus ? (
                  <>
                    <CircularProgress
                      color="inherit"
                      size={18}
                      sx={{ marginRight: "4px" }}
                    />
                    Signing In...
                  </>
                ) : (
                  <>Sign In</>
                )}
              </button>
            </div>
            <div className="redirect" style={{ justifyContent: "flex-end" }}>
              <Link to="/reset" style={{ textDecoration: "none" }}>
                Forgot Password?
              </Link>
            </div>
            <div className="divider">
              <div style={{ width: "100%" }}>
                <hr />
              </div>
              <div
                style={{
                  color: "rgba(0, 0, 0, 0.5)",
                  fontWeight: "500",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  fontSize: "0.7rem",
                }}
              >
                <p>OR</p>
              </div>
              <div style={{ width: "100%" }}>
                <hr />
              </div>
            </div>
            <div className="redirect">
              <p>Want to be an HR ? Use your organization email</p>
            </div>
            <div className="social">
              <GoogleLogin
                onSuccess={(credentialResponse) => {

                  const data = jwtDecode(credentialResponse.credential);
                  handleLoginSuccess(data);
                }}
                onError={() => {
                  setMessage("Google Login Failed");
                  setOpenError(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
    </>
  );
};

export default Login;
