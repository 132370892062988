import {
  REQUEST_HR_SUCCESS,
  GET_HR_REQUESTS_SUCCESS,
  GET_PROFILE,
  SET_HR_REQUEST,
  SET_MESSAGE,
  GET_ENTRIES,
  LOADER,
  GET_NOTIFY,
  SET_NOTIFY,
  SAVE_SKILLS,
  SAVE_PROFILE,
  SET_ROLE,
  SKILLS_LOADER,
  STATUS_LOADER,
  CAMPS_LOADER,
  GET_SKILLSET,
  GET_CAMPS,
  REQUEST_ACCESS_SUCCESS,
} from "./types";

import ProfileService from "../services/profile.service";

export const saveProfile = (id, updatedData) => (dispatch) => {
  dispatch({ type: STATUS_LOADER, payload: true });
  return ProfileService.saveProfile(id, updatedData).then(
    (response) => {
      dispatch({
        type: SAVE_PROFILE,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: STATUS_LOADER, payload: false });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        error.response.data.message || error.message || error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: STATUS_LOADER, payload: false });

      return Promise.reject(message);
    }
  );
};

export const saveSkills = (id, skills) => (dispatch) => {
  // dispatch({ type: LOADER, payload: true });
  dispatch({ type: SKILLS_LOADER, payload: true });
  return ProfileService.saveSkills(id, skills).then(
    (response) => {
      dispatch({
        type: SAVE_SKILLS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      // dispatch({ type: LOADER, payload: false });
      dispatch({ type: SKILLS_LOADER, payload: false });
      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        error.response.data.message || error.message || error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      // dispatch({ type: LOADER, payload: false });
      dispatch({ type: SKILLS_LOADER, payload: false });

      return Promise.reject(message);
    }
  );
};

export const sendHrRequest =
  (companyName, companyDescription, mobile) => (dispatch) => {
    dispatch({ type: LOADER, payload: true });
    return ProfileService.sendHrRequest(
      companyName,
      companyDescription,
      mobile
    ).then(
      (response) => {
        dispatch({
          type: REQUEST_HR_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        dispatch({ type: LOADER, payload: false });

        return Promise.resolve(response.data.message);
      },
      (error) => {
        const message = error.response.data.message;

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        dispatch({ type: LOADER, payload: false });

        return Promise.reject(message);
      }
    );
  };

export const getHrRequests = () => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return ProfileService.getHrRequests().then(
    (response) => {
      dispatch({
        type: GET_HR_REQUESTS_SUCCESS,
        payload: { requests: response.data },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: LOADER, payload: false });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: LOADER, payload: false });

      return Promise.reject(message);
    }
  );
};

export const getNotify = (userId) => (dispatch) => {
  dispatch({ type: STATUS_LOADER, payload: true });
  return ProfileService.getNotify(userId).then(
    (response) => {
      dispatch({
        type: GET_NOTIFY,
        payload: { notification: response.data.notify },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: STATUS_LOADER, payload: false });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: STATUS_LOADER, payload: false });

      return Promise.reject(message);
    }
  );
};

export const setNotify = (userId, notif) => (dispatch) => {
  dispatch({ type: STATUS_LOADER, payload: true });
  return ProfileService.setNotify(userId, notif).then(
    (response) => {
      dispatch({
        type: SET_NOTIFY,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: STATUS_LOADER, payload: false });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: STATUS_LOADER, payload: false });

      return Promise.reject(message);
    }
  );
};

export const getProfile = (id) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return ProfileService.getProfile(id).then(
    (response) => {
      dispatch({
        type: GET_PROFILE,
        payload: { requestdata: response.data },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: LOADER, payload: false });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        error.response.data.message || error.message || error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: LOADER, payload: false });

      return Promise.reject(message);
    }
  );
};

export const setHrRequest = (id, status, comment) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return ProfileService.setHrRequest(id, status, comment).then(
    (response) => {
      dispatch({
        type: SET_HR_REQUEST,
        payload: { requestdata: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: LOADER, payload: false });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        error.response.data.message || error.message || error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: LOADER, payload: false });

      return Promise.reject(message);
    }
  );
};

export const getEntries = (role) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return ProfileService.getEntries(role).then(
    (response) => {
      dispatch({
        type: GET_ENTRIES,
        payload: { entries: response.data },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: LOADER, payload: false });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        error.response.data.message || error.message || error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: LOADER, payload: false });

      return Promise.reject(message);
    }
  );
};

export const updateRoleAction = (id, role) => (dispatch) => {
  return ProfileService.updateRoleAction(id, role).then(
    (response) => {
      dispatch({
        type: SET_ROLE,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        error.response.data.message || error.message || error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    }
  );
};

export const getSkillSet = () => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return ProfileService.getSkillSet().then(
    (response) => {
      // console.log(response.data);
      dispatch({
        type: GET_SKILLSET,
        payload: { requestdata: response.data },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: LOADER, payload: false });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        error.response.data.message || error.message || error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: LOADER, payload: false });

      return Promise.reject(message);
    }
  );
};

export const sendAccessRequest = (email, role) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return ProfileService.sendAccessRequest(email, role).then(
    (response) => {
      dispatch({
        type: REQUEST_ACCESS_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: LOADER, payload: false });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message = error.response.data.message;

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: LOADER, payload: false });

      return Promise.reject(message);
    }
  );
};

export const getCamps = (email) => (dispatch) => {
  dispatch({ type: CAMPS_LOADER, payload: true });
  return ProfileService.getCamps(email).then(
    (response) => {
      dispatch({
        type: GET_CAMPS,
        payload: { requestdata: response.data },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: CAMPS_LOADER, payload: false });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        error.response.data.message || error.message || error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: CAMPS_LOADER, payload: false });

      return Promise.reject(message);
    }
  );
};
