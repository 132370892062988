import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  createJob,
  getLocations,
  getLocationsCity,
} from "../../../actions/job.action";
import { getSkillSet } from "../../../actions/profile.action";
import SnackBar from "../../../components/Alerts/SnackBar";
import CloseIcon from "@mui/icons-material/Close";
import {
  CircularProgress,
  Typography,
  Select,
  Stack,
  FormControl,
  MenuItem,
  InputLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import "./CreateJob.css";

//iska kya karna he?
const jobType = ["Full Time", "Part Time", "Internship"];

const CreateJob = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const city = useSelector((state) => state.job.city);
  const states = useSelector((state) => state.job.states);
  const isLoadingStatus = useSelector((state) => state.loader.isLoadingStatus);
  const skillSet = useSelector((state) => state.profile.skillSet);

  const [job_title, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [state, setLocationState] = useState("");
  const [fetchState, setFetchState] = useState(false);
  const [job_type, setType] = useState("");
  const [date, setDate] = useState(dayjs(Date.now()));
  const [short_job_description, setShortDescription] = useState("");
  const [job_description, setDescription] = useState("");
  const [skills, setSkills] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [questionsjob, setQuestions] = useState([""]);

  const addQuestions = () => {
    setQuestions([...questionsjob, ""]);
  };

  const removeQuestions = (index) => {
    const rem = [...questionsjob];
    rem.splice(index, 1);
    setQuestions(rem);
  };

  const updateQuestions = (index, e) => {
    const temp = [...questionsjob];
    temp[index] = e.target.value;
    setQuestions(temp);
  };

  var toolbarOptions = [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ];

  const handleTitleChange = (event) => {
    setJobTitle(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.innerText);
  };

  const handleLocationStateChange = (event, newValue) => {
    event.preventDefault();
    // console.log(newValue);
    setLocationState(newValue);
    if (newValue) {
      dispatch(getLocationsCity(newValue));
      setFetchState(true);
    } else {
      setFetchState(false);
      setLocation("");
    }
  };

  const handleJobType = (event) => {
    setType(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event);
  };

  const handleSkillsChange = (event) => {
    setSkills([...skills, event.target.innerText]);
  };

  const handleShortDescriptionChange = (event) => {
    setShortDescription(event.target.value);
  };

  const handleCloseForm = () => {
    navigate(-1);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const handleCreatePost = (e) => {
    e.preventDefault();
    const expiry_date = date;
    if (
      !job_title ||
      !job_type ||
      !expiry_date ||
      !state ||
      !location ||
      skills.length === 0 ||
      !job_description ||
      !short_job_description
    ) {
      setMessage("Please fill all the fields!");
      setOpenError(true);
      return;
    }
    let questions = questionsjob.filter((que) => {
      que = que.trim();
      return que !== "";
    });

    dispatch(
      createJob(
        job_title,
        job_type,
        expiry_date,
        state,
        location,
        skills,
        job_description,
        short_job_description,
        questions
      )
    )
      .then(() => {
        setMessage("Job created successfully");
        setOpenSuccess(true);
        setTimeout(() => {
          navigate("/job");
        }, 3000);
      })
      .catch((err) => {
        setMessage(err);
        setOpenError(true);
      });
  };

  useEffect(() => {
    dispatch(getSkillSet())
      .then((message) => {})
      .catch((message) => {
        setMessage(message);
        setOpenError(true);
      });
    dispatch(getLocations())
      .then((message) => {})
      .catch((message) => {
        setMessage(message);
        setOpenError(true);
      });
  }, []);

  return (
    <div className="createJob">
      <div className="pageHeading">Create Job</div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
      <div className="container">
        <div className="details">
          <div className="title col">
            Job Title
            <TextField
              id="outlined-basic"
              required
              placeholder="Job title"
              variant="outlined"
              value={job_title}
              onChange={handleTitleChange}
            />
          </div>
          <div className="type col">
            Job Type
            <FormControl sx={{ minWidth: 100 }}>
              <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Type"
                value={job_type}
                onChange={handleJobType}
              >
                <MenuItem value="" disabled>
                  Select job type
                </MenuItem>
                {jobType.map((job) => (
                  <MenuItem value={job}>{job}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="location col">
            State
            <Autocomplete
              disablePortal
              autoHighlight
              id="combo-box-demo"
              options={states.map((option) => option)}
              sx={{
                width: 190,
              }}
              defaultValue={state}
              onChange={handleLocationStateChange}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select State" />
              )}
            />
          </div>
          {fetchState && (
            <div className="location col">
              City
              <Autocomplete
                disablePortal
                autoHighlight
                id="combo-box-demo"
                options={city.map((option) => option)}
                sx={{
                  width: 180,
                }}
                defaultValue={location}
                onChange={handleLocationChange}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select City" />
                )}
              />
            </div>
          )}
        </div>
        <div className="skillRow">
          <div className="skills col">
            Add Skills
            <Stack spacing={3} sx={{ width: 482 }}>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={skillSet}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                defaultSelected={skills}
                onChange={handleSkillsChange}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Required Skills" />
                )}
              />
            </Stack>
          </div>
          <div className="date col">
            Last Date
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={date}
                minDate={dayjs(Date.now())}
                onChange={handleDateChange}
                sx={{ width: 200 }}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="shortDescription col">
          Short Description
          <TextField
            id="outlined-basic"
            placeholder="Short Description"
            variant="outlined"
            value={short_job_description}
            onChange={handleShortDescriptionChange}
            sx={{ minWidth: "100%" }}
          />
        </div>
        <div className="description col">
          Job Description
          <div className="editContainer">
            <ReactQuill
              modules={{
                toolbar: toolbarOptions,
              }}
              theme="snow"
              value={job_description}
              onChange={setDescription}
            />
          </div>
        </div>
        <div className="careerDetail">
          <Typography sx={{ fontWeight: "450" }}>Questions</Typography>
          <Typography sx={{ fontWeight: "300" }}>
            You are allowed to ask up to 4 questions that any applicant must
            answer.{" "}
          </Typography>
          {questionsjob.map((field, index) => (
            <div className="data" style={{ marginTop: "0.2rem" }}>
              <div className="row">
                <div className="questions">
                  <div className="question col">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Question {index + 1}
                      {questionsjob.length !== 1 && (
                        <CloseIcon
                          sx={{
                            color: "var(--primary-color)",
                            cursor: "pointer",
                          }}
                          onClick={() => removeQuestions(index)}
                        />
                      )}
                    </div>
                    <TextField
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Ex: How many years of work experience you have in tech?"
                      value={field}
                      onChange={(e) => updateQuestions(index, e)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
          {questionsjob.length < 4 && (
            <button className="buttn" onClick={addQuestions}>
              + Add More
            </button>
          )}
        </div>

        <div className="buttons">
          <button className="com cancel" onClick={handleCloseForm}>
            Cancel
          </button>
          <button
            className={`com ${isLoadingStatus ? "loading" : "create"}`}
            disabled={isLoadingStatus}
            onClick={handleCreatePost}
          >
            {isLoadingStatus ? (
              <>
                <CircularProgress
                  color="inherit"
                  size={12}
                  sx={{ marginRight: "4px" }}
                />
                Creating
              </>
            ) : (
              <>Create</>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateJob;
