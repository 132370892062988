// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminCamps {
    min-height: 100vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .adminCamps .container {
    width: 70%;
  }
  
  .adminCamps .status {
    font-weight: 500;
    font-size: 14px;
    color: var(--primary-color);
  }
  
  .adminCamps .expenses {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/modules/Expense/AdminCamp/AdminCamp.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,2BAA2B;EAC7B;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX","sourcesContent":[".adminCamps {\n    min-height: 100vh;\n    padding: 2rem;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .adminCamps .container {\n    width: 70%;\n  }\n  \n  .adminCamps .status {\n    font-weight: 500;\n    font-size: 14px;\n    color: var(--primary-color);\n  }\n  \n  .adminCamps .expenses {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
