// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tablecontainer {
  width: 90%;
  background-color: white;
  margin: 0 auto;
  height: 100%;
}

.userManagement {
}
.userManagement .tablecontainer {
  width: 90%;
  background-color: white;
  margin: 0 auto;
  height: 100%;
}

.custom-select-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-select-cell .MuiSelect-root.Cadet {
  background-color: red;
}

.custom-select-cell .MuiSelect-root.Trainer {
  background-color: blue;
}

.custom-select-cell .MuiSelect-root:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.custom-select-cell .MuiSelect-root:focus {
  background-color: rgba(255, 255, 255, 0.3);
}
.custom-select-cell .MuiOutlinedInput-root {
  width: 100%;
  background-color: "#BDFFC4";
}
.userManagement .MuiFormControl-root {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/modules/Admin/UserManagement/UserManagement.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,uBAAuB;EACvB,cAAc;EACd,YAAY;AACd;;AAEA;AACA;AACA;EACE,UAAU;EACV,uBAAuB;EACvB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,0CAA0C;AAC5C;AACA;EACE,WAAW;EACX,2BAA2B;AAC7B;AACA;EACE,WAAW;AACb","sourcesContent":[".tablecontainer {\n  width: 90%;\n  background-color: white;\n  margin: 0 auto;\n  height: 100%;\n}\n\n.userManagement {\n}\n.userManagement .tablecontainer {\n  width: 90%;\n  background-color: white;\n  margin: 0 auto;\n  height: 100%;\n}\n\n.custom-select-cell {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.custom-select-cell .MuiSelect-root.Cadet {\n  background-color: red;\n}\n\n.custom-select-cell .MuiSelect-root.Trainer {\n  background-color: blue;\n}\n\n.custom-select-cell .MuiSelect-root:hover {\n  background-color: rgba(255, 255, 255, 0.2);\n}\n\n.custom-select-cell .MuiSelect-root:focus {\n  background-color: rgba(255, 255, 255, 0.3);\n}\n.custom-select-cell .MuiOutlinedInput-root {\n  width: 100%;\n  background-color: \"#BDFFC4\";\n}\n.userManagement .MuiFormControl-root {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
