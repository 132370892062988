// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  flex: 1 1 auto;
  background-color: var(--primary-color);
  color: var(--light-text-color);
  border-radius: 0.2rem;
  font-size: medium;
  border: none;
  padding: 0.6rem 2rem;
  transition: 0.3s;
}

.btn:hover {
  cursor: pointer;
  /* transform: scale3d(1.02, 1.02, 1.02); */
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,sCAAsC;EACtC,8BAA8B;EAC9B,qBAAqB;EACrB,iBAAiB;EACjB,YAAY;EACZ,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,0CAA0C;AAC5C","sourcesContent":[".btn {\n  flex: 1 1 auto;\n  background-color: var(--primary-color);\n  color: var(--light-text-color);\n  border-radius: 0.2rem;\n  font-size: medium;\n  border: none;\n  padding: 0.6rem 2rem;\n  transition: 0.3s;\n}\n\n.btn:hover {\n  cursor: pointer;\n  /* transform: scale3d(1.02, 1.02, 1.02); */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
