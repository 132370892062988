// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageFooter {
  background-color: #a4a6dd;
  /* background-color: #f8faff; */
  height: 4vh;
  padding: 0 0.5rem;
  font-size: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,+BAA+B;EAC/B,WAAW;EACX,iBAAiB;EACjB,iBAAiB;EACjB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".pageFooter {\n  background-color: #a4a6dd;\n  /* background-color: #f8faff; */\n  height: 4vh;\n  padding: 0 0.5rem;\n  font-size: 0.5rem;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
