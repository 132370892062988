import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import Hamburger from "@mui/icons-material/Menu";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";

const DrawerComp = (props) => {
  const { user } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);

  //for admin
  const [anchorAdmin, setAnchorAdmin] = useState(null);
  const openAdmin = Boolean(anchorAdmin);

  //for job menu
  const [anchorJob, setAnchorJob] = useState(null);
  const openJob = Boolean(anchorJob);

  //for account
  const [anchorProfile, setAnchorProfile] = useState(null);
  const openProfile = Boolean(anchorProfile);
  const handleProfileMenu = (event) => {
    setAnchorProfile(event.currentTarget);
  };
  const closeProfileMenu = () => {
    setAnchorProfile(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          {/* Admin Button */}
          {user.isLoggedIn &&
            (user.user.role === "Admin" || user.user.role === "S_ADMIN") && (
              <>
                <ListItemButton
                  onClick={(e) => {
                    setAnchorAdmin(e.currentTarget);
                  }}
                >
                  <ListItemIcon>
                    <ListItemText>Admin</ListItemText>
                  </ListItemIcon>
                </ListItemButton>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorAdmin}
                  open={openAdmin}
                  onClose={() => setAnchorAdmin(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Link className="linkMod" to="/adminjobs">
                    <MenuItem
                      onClick={() => {
                        setAnchorAdmin(null);
                        setOpenDrawer(false);
                      }}
                    >
                      All Jobs
                    </MenuItem>
                  </Link>

                  <Link className="linkMod" to="/hrrequests">
                    <MenuItem
                      onClick={() => {
                        setAnchorAdmin(null);
                        setOpenDrawer(false);
                      }}
                    >
                      HR Requests
                    </MenuItem>
                  </Link>

                  <Link className="linkMod" to="/usermanagement">
                    <MenuItem
                      onClick={() => {
                        setAnchorAdmin(null);
                        setOpenDrawer(false);
                      }}
                    >
                      User Management
                    </MenuItem>
                  </Link>

                  <Link className="linkMod" to="/expenseadminrequests">
                    <MenuItem
                      onClick={() => {
                        setAnchorAdmin(null);
                        setOpenDrawer(false);
                      }}
                    >
                      All Expense Requests
                    </MenuItem>
                  </Link>

                  <Link className="linkMod" to="/expenseadmincamp">
                    <MenuItem
                      onClick={() => {
                        setAnchorAdmin(null);
                        setOpenDrawer(false);
                      }}
                    >
                      Campwise Expenses
                    </MenuItem>
                  </Link>
                </Menu>
              </>
            )}

          {/* My Expenses Button */}
          {user.isLoggedIn &&
            (user.user.role === "Admin" ||
              user.user.role === "S_ADMIN" ||
              user.user.role === "Trainer") && (
              <ListItemButton onClick={() => setOpenDrawer(false)}>
                <ListItemIcon onClick={() => navigate("/expense")}>
                  <ListItemText>My Expenses</ListItemText>
                </ListItemIcon>
              </ListItemButton>
            )}

          {/* Jobs Button */}
          <ListItemButton
            onClick={(e) => {
              setAnchorJob(e.currentTarget);
            }}
          >
            <ListItemIcon>
              <ListItemText>Jobs</ListItemText>
            </ListItemIcon>
          </ListItemButton>
          <Menu
            id="job-menu"
            anchorEl={anchorJob}
            open={openJob}
            onClose={() => setAnchorJob(null)}
            MenuListProps={{
              "aria-labelledby": "job-button",
            }}
          >
            <Link className="linkMod" to="/job">
              <MenuItem
                onClick={() => {
                  setAnchorJob(null);
                  setOpenDrawer(false);
                }}
              >
                Active Jobs
              </MenuItem>
            </Link>
            {user.isLoggedIn &&
              (user.user.role === "Cadet" || user.user.role === "Trainer") && (
                <Link className="linkMod" to="/applied">
                  <MenuItem
                    onClick={() => {
                      setAnchorJob(null);
                      setOpenDrawer(false);
                    }}
                  >
                    My Applied Jobs
                  </MenuItem>
                </Link>
              )}
            {user.isLoggedIn && user.user.role === "HR" && (
              <Link className="linkMod" to="/managejobhr">
                <MenuItem
                  onClick={() => {
                    setAnchorJob(null);
                    setOpenDrawer(false);
                  }}
                >
                  Manage My Jobs
                </MenuItem>
              </Link>
            )}
            {user.isLoggedIn && user.user.role === "HR" && (
              <Link className="linkMod" to="/createjob">
                <MenuItem
                  onClick={() => {
                    setAnchorJob(null);
                    setOpenDrawer(false);
                  }}
                >
                  Post a Job
                </MenuItem>
              </Link>
            )}
          </Menu>

          {/* About Us botton */}
          <ListItemButton onClick={() => setOpenDrawer(false)}>
            <ListItemIcon onClick={() => navigate("/")}>
              <ListItemText>About Us</ListItemText>
            </ListItemIcon>
          </ListItemButton>
          {/* Contact Us button */}
          <ListItemButton onClick={() => setOpenDrawer(false)}>
            <ListItemIcon onClick={() => navigate("/contact")}>
              <ListItemText>Contact Us</ListItemText>
            </ListItemIcon>
          </ListItemButton>

          {/* My Account Button */}
          <ListItemButton
            onClick={(e) => {
              setAnchorProfile(e.currentTarget);
            }}
          >
            <ListItemIcon>
              <ListItemText>My Account</ListItemText>
            </ListItemIcon>
          </ListItemButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorProfile}
            open={openProfile}
            onClose={() => setAnchorProfile(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Link className="linkMod" to={`/profile/${user.user.id}`}>
              <MenuItem
                onClick={() => {
                  setAnchorJob(null);
                  setOpenDrawer(false);
                }}
              >
                Profile
              </MenuItem>
            </Link>
            {user.isLoggedIn &&
              (user.user.role === "Guest" ) && (
                <Link className="linkMod" to={`/requestpage`}>
                  <MenuItem
                    onClick={() => {
                      setAnchorJob(null);
                      setOpenDrawer(false);
                    }}
                  >
                    Request Access
                  </MenuItem>
                </Link>
              )}
            <Link className="linkMod" to={`/reset`}>
              <MenuItem
                onClick={() => {
                  setAnchorJob(null);
                  setOpenDrawer(false);
                }}
              >
                Reset Password
              </MenuItem>
            </Link>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </List>
      </Drawer>
      <IconButton
        sx={{ color: "#000000", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <Hamburger />
      </IconButton>
    </>
  );
};

export default DrawerComp;
