import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  VERIFY_EMAIL_SUCCESS,
  SEND_RESET_EMAIL_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  CHECK_USER_SUCCESS,
  RESET_PASS_SUCCESS,
  LOGOUT,
  SET_MESSAGE,
  LOADER,
  STATUS_LOADER,
} from "./types";

import AuthService from "../services/auth.service";

export const register =
  (name, email, password, confirmPassword) => (dispatch) => {
    dispatch({ type: STATUS_LOADER, payload: true });
    return AuthService.register(name, email, password, confirmPassword).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        dispatch({ type: STATUS_LOADER, payload: false });

        return Promise.resolve(response.data.message);
      },
      (error) => {
        const message = error.response.data.message;

        dispatch({
          type: REGISTER_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        dispatch({ type: STATUS_LOADER, payload: false });

        return Promise.reject(message);
      }
    );
  };

export const login = (email, password) => (dispatch) => {
  dispatch({ type: STATUS_LOADER, payload: true });
  return AuthService.login(email, password).then(
    (response) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: response.data.user },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: STATUS_LOADER, payload: false });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message = error.response.data.message;
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: STATUS_LOADER, payload: false });

      return Promise.reject(message);
    }
  );
};

export const googleLogin = (response) => (dispatch) => {
  return AuthService.googleLogin(response).then(
    (response) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: response.data.user },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message = error.response.data.message;
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    }
  );
};

export const verifyEmail = (token) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return AuthService.verifyEmail(token).then(
    (response) => {
      dispatch({
        type: VERIFY_EMAIL_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: LOADER, payload: false });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: LOADER, payload: false });

      return Promise.reject(message);
    }
  );
};

export const sendResetEmail = (email) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return AuthService.sendResetEmail(email).then(
    (response) => {
      dispatch({
        type: SEND_RESET_EMAIL_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: LOADER, payload: false });
      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message = error.response.data.message;

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const checkUser = (id, token) => (dispatch) => {
  dispatch({ type: LOADER, payload: true });
  return AuthService.checkUser(id, token).then(
    (response) => {
      dispatch({
        type: CHECK_USER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: LOADER, payload: false });
      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message = error.response.data.message;
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: LOADER, payload: false });
      return Promise.reject(message);
    }
  );
};

export const resetPass =
  (id, token, passsword, confirmPassword) => (dispatch) => {
    dispatch({ type: LOADER, payload: true });
    return AuthService.resetPass(id, token, passsword, confirmPassword).then(
      (response) => {
        dispatch({
          type: RESET_PASS_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        dispatch({ type: LOADER, payload: false });
        return Promise.resolve(response.data.message);
      },
      (error) => {
        const message = error.response.data.message;

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        dispatch({ type: LOADER, payload: false });
        return Promise.reject(message);
      }
    );
  };

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const contactUs = (name, email, subject, message) => (dispatch) => {
  dispatch({ type: STATUS_LOADER, payload: true });
  return AuthService.contactUs(name, email, subject, message).then(
    (response) => {
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({ type: STATUS_LOADER, payload: false });

      return Promise.resolve(response.data.message);
    },
    (error) => {
      const message = error.response.data.message;

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({ type: STATUS_LOADER, payload: false });

      return Promise.reject(message);
    }
  );
};
