// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hrRequests .pageHeading {
  position: fixed;
  z-index: 999;
  top: 4rem;
  width: 100%;
  background-color: var(--primary-color);
  color: var(--light-text-color);
  font-size: 20px;
  padding: 1rem 0 1rem 3.5em;
}
.hrRequests {
  top: 1rem;
  position: relative;
}

.tablecontainer {
  width: 90%;
  background-color: white;
  margin: 0 auto;
  height: 80vh;
}

`, "",{"version":3,"sources":["webpack://./src/modules/Admin/HrRequests/HrRequests.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,SAAS;EACT,WAAW;EACX,sCAAsC;EACtC,8BAA8B;EAC9B,eAAe;EACf,0BAA0B;AAC5B;AACA;EACE,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,uBAAuB;EACvB,cAAc;EACd,YAAY;AACd","sourcesContent":[".hrRequests .pageHeading {\n  position: fixed;\n  z-index: 999;\n  top: 4rem;\n  width: 100%;\n  background-color: var(--primary-color);\n  color: var(--light-text-color);\n  font-size: 20px;\n  padding: 1rem 0 1rem 3.5em;\n}\n.hrRequests {\n  top: 1rem;\n  position: relative;\n}\n\n.tablecontainer {\n  width: 90%;\n  background-color: white;\n  margin: 0 auto;\n  height: 80vh;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
