import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkUser, resetPass } from "../../actions/auth";
import Button from '../../components/Button/Button';
import './Forgot.css';
import Input from '../../components/Input/Input';
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import SnackBar from "../../components/Alerts/SnackBar";
import DialogueBox from "../../components/Alerts/DialogueBox";
import logo from "../../assets/Logo.png";

const ResetPassword = () => {
  const isLoading = useSelector((state) => state.loader.isLoading);
  const { id, token } = useParams();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const goBack = () => { navigate(-1); };
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [dialogueMessage, setDialogueMessage] = useState(
    "Do you really want to reset your password?"
  );

  const handleClickOpen = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleRedirect = () => {
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  const handleChange = () => {
    setOpenAlert(false);
    setMessage("Password Reset Successful");
    dispatch(resetPass(id, token, password, confirmPassword))
      .then(() => {
        setOpenSuccess(true);
        handleRedirect();
      })
      .catch((message) => {
        setMessage(message);
        setOpenError(true);
      });
  }

  const handleReset = () => {
    const passwordValid = /.*\d.*/.test(password);
    const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&~^()+_={}“‘:;,.\/\\<>\[|\]-])[A-Za-z\d#@$!%*?&~^()+_={}“‘:;,.\/\\<>\[|\]-]{8,}$/.test(password);
    
    if (!password || !confirmPassword) {
      setMessage("Both new password and confirm password are required");
      setOpenError(true);
      return; // Stop execution if either password field is empty
    }
    if (password.length < 8 || !passwordValid || !passwordFormat) {
      setMessage("Password should be at least 8 characters long, and must contain at least one upper case alphabet, one numeric and one special character.");
      setOpenError(true);
      return; // Stop execution if password is not valid
    }
    if (password !== confirmPassword) {
      setMessage("Confirm password does not match the new password");
      setOpenError(true);
      return; // Stop execution if confirm password does not match
    }
    dispatch(checkUser(id, token))
      .then(() => {
        handleClickOpen();
      })
      .catch((message) => {
        setMessage(message);
        setOpenError(true);
      });
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <div className="forgotPage">
        <div className="forgotContainer">
          <div className="title" onClick={() => navigate("/")}>
            <span className="logo">
              <img src={logo} alt="logo" height="60px" width="60px" />
            </span>
            <span className="appName">CADET 360</span>
          </div>
          <div className="forgot">Reset Password</div>
          <div className="formContainer">
            <div className="inputField">
              <Input
                type="password"
                field="New Password"
                placeholder="Enter New Password"
                value={password}
                onChange={handlePasswordChange}
                req={true}
              />
            </div>
            <div className="inputField">
              <Input
                type="password"
                field="Confirm Password"
                placeholder="Re-enter Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                req={true}
              />

            </div>
            <div className="button">
              <Button text="Submit" handleClick={handleReset} />
            </div>
            <Button text="Go Back" handleClick={goBack} />
          </div>
        </div>
      </div>
      <SnackBar
        message={message}
        openSuccess={openSuccess}
        openError={openError}
        handleClose={handleClose}
      />
      <DialogueBox
        dialogueMessage={dialogueMessage}
        handleFunction={handleChange}
        handleCloseAlert={handleCloseAlert}
        openAlert={openAlert}
      />
    </>
  );
};

export default ResetPassword;